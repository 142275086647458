<template>
  <div>
    <v-tooltip right>
      <template v-slot:activator="{ on, attrs }">
        <div class="filter" v-bind="attrs" v-on="on">
          <v-autocomplete
            :items="allAsignee"
            v-model="currentAsignee"
            item-text="name"
            style="height: 40px"
            label="Filter by"
            placeholder="Filter by"
            color="#5097F3"
            background-color="#F1F1F2"
            :menu-props="{ bottom: true, offsetY: true }"
            solo
            filled
            dense
            persistent-hint
            return-object
          ></v-autocomplete>
        </div>
      </template>
      <span>Filter leads</span>
    </v-tooltip>
  </div>
</template>

<script>
import axiosInstance from "@/axios";
export default {
  data: () => ({
    allAsignee: [],
    currentAsignee: null,
  }),
  props: {
    reloadData: Function,
  },
  watch: {
    currentAsignee: async function() {
      if (this.currentAsignee.id) {
        this.$store.commit("setFilterStatus", true);
      } else {
        this.$store.commit("setFilterStatus", false);
      }
      this.$store.commit("setFilterName", this.currentAsignee.name);
      this.$store.commit("setAsigneeId", this.currentAsignee.id);
      this.$props.reloadData();
    },
  },
  async created() {
    const { data: asignee } = await axiosInstance.get(`/User`);
    this.$data.allAsignee = ["None", ...asignee.data];
  },
};
</script>

<style scoped>
.filter {
  z-index: 1 !important;
  width: 100% !important;
  max-width: 205px !important;
  height: 100%;
  display: flex;
  align-items: center;

  @media (min-width: 1280px) {
    min-width: 208px;
  }

  @media (min-width: 1920px) {
    min-width: 328px;
  }
}

::v-deep
  .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
  > .v-input__control
  > .v-input__slot {
  box-shadow: none;
}
</style>
