<template>
  <div>
    <notification-bell
      :icon="require('../../assets/header/bell-regular.svg')"
      :count="count"
      :upperLimit="upperLimitOfNotification"
      counterLocation="upperLeft"
      counterBackgroundColor="#F2A531"
      :animated="true"
      fontSize="9px"
      top="1px"
      left="1px"
    />
  </div>
</template>

<script>
import NotificationBell from "vue-notification-bell";
export default {
  props: {
    count: Number,
  },
  data: () => ({
    upperLimitOfNotification: 50,
  }),

  components: {
    NotificationBell,
  },
};
</script>
