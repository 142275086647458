<template>
  <v-dialog persistent v-model="dialog" max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="ml-auto global-button-vuetify"
        icon
        color="green"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon color="green">mdi-plus</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">Add technology</span>
      </v-card-title>
      <v-card-text>
        <v-row no-gutters>
          <v-col>
            <v-combobox
              v-model="technologieSelect"
              append-icon=""
              clearable
              color="blue"
              label="Technology"
              item-text="technologyName"
              hide-selected
              :items="technologys"
              :search-input.sync="technologyName"
            ></v-combobox>
            <v-form ref="form">
              <v-textarea
                clearable
                no-resize
                :rules="descriptionRules"
                label="Description"
                outlined
                v-model="description"
              >
              </v-textarea>
            </v-form>
          </v-col>
        </v-row>

        <v-card-actions class="pa-0" no-gutters>
          <v-checkbox
            v-model="checkbox"
            :label="`Add as template`"
          ></v-checkbox>
          <v-spacer></v-spacer>
          <v-btn text @click="(dialog = false), clearAllData()">
            Close
          </v-btn>
          <v-btn color="primary" @click="addTechnology()">
            Add
          </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import axiosInstance from "@/axios";
//import { config } from "../../../../config.js";
import _ from "lodash";
export default {
  props: {
    text: String,
    addTechnologyLocal: Function,
  },
  data: () => ({
    technologyName: "",
    dialog: false,
    checkbox: false,
    description: "",
    descriptionRules: [(v) => !!v || "Description is required"],
    technologys: [],
    technologieSelect: "",
    isTemplate: false,
  }),
  mounted() {
    this.$data.technologieSelect = this.$props.text;
  },
  watch: {
    technologieSelect: function() {
      if (
        typeof this.technologieSelect === "object" &&
        this.technologieSelect
      ) {
        this.$data.description = this.technologieSelect.description;
      } else {
        this.$data.description = "";
      }
    },
    technologyName: async function() {
      try {
        const { data } = await axiosInstance.get(
          `/Dictionary/technologies?search=${this.$data.technologyName}`,
          {
            headers: {},
          }
        );

        if (data) {
          this.$data.technologys = data.data;
        }
      } catch (err) {
        console.log(err);
      }
    },
  },
  methods: {
    clearAllData() {
      this.$data.technologyName = "";
      this.$data.checkbox = false;
      this.$data.description = "";
      this.$refs.form.resetValidation();
    },

    async addTechnology() {
      let validation = this.$refs.form.validate();
      if (validation) {
        let obj = {
          technologyName: this.$data.technologyName,
          description: this.$data.description,
        };

        this.$data.technologys.forEach((element) => {
          delete element.id;
          this.$data.isTemplate = _.isEqual(element, obj);
        });

        if (this.$data.checkbox) {
          if (!this.$data.isTemplate) {
            try {
              await axiosInstance.post(`/Dictionary/technologies`, obj);
            } catch (e) {
              console.log(e);
            }
            this.$props.addTechnologyLocal(obj);
            this.$data.dialog = false;
            this.clearAllData();
          } else {
            this.$swal({
              icon: "error",
              title: "This template already exist!",
            });
            this.clearAllData();
          }
        } else {
          this.$props.addTechnologyLocal(obj);
        }
      }
    },
  },
};
</script>
