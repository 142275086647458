var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"no-gutters":""}},_vm._l((_vm.groups),function(group){return _c('v-col',{key:group.id,staticClass:"group-column mx-3"},[_c('div',{class:_vm.windowTop > 136 ? 'group-header group-header-sticky' : 'group-header'},[_c('h2',[_vm._v(_vm._s(group.name))])]),_c('draggable',{staticStyle:{"height":"100%"},attrs:{"animation":200,"ghost-class":"moving-card","group":"leads"},on:{"change":function($event){return _vm.onGroupsChange($event, group.status, group)}},model:{value:(group.items),callback:function ($$v) {_vm.$set(group, "items", $$v)},expression:"group.items"}},_vm._l((group.items),function(ref,i){
        var id = ref.id;
        var title = ref.title;
        var organization = ref.organization;
        var creationDate = ref.creationDate;
        var assigneeInitials = ref.assigneeInitials;
        var assigneeName = ref.assigneeName;
        var dueDate = ref.dueDate;
        var overdue = ref.overdue;
return _c('div',{key:i,staticClass:"drag-item",on:{"click":function($event){return _vm.$router.push(("/leads/" + id))}}},[_c('div',{staticClass:"card-container"},[_c('div',{staticClass:"lead-title"},[_vm._v(_vm._s(title))]),_c('div',{staticClass:"lead-organization"},[_vm._v(_vm._s(organization))]),_c('div',{staticClass:"lead-creation-date"},[_vm._v(_vm._s(creationDate))]),_c('div',{staticClass:"icon-initials-container"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[(overdue)?_c('div',[_c('i',{staticClass:"fas fa-bell bell-icon"})]):_vm._e()])]}}],null,true)},[_c('span',[_vm._v("Ticket is overdue on "+_vm._s(_vm.filterDate(dueDate))+". "),_c('div',[_vm._v("Please execute it immediately")])])]),(assigneeInitials)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"lead-assignee-initials-container"},'div',attrs,false),on),[_c('div',{staticClass:"lead-assignee-initials"},[_vm._v(" "+_vm._s(assigneeInitials)+" ")])])]}}],null,true)},[_c('div',[_c('span',[_vm._v(" "+_vm._s(assigneeName)+" ")])])]):_vm._e()],1)]),_c('CardMenu',{attrs:{"id":id,"entitiesName":_vm.entitiesName,"singleEntityName":_vm.singleEntityName,"reloadData":_vm.reloadData,"menuOptions":_vm.menuOptions}})],1)}),0)],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }