var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"max-width":"900px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialogOn = ref.on;
var dialogAttrs = ref.attrs;
return [_c('v-tooltip',{staticStyle:{"z-index":"10"},attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltipOn = ref.on;
var tooltipAttrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',tooltipAttrs,false),tooltipOn),[_c('v-btn',_vm._g(_vm._b({staticClass:"mx-3",attrs:{"fab":"","dark":"","small":"","color":"#92278e"},on:{"click":function($event){return _vm.getTemplates()}}},'v-btn',dialogAttrs,false),dialogOn),[_c('div',[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-book-open-blank-variant")])],1)])],1)]}}],null,true)},[_c('span',[_vm._v("Add architecture from template")])])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"min-height":"90vh"}},[_c('v-card-title',{staticClass:"d-flex justify-space-between"},[_c('span',{staticClass:"headline"},[_vm._v("Architecture templates")]),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("fas fa-times")])],1)],1),_c('v-card-text',[_c('v-text-field',{staticClass:"py-6",attrs:{"prepend-inner-icon":"mdi-magnify","label":"Search","solo":"","hide-details":"","clearable":""},on:{"input":_vm.handleSearch},model:{value:(_vm.searchData),callback:function ($$v) {_vm.searchData=$$v},expression:"searchData"}}),_vm._l((_vm.templates),function(ref,i){
var id = ref.id;
var fileId = ref.fileId;
var title = ref.title;
var description = ref.description;
return _c('div',{key:i,staticClass:"architecture-card"},[_c('div',{staticClass:"d-flex justify-end px-2 py-2"},[_c('v-btn',{attrs:{"fab":"","dark":"","small":"","color":"green"},on:{"click":function($event){return _vm.addTemplateToProposal(id)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" "+_vm._s("mdi-plus")+" ")])],1)],1),_c('div',{staticClass:"image"},[_c('iframe',{staticStyle:{"height":"240px"},attrs:{"frameborder":"0","src":("https://viewer.diagrams.net/?highlight=0000ff&nav=1#G" + fileId)}}),_c('div',{staticClass:"text"},[_c('div',{staticClass:"template-title"},[_vm._v(_vm._s(title))]),_c('div',{staticClass:"template-description"},[_vm._v(_vm._s(description))])])])])}),_c('div',{staticClass:"load-more-button"},[(_vm.isLoadMoreActive)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.loadMoreCards}},[_vm._v("Load more")]):_vm._e()],1)],2)],1),_c('v-snackbar',{attrs:{"color":"green","centered":"","timeout":"3000"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"pink","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.text)+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }