<template>
  <div>
    <template>
      <v-dialog persistent v-model="dialog" max-width="600px">
        <template v-slot:activator="{ on, attrs }">
          <div v-if="!edit" v-bind="attrs" v-on="on">
            <v-btn
              class="global-button-vuetify"
              icon
              color="green"
              @click="setProject"
            >
              <v-icon color="green">mdi-plus</v-icon>
            </v-btn>
          </div>
          <div
            v-else
            @click="getModelsData"
            v-bind="attrs"
            v-on="on"
            class="option"
          >
            <i :class="className" class="mr-2 icon-style" />
            <div>{{ title }}</div>
          </div>
        </template>
        <v-card>
          <v-card-title>
            <span>{{ edit ? "Edit task" : "Add task" }}</span>
          </v-card-title>
          <v-card-text class="pb-0">
            <v-form v-model="valid" ref="taskName">
              <v-textarea
                class="ma-0 pa-0"
                label="Enter task name"
                auto-grow
                rows="1"
                :rules="[rules.required]"
                row-height="15"
                v-model="taskName"
              >
              </v-textarea>
            </v-form>
            <v-row>
              <v-col>
                <v-form v-model="valid" ref="estimation">
                  <v-text-field
                    label="Estimate optimistic"
                    :rules="[
                      rules.numberRule,
                      rules.required,
                      rules.negativeValue,
                      opimisticEstimate,
                    ]"
                    v-model="optimistic"
                  >
                  </v-text-field>
                  <v-text-field
                    label="Estimate pessimistic"
                    :rules="[
                      rules.numberRule,
                      rules.required,
                      rules.negativeValue,
                      pessimisticEstimate,
                    ]"
                    v-model="pessimistic"
                  >
                  </v-text-field>
                  <v-text-field
                    label="Estimate realistic"
                    :rules="[
                      rules.numberRule,
                      rules.required,
                      rules.negativeValue,
                      realisticEstimate,
                    ]"
                    v-model="realistic"
                  >
                  </v-text-field>
                </v-form>
              </v-col>
              <v-col>
                <div class="mt-3">
                  <v-textarea
                    filled
                    outlined
                    dense
                    label="Estimate"
                    auto-grow
                    rows="1"
                    row-height="15"
                    disabled
                    v-model="estimate"
                    :loading="loading"
                  ></v-textarea>
                  <v-textarea
                    filled
                    outlined
                    dense
                    auto-grow
                    rows="1"
                    row-height="15"
                    label="Diviation"
                    disabled
                    v-model="diviation"
                    :loading="loading"
                  ></v-textarea>
                </div>
              </v-col>
            </v-row>
            <v-select
              v-if="!edit"
              class="pa-0 ma-0 mt-3"
              :items="projects"
              item-text="name"
              v-model="selectedProject"
              label="Projects"
              return-object
            ></v-select>
            <v-select
              v-else
              class="pa-0 ma-0 mt-3"
              :items="editProjects"
              item-text="name"
              v-model="selectedProject"
              label="Projects"
              return-object
            ></v-select>
          </v-card-text>
          <v-card-actions v-if="!edit" class="px-6">
            <v-checkbox
              class="pa-0 ma-0"
              label="Add as template"
              v-model="checkbox"
            ></v-checkbox>
            <v-spacer></v-spacer>
            <v-btn text @click="clearData()">
              Close
            </v-btn>
            <v-btn color="primary" @click="addTask()">
              Add
            </v-btn>
          </v-card-actions>
          <v-card-actions v-else class="px-6">
            <v-checkbox
              class="pt-0 mt-0"
              label="Add as template"
              v-model="checkbox"
            ></v-checkbox>
            <v-spacer></v-spacer>
            <v-btn text @click="closeWindow()">
              Close
            </v-btn>
            <v-btn color="primary" @click="editTask()">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </div>
</template>

<script>
import axiosInstance from "@/axios";
import _ from "lodash";
export default {
  props: {
    edit: Boolean,
    projectId: Number,
    projects: Array,
    refreshData: Function,
    className: String,
    title: String,
    taskId: Number,
    refresh: Function,
    closeMenu: Function,
  },
  watch: {
    optimistic: function() {
      this.Estimation();
      this.$refs.estimation.validate();
    },
    pessimistic: function() {
      this.Estimation();
      this.$refs.estimation.validate();
    },
    realistic: function() {
      this.Estimation();
      this.$refs.estimation.validate();
    },
  },
  computed: {
    opimisticEstimate() {
      const rule = (opt) =>
        Number(opt) < Number(this.pessimistic) ||
        `Optimistic should be smaller than pessimistic!`;

      return rule;
    },
    pessimisticEstimate() {
      const rule = (pess) =>
        Number(pess) > Number(this.optimistic) ||
        `Pessimistic should be bigger than optimistic!`;

      return rule;
    },
    realisticEstimate() {
      const rule = (real) => {
        if (
          Number(real) >= Number(this.optimistic) &&
          Number(real) <= Number(this.pessimistic)
        ) {
          return true;
        } else {
          return `Realistic should be between optimistic and pessimistic estimate!`;
        }
      };

      return rule;
    },
  },

  data: () => ({
    selectedProject: {},
    editProjects: [],
    checkbox: false,
    dialog: false,
    taskName: "",
    loading: false,
    estimate: 0,
    diviation: 0,
    valid: false,
    rules: {
      required: (v) => !!v || "This field is required",
      allocateValue: (v) =>
        (v >= 1 && v <= 100) ||
        "Allocation can`t be greater than 100 or less than 1",
      negativeValue: (v) => v >= 0 || "This value can`t be negative!",
      numberRule: (v) => {
        if (!isNaN(Number(v))) return true;
        return "Should be a number";
      },
    },
    optimistic: null,
    pessimistic: null,
    realistic: null,
  }),
  methods: {
    setProject() {
      this.$data.selectedProject = _(this.$props.projects).find(
        (el) => el.id == this.$props.projectId
      );
    },

    customValidation() {
      const mainValidationRules = (v) => !isNaN(Number(v)) && !!v && v >= 0;
      let optimisticValidation =
        Number(this.optimistic) < Number(this.pessimistic) &&
        mainValidationRules(Number(this.optimistic));
      let pessimisticValidation =
        Number(this.pessimistic) > Number(this.optimistic) &&
        mainValidationRules(Number(this.pessimistic));
      let realisticValidation =
        Number(this.realistic) >= Number(this.optimistic) &&
        Number(this.realistic) <= Number(this.pessimistic) &&
        mainValidationRules(Number(this.realistic));
      return (
        optimisticValidation && pessimisticValidation && realisticValidation
      );
    },

    async editTask() {
      this.$refs.estimation.validate();
      this.$refs.taskName.validate();
      if (this.$data.valid) {
        let hours = {
          optimistic: this.$data.optimistic,
          pessimistic: this.$data.pessimistic,
          realistic: this.$data.realistic,
        };

        let obj = {
          name: this.$data.taskName,
          optimisticHours: Number(hours.optimistic),
          pesimisticHours: Number(hours.pessimistic),
          realisticHours: Number(hours.realistic),
          projectId: Number(this.$data.selectedProject.id),
          saveAsTemplate: this.$data.checkbox,
        };

        try {
          await axiosInstance.put(
            `/proposal/${this.$route.params.id}/Task/${this.$props.taskId}`,
            obj
          );
        } catch (e) {
          console.log(e);
        }

        this.closeWindow();
      }
    },

    async getModelsData() {
      this.$store.commit("setCardMenuCloseStatus", false);
      try {
        const {
          data: { data: tasks },
        } = await axiosInstance.get(`/proposal/${this.$route.params.id}/Task`, {
          headers: {},
        });

        const {
          data: { data: proj },
        } = await axiosInstance.get(
          `/proposal/${this.$route.params.id}/Project`
        );

        for (let key in proj) {
          let tmp = {
            id: proj[key].id,
            name: proj[key].title,
          };
          this.$data.editProjects.push(tmp);
        }

        var currentElement = _(tasks).find((el) => el.id == this.$props.taskId);

        if (tasks) {
          this.$data.taskName = currentElement.name;
          this.$data.optimistic = currentElement.optimisticHours;
          this.$data.pessimistic = currentElement.pesimisticHours;
          this.$data.realistic = currentElement.realisticHours;

          this.$data.selectedProject = {
            id: currentElement.projectId,
            name: currentElement.projectName,
          };
          this.Estimation();
        }
      } catch (err) {
        console.log(err);
      }
    },

    closeWindow() {
      this.$props.closeMenu();
      this.$props.refresh();
      this.$data.dialog = false;
      this.$refs.taskName.resetValidation();
      this.$refs.estimation.resetValidation();
      this.$store.commit("setCardMenuCloseStatus", true);
    },

    clearData() {
      this.$data.selectedProject = {};
      this.$data.checkbox = false;
      this.$data.taskName = "";
      this.$data.optimistic = null;
      this.$data.pessimistic = null;
      this.$data.realistic = null;
      this.$data.estimate = 0;
      this.$data.diviation = 0;
      this.$refs.taskName.resetValidation();
      this.$refs.estimation.resetValidation();
      this.$data.dialog = false;
    },
    async addTask() {
      this.$refs.estimation.validate();
      this.$refs.taskName.validate();

      if (this.$data.valid) {
        try {
          let hours = {
            optimistic: this.$data.optimistic,
            pessimistic: this.$data.pessimistic,
            realistic: this.$data.realistic,
          };

          let obj = {
            name: this.$data.taskName,
            optimisticHours: Number(hours.optimistic),
            pesimisticHours: Number(hours.pessimistic),
            realisticHours: Number(hours.realistic),
            projectId: Number(this.$data.selectedProject.id),
            saveAsTemplate: this.$data.checkbox,
          };

          await axiosInstance.post(
            `/proposal/${this.$route.params.id}/Task`,
            obj
          );

          this.$props.refreshData();
          this.$refs.estimation.resetValidation();
          this.$refs.taskName.resetValidation();
          this.clearData();
        } catch (e) {
          console.log(e);
        }
      }
    },
    async Estimation() {
      try {
        let hours = {
          optimistic: this.$data.optimistic,
          pessimistic: this.$data.pessimistic,
          realistic: this.$data.realistic,
        };
        let validation = null;
        if (this.$props.edit) {
          validation = this.customValidation();
        } else {
          validation = this.$refs.estimation.validate();
        }

        if (validation) {
          this.$data.loading = true;
          const { data: estimate } = await axiosInstance.get(
            `/Estimation?Optimistic=${hours.optimistic}&Pessimistic=${hours.pessimistic}&Realistic=${hours.realistic}&EstimationType=1`
          );

          const { data: diviation } = await axiosInstance.get(
            `/Estimation?Optimistic=${hours.optimistic}&Pessimistic=${hours.pessimistic}&Realistic=${hours.realistic}&EstimationType=2`
          );

          this.$data.estimate = estimate.data.estimate;
          this.$data.diviation = diviation.data.diviation;
          this.$data.loading = false;
        }
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style scoped>
.option {
  display: flex;
  align-items: center;
  padding: 12px;
}
.icon-style {
  width: 20px;
}
</style>
