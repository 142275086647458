<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <div elevation="0" v-bind="attrs" v-on="on">
        <Notifications :count="countOfNotifications" />
      </div>
    </template>
    <v-list
      class="py-0 mt-4"
      color="white"
      max-width="400px"
      max-height="400px"
      v-if="countOfNotifications"
    >
      <v-list-item
        v-for="({ id, text, creationDate, source },
        index) in listOfNotifications"
        :key="index"
        class="list-content-on"
        :class="index % 2 === 0 ? 'list-content-bg' : ''"
      >
        <v-list-item-content class="py-2">
          <div class="d-flex flex-row">
            <div>
              <div v-if="source.includes('Lead')">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      <v-icon small class="pr-1">mdi-account</v-icon>
                    </div>
                  </template>
                  <span>{{ source }}</span>
                </v-tooltip>
              </div>
              <div v-else>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      <v-icon small class="pr-1">mdi-file-document</v-icon>
                    </div>
                  </template>
                  <span>{{ source }}</span>
                </v-tooltip>
              </div>
            </div>
            <div>
              <v-list-item-title v-html="text"></v-list-item-title>
              <v-list-item-subtitle
                v-html="formatDate(creationDate)"
              ></v-list-item-subtitle>
            </div>
          </div>
        </v-list-item-content>
        <div v-on:click.stop.prevent="deleteNotification(id)" class="cross">
          <i class="fas fa-times"></i>
        </div>
      </v-list-item>
    </v-list>
    <v-list class="pa-1" v-else>No notifications</v-list>
  </v-menu>
</template>

<script>
//import { config } from "../../../config.js";
import axiosInstance from "@/axios";
import Notifications from "./Notifications";
export default {
  components: {
    Notifications,
  },
  data: () => ({
    listOfNotifications: [],
    countOfNotifications: 0,
  }),
  //#r change to created
  async created() {
    this.refreshListOfNotifications();
  },
  methods: {
    //#r cahgne name to formatDate
    formatDate(creationDate) {
      // #r use moment
      let newDate = new Date(creationDate.split(".").shift() + "Z");

      let date = newDate.toLocaleString().split(",");
      let time = date.pop();

      let result = time;
      return result;
    },
    //#r naming
    async refreshListOfNotifications() {
      const { data } = await axiosInstance.get(`/alerts`);

      this.$data.listOfNotifications = data.data;
      this.$data.countOfNotifications = data.data.length;
    },
    async deleteNotification(id) {
      try {
        await axiosInstance.delete(`/alerts/${id}`);

        this.refreshListOfNotifications();
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>

<style scoped>
::v-deep .v-list-item {
  background-color: white !important;
}

.list-content-bg {
  background-color: #f7f7f7 !important;
}

.list-content-on {
  cursor: default;
  transition: 0.5s;
}

.list-content-on:hover {
  background-color: rgb(233, 235, 241) !important;
}

.cross {
  color: #757575;
  margin-left: auto;
  cursor: pointer;
  padding-left: 5px;
}

.cross:hover {
  color: red;
}
</style>
