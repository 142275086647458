<template>
  <v-card>
    <v-card-title class="card-title-style d-flex align-center">
      <div>
        Summary
      </div>
    </v-card-title>
    <v-card-text class="my-5 px-6">
      <div class="scrollable-table">
        <v-data-table
          :headers="headers"
          :items="elements"
          hide-default-footer
          disable-sort
          :items-per-page="elements.length"
        ></v-data-table>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import _ from "lodash";
export default {
  data: () => ({
    isDataRefreshed: false,
    headers: [
      {
        text: "Milestones",
        align: "start",
        value: "milestoneName",
        width: "1%",
      },
      { text: "Scope hours", value: "scopeHours", width: "1%" },
    ],
  }),
  computed: {
    elements() {
      return _(this.$store.state.milestoneSummary).forEachRight(
        (value) =>
          (value = _(value).forInRight((element, key) =>
            typeof element == "number"
              ? (value[key] = _(element).floor(2))
              : element
          ))
      );
    },
  },
  async created() {
    await this.$store.dispatch("getMilestoneSummary", this.$route.params.id);
  },
};
</script>

<style scoped>
.scrollable-table {
  max-height: 50vh;
  overflow-y: auto;
}
</style>
