<template>
  <v-card>
    <v-card-text class="px-6">
      <div>
        <div>
          <v-autocomplete
            prepend-icon="mdi-account-box"
            :items="allAsignee"
            v-model="currentAsignee"
            item-text="name"
            label="Select asignee"
            persistent-hint
            return-object
            single-line
            attach
          ></v-autocomplete>
        </div>

        <div>
          <v-select
            prepend-icon="mdi-table-settings"
            :items="sections"
            v-model="selectMoveTo"
            item-text="title"
            item-value="title"
            label="Move to"
            persistent-hint
            return-object
            single-line
            attach
          ></v-select>
        </div>
      </div>

      <div>
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="date"
          transition="scale-transition"
          offset-y
          min-width="auto"
          attach
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="date"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker :min="todayDate" v-model="date" no-title scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu = false">
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.menu.save(date), changeDueDate()"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </div>

      <div class="my-5">
        <div class="d-flex align-center justify-space-between">
          <div>History</div>
          <v-btn class="global-button-vuetify" icon @click="getHistory">
            <v-icon color="#92278e">mdi-autorenew</v-icon>
          </v-btn>
        </div>
        <div class="history">
          <div v-for="(item, index) in leadHistory" :key="index">
            <v-list class="py-1  px-1">
              <template>
                <v-list-item class="pa-0" :key="index">
                  <v-list-item-content class="py-0">
                    <v-list-item-title
                      style="font-size: 14px"
                      v-html="item.message"
                    ></v-list-item-title>
                    <v-list-item-subtitle
                      style="font-size: 12px"
                      v-html="filterDate(item.date) + ' | ' + item.user"
                    ></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list>
          </div>
        </div>
      </div>
      <div @click="deleteAlert()" class="delete-lead mt-7">
        <v-btn small text color="red">
          Delete lead
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import axiosInstance from "@/axios";
//import { config } from "../../../config.js";
export default {
  data: () => ({
    date: "",
    menu: false,
    sections: [
      { statusNumber: 1, title: "New" },
      { statusNumber: 2, title: "Business clarification" },
      { statusNumber: 3, title: "Requirements clarification" },
      { statusNumber: 4, title: "Preparing proposal" },
      { statusNumber: 5, title: "Done" },
    ],
    allAsignee: [],
    selectMoveTo: null,
    currentAsignee: null,
  }),
  computed: {
    //#r moment
    todayDate: function() {
      let today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0");
      var yyyy = today.getFullYear();

      today = yyyy + "-" + mm + "-" + dd;
      return today;
    },
    leadHistory() {
      return this.$store.state.history.lead;
    },
  },
  async mounted() {
    try {
      const { data } = await axiosInstance.get(`/lead?filter=1`);

      const { data: asignee } = await axiosInstance.get(`/User`);

      this.$data.allAsignee = [{ id: 0, name: "Unassigned" }, ...asignee.data];

      let currentObj = data.data.find(
        (elem) => elem.id == this.$route.params.id
      );

      let currentAsignee = asignee.data.find(
        (elem) => elem.name == currentObj.assigneeName
      );

      let currentStatus = this.$data.sections.find(
        (elem) => elem.statusNumber == currentObj.status
      );

      if (data) {
        this.$data.selectMoveTo = currentStatus;
        if (currentAsignee) {
          this.$data.currentAsignee = currentAsignee;
        } else {
          this.$data.currentAsignee = { id: 0, name: "Unassigned" };
        }
        if (currentObj.dueDate) {
          this.$data.date = currentObj.dueDate.split("T").shift();
        }
      }
    } catch (err) {
      console.log(err);
    }
  },
  watch: {
    selectMoveTo: async function() {
      try {
        await axiosInstance.put(
          `/Lead/${this.$route.params.id}/status?status=${this.$data.selectMoveTo.statusNumber}`,
          {}
        );
      } catch (er) {
        console.log(er);
      }
    },
    currentAsignee: async function() {
      if (this.$data.currentAsignee.id) {
        try {
          await axiosInstance.put(
            `/Lead/${this.$route.params.id}/assignee?assigneeId=${this.$data.currentAsignee.id}`
          );
        } catch (er) {
          console.log(er);
        }
      } else {
        try {
          await axiosInstance.delete(`/Lead/${this.$route.params.id}/assignee`);
        } catch (er) {
          console.log(er);
        }
      }
    },
  },
  methods: {
    filterDate(creationDate) {
      let newDate = new Date(creationDate.split(".").shift() + "Z");

      let date = newDate.toLocaleString().split(",");
      let time = date.pop();

      let result = date.shift() + " | " + time;
      return result;
    },
    deleteAlert() {
      this.$swal({
        title: "Are you sure about that?",
        showDenyButton: true,
        confirmButtonText: `Yes`,
        denyButtonText: `No`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteLead();
        } else if (result.isDenied) {
          return;
        }
      });
    },
    async deleteLead() {
      const leadId = this.$route.params.id;
      this.$router.go(-1);
      try {
        await axiosInstance.delete(`/Lead/${leadId}`);
      } catch (err) {
        console.log(err);
      }
    },
    async changeDueDate() {
      try {
        await axiosInstance.put(
          `/Lead/${this.$route.params.id}/duedate?duedate=${this.$data.date}`,
          {}
        );
      } catch (err) {
        console.log(err);
      }
    },
    async getHistory() {
      const leadId = this.$route.params.id;
      this.$store.dispatch("getHistory", {
        id: leadId,
        entityName: "lead",
      });
    },
  },
};
</script>

<style scoped>
.delete-lead {
  color: black;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
}

.history {
  height: 200px;
  overflow-y: scroll;
  color: black;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.history-item {
  padding: 0 5px;
}
</style>
