<template>
  <v-dialog v-model="dialog" max-width="400px">
    <template v-slot:activator="{ on: dialogOn, attrs: dialogAttrs }">
      <div class="button-container absolute-container my-auto pl-4">
        <v-tooltip style="z-index: 10;" bottom>
          <template v-slot:activator="{ on: tooltipOn, attrs: tooltipAttrs }">
            <div v-on="tooltipOn" v-bind="tooltipAttrs">
              <v-btn
                fab
                dark
                small
                color="green"
                v-on="dialogOn"
                v-bind="dialogAttrs"
              >
                <v-icon dark>
                  {{ "mdi-plus" }}
                </v-icon>
              </v-btn>
            </div>
          </template>
          <span>Add lead</span>
        </v-tooltip>
      </div>
    </template>

    <v-card>
      <v-card-title>
        <span class="headline">New lead</span>
      </v-card-title>

      <v-text-field
        v-model="pipeDriveDealId"
        dense
        clearable
        clear-icon="$clear"
        color="dark"
        hide-details
        label="Pipe drive deal id or url"
        class="input"
      />

      <div class="error-message">
        {{ errorMessage }}
      </div>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="dialog = false">
          Close
        </v-btn>
        <v-btn
          v-if="!isDataSent"
          :disabled="!pipeDriveDealId"
          color="primary"
          @click="addLead"
        >
          Add
        </v-btn>

        <v-progress-circular
          v-if="isDataSent"
          :width="3"
          color="primary"
          indeterminate
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axiosInstance from "@/axios";

export default {
  props: {
    reloadData: Function,
  },
  data: () => ({
    dialog: false,
    pipeDriveDealId: "",
    isDataSent: false,
    errorMessage: null,
  }),
  methods: {
    async addLead() {
      try {
        this.$data.isDataSent = true;
        const pipeDriveDealId = this.$data.pipeDriveDealId.split("/").pop();

        await axiosInstance.post(
          `/lead`,
          { pipeDriveDealId: Number(pipeDriveDealId) },
          {
            headers: {},
          }
        );

        this.$data.isDataSent = this.$data.dialog = false;
        this.$data.errorMessage = this.$data.pipeDriveDealId = null;
        this.$props.reloadData();
      } catch (e) {
        this.$data.isDataSent = false;
        this.$data.errorMessage =
          e.response.data.Message || "wrong pipe drive id or url";
      }
    },
  },
};
</script>

<style scoped>
.button-container {
  display: flex;
  align-items: center;
}

.action-name {
  font-size: 15px;
}

.input {
  width: 350px;
  padding: 20px 24px 5px;
}

.error-message {
  color: red;
  font-size: 12px;
  font-weight: 300;
  padding: 0 24px 20px;
}

.absolute-container {
  position: absolute;
  left: -7%;

  @media (min-width: 1280px) {
    left: -5%;
  }

  @media (min-width: 1920px) {
    left: -4%;
  }
}
</style>
