<template>
  <v-card elevation="2">
    <v-card-title class="card-title-style justify-space-between"
      >{{ expansionHeader }}
    </v-card-title>
    <div>
      <div class="px-3">
        <div class="block-style">
          <div class="item-title-font">Milestone duration</div>
          <v-select
            hide-details
            :items="milestoneDurationData.options"
            label="please select"
            dense
            solo
            :value="milestoneDuration"
            class="select item-description-font"
            @change="
              (value) => handleChange(value, milestoneDurationData.serverRoute)
            "
          />
        </div>
      </div>
      <div class="px-3 card-text-bg">
        <div class="block-style ">
          <div class="item-title-font">
            Stakeholders involvement at least once per
          </div>
          <v-select
            hide-details
            :items="stakeholderInvolvmentData.options"
            label="please select"
            dense
            solo
            :value="stakeholderInvolvment"
            class="select item-description-font"
            @change="
              (value) =>
                handleChange(value, stakeholderInvolvmentData.serverRoute)
            "
          />
        </div>
      </div>
      <div class="px-3">
        <div class="block-style">
          <div class="item-title-font">
            Stakeholders response time for critical issues
          </div>
          <v-select
            hide-details
            :items="stakeholderCriticalResponseData.options"
            label="please select"
            dense
            solo
            :value="stakeholderCriticalResponse"
            class="select item-description-font"
            @change="
              (value) =>
                handleChange(value, stakeholderCriticalResponseData.serverRoute)
            "
          />
        </div>
      </div>
      <div class="px-3 card-text-bg">
        <div class="block-style ">
          <div class="item-title-font">
            Stakeholders response time for not critical issues
          </div>
          <v-select
            hide-details
            :items="stakeholderNotCriticalResponseData.options"
            label="please select"
            dense
            solo
            :value="stakeholderNotCriticalResponse"
            class="select item-description-font"
            @change="
              (value) =>
                handleChange(
                  value,
                  stakeholderNotCriticalResponseData.serverRoute
                )
            "
          />
        </div>
      </div>
      <div class="px-3">
        <div class="block-style">
          <div class="item-title-font">Primary communication channel</div>
          <v-select
            hide-details
            :items="primaryCommunicationChannelData.options"
            label="please select "
            dense
            solo
            :value="primaryCommunicationChannel"
            class="select item-description-font"
            @change="
              (value) =>
                handleChange(value, primaryCommunicationChannelData.serverRoute)
            "
          />
        </div>
      </div>
    </div>
  </v-card>
</template>
<script>
import axiosInstance from "@/axios";
//import { config } from "../../../config";

export default {
  data: () => ({
    expansionHeader: "SDLC",
    milestoneDurationData: {
      options: [
        { value: 1, text: "1 Week" },
        { value: 2, text: "2 Weeks" },
        { value: 3, text: "3 Weeks" },
        { value: 4, text: "4 Weeks" },
      ],
      serverRoute: "milestone/duration",
    },
    stakeholderInvolvmentData: {
      options: [
        { value: 1, text: "1 Day" },
        { value: 2, text: "2 Days" },
        { value: 3, text: "3 Days" },
        { value: 4, text: "4 Days" },
        { value: 5, text: "5 Days" },
        { value: 6, text: "6 Days" },
        { value: 7, text: "7 Days" },
        { value: 8, text: "8 Days" },
        { value: 9, text: "9 Days" },
        { value: 10, text: "10 Days" },
        { value: 11, text: "11 Days" },
        { value: 12, text: "12 Days" },
        { value: 13, text: "13 Days" },
        { value: 14, text: "14 Days" },
      ],
      serverRoute: "stakeholder/involvement",
    },
    stakeholderCriticalResponseData: {
      options: [
        { value: 1, text: "1 Hour" },
        { value: 2, text: "2 Hours" },
        { value: 4, text: "4 Hours" },
        { value: 6, text: "6 Hours" },
        { value: 8, text: "8 Hours" },
        { value: 12, text: "12 Hours" },
        { value: 18, text: "18 Hours" },
        { value: 24, text: "24 Hours" },
        { value: 36, text: "36 Hours" },
        { value: 48, text: "48 Hours" },
      ],
      serverRoute: "stakeholder/response/critical",
    },
    stakeholderNotCriticalResponseData: {
      options: [
        { value: 4, text: "4 Hours" },
        { value: 8, text: "8 Hours" },
        { value: 12, text: "12 Hours" },
        { value: 18, text: "18 Hours" },
        { value: 24, text: "24 Hours" },
        { value: 36, text: "36 Hours" },
        { value: 48, text: "48 Hours" },
        { value: 72, text: "72 Hours" },
        { value: 168, text: "168 Hours" },
      ],
      serverRoute: "stakeholder/response/noncritical",
    },
    primaryCommunicationChannelData: {
      options: [
        { value: 1, text: "Email" },
        { value: 2, text: "Messenger" },
      ],
      serverRoute: "communication/channel",
    },
  }),
  methods: {
    async handleChange(value, route) {
      try {
        await axiosInstance.put(
          `/proposal/${this.$route.params.id}/SingleValue/${route}/${value}`,
          {},
          {
            headers: {
              "Content-Type": "application/problem+json; charset=utf-8",
            },
          }
        );
        await this.$store.dispatch("getSummaryData", this.$route.params.id);
        this.$store.dispatch("getCurrentProposalData", this.$route.params.id);
      } catch (e) {
        console.log(e);
      }
    },
  },
  computed: {
    milestoneDuration() {
      return this.$store.state.singleValue.milestoneDuration;
    },
    stakeholderInvolvment() {
      return this.$store.state.singleValue.stakeholderInvolvment;
    },
    stakeholderCriticalResponse() {
      return this.$store.state.singleValue.stakeholderCriticalResponse;
    },
    stakeholderNotCriticalResponse() {
      return this.$store.state.singleValue.stakeholderNotCriticalResponse;
    },
    primaryCommunicationChannel() {
      return this.$store.state.singleValue.primaryCommunicationChannel;
    },
  },
};
</script>
<style scoped>
.block-style {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0;
  /* max-width: 800px; */
  padding: 8px 16px;
}
.select {
  max-width: 200px;
}
</style>
