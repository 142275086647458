var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({attrs:{"elevation":"0"}},'div',attrs,false),on),[_c('Notifications',{attrs:{"count":_vm.countOfNotifications}})],1)]}}])},[(_vm.countOfNotifications)?_c('v-list',{staticClass:"py-0 mt-4",attrs:{"color":"white","max-width":"400px","max-height":"400px"}},_vm._l((_vm.listOfNotifications),function(ref,index){
var id = ref.id;
var text = ref.text;
var creationDate = ref.creationDate;
var source = ref.source;
return _c('v-list-item',{key:index,staticClass:"list-content-on",class:index % 2 === 0 ? 'list-content-bg' : ''},[_c('v-list-item-content',{staticClass:"py-2"},[_c('div',{staticClass:"d-flex flex-row"},[_c('div',[(source.includes('Lead'))?_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-icon',{staticClass:"pr-1",attrs:{"small":""}},[_vm._v("mdi-account")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(source))])])],1):_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-icon',{staticClass:"pr-1",attrs:{"small":""}},[_vm._v("mdi-file-document")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(source))])])],1)]),_c('div',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(text)}}),_c('v-list-item-subtitle',{domProps:{"innerHTML":_vm._s(_vm.formatDate(creationDate))}})],1)])]),_c('div',{staticClass:"cross",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.deleteNotification(id)}}},[_c('i',{staticClass:"fas fa-times"})])],1)}),1):_c('v-list',{staticClass:"pa-1"},[_vm._v("No notifications")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }