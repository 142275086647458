<template>
  <v-card>
    <v-card-title class="card-title-style my-auto"
      >Summary
      <div class="ml-auto d-flex align-center">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <v-btn icon color="primary" @click="autofill">
                <v-icon>mdi-auto-download</v-icon>
              </v-btn>
            </div>
          </template>
          <span>Autofill all services</span>
        </v-tooltip>
      </div>
    </v-card-title>
    <v-card-text class="my-5 px-6">
      <v-data-table
        :headers="headers"
        :items="elements"
        :items-per-page="5"
        hide-default-footer
        disable-sort
      ></v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import axiosInstance from "@/axios";
import _ from "lodash";
export default {
  data: () => ({
    headers: [
      {
        text: "Services",
        align: "start",
        value: "serviceTypeTitle",
        width: "1%",
      },
      { text: "Optimistic hours", value: "optimisticHours", width: "1%" },
      { text: "Pesimistic hours", value: "pesimisticHours", width: "1%" },
      { text: "Realistic hours", value: "realisticHours", width: "1%" },
      { text: "Estimate hours", value: "estimateHours", width: "1%" },
      { text: "Standard deviation", value: "standardDeviation", width: "1%" },
    ],
  }),
  computed: {
    elements() {
      return _(this.$store.state.servicesSummary).forEachRight(
        (value) =>
          (value = _(value).forInRight((element, key) =>
            typeof element == "number"
              ? (value[key] = _(element).floor(2))
              : element
          ))
      );
    },
  },
  async created() {
    // await this.$store.dispatch("getSummaryServices", this.$route.params.id);
  },
  methods: {
    async autofill() {
      try {
        await axiosInstance.post(
          `/proposal/${this.$route.params.id}/Service/auto`,
          {}
        );

        await this.$store.dispatch("refreshServices", this.$route.params.id);
        await this.$store.dispatch("getSummaryServices", this.$route.params.id);
        await this.$store.dispatch("getSimpleServices", this.$route.params.id);
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>
