var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._l((_vm.expansionPanelContent),function(item,index){return _c('v-card',{key:index,staticClass:"mb-10",attrs:{"elevation":"2"}},[_c('v-card-title',{staticClass:"card-title-style"},[_vm._v(_vm._s(item.name)+" "),_c('div',{staticClass:"ml-auto d-flex flex-row"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('AddTemplate',{attrs:{"refreshData":_vm.refreshData,"projectId":item.projectId}})],1)]}}],null,true)},[_c('span',[_vm._v("Add tasks from templates")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('AddTask',{attrs:{"projectId":item.projectId,"refreshData":_vm.refreshData,"projects":_vm.groupedByNameId}})],1)]}}],null,true)},[_c('span',[_vm._v("Add task")])])],1)]),_c('v-card-text',[_c('div',[_c('draggable',{staticStyle:{"height":"100%"},attrs:{"animation":200,"ghost-class":"moving-card","group":"tasks"},on:{"change":function($event){return _vm.onGroupsChange($event, item.projectId)}},model:{value:(item.tasks),callback:function ($$v) {_vm.$set(item, "tasks", $$v)},expression:"item.tasks"}},_vm._l((item.tasks),function(ref,index){
            var id = ref.id;
            var name = ref.name;
            var optimisticHours = ref.optimisticHours;
            var pesimisticHours = ref.pesimisticHours;
            var realisticHours = ref.realisticHours;
return _c('v-card',{key:index,staticClass:"pa-3 py-5 mt-5 black--text",attrs:{"elevation":"2"}},[_c('div',{staticClass:"d-flex flex-row"},[_c('div',[_c('div',[_c('v-card-title',{staticClass:"pb-0 pt-0"},[_vm._v(_vm._s(name))]),_c('div',[_c('v-card-text',{staticClass:"pt-0 pb-0"},[_c('div',{staticClass:"d-flex flex-row"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"d-flex flex-row align-center"},'div',attrs,false),on),[_c('v-icon',[_vm._v("mdi-clock-time-three")]),_c('div',{staticClass:"d-flex flex-row"},[_c('div',{staticClass:"mx-1"},[_vm._v(" "+_vm._s(optimisticHours + "hrs")+" ")]),_c('div',{staticClass:"mx-1"},[_vm._v(" "+_vm._s(pesimisticHours + "hrs")+" ")]),_c('div',{staticClass:"mx-1"},[_vm._v(" "+_vm._s(realisticHours + "hrs")+" ")])])],1)]}}],null,true)},[_c('span',[_vm._v("Optimistic, pesimistic, realistic hours")])])],1)])],1)],1)]),_c('div',{staticClass:"ml-auto"},[_c('CardMenu',{attrs:{"id":id,"taskId":id,"alternativeDelete":true,"menuOptions":_vm.menuOptions,"reloadData":_vm.refreshData,"singleEntityName":_vm.singleEntityName}})],1)])])}),1)],1)])],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }