var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"elevation":"2"}},[_c('v-card-title',{staticClass:"card-title-style justify-space-between"},[_vm._v(_vm._s(_vm.expansionHeader)+" "),_c('div',{staticClass:"d-flex"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"global-button-vuetify",attrs:{"icon":"","color":"green"},on:{"click":function($event){return _vm.$router.push((_vm.proposalId + "/architecture"))}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-pencil-plus")])],1)]}}])},[_vm._v(" Edit architectures ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.autoFill}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"light-blue"}},[_vm._v("mdi-auto-download")])],1)]}}])},[_vm._v(" Autofill solution architecture ")])],1)]),_c('div',[_vm._l((_vm.architectures),function(ref,i){
var title = ref.title;
var description = ref.description;
return _c('div',{key:i,class:i % 2 === 0 ? 'px-3' : 'card-text-bg px-3'},[_c('div',{staticClass:"pa-4"},[_c('div',{staticClass:"item-title-font"},[_vm._v(_vm._s(title))]),_c('div',{staticClass:"item-description-font mt-1"},[_vm._v(_vm._s(description))])])])}),(!_vm.architectures.length)?_c('div',{staticClass:"red--text px-7 py-4",staticStyle:{"font-size":"0.875rem"}},[_vm._v(" No data found! ")]):_vm._e()],2),_c('v-snackbar',{attrs:{"timeout":_vm.errorPopup.timeout,"bottom":""},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"red","text":""},on:{"click":function($event){return _vm.closePopup()}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.errorPopup.status),callback:function ($$v) {_vm.$set(_vm.errorPopup, "status", $$v)},expression:"errorPopup.status"}},[_vm._v(" "+_vm._s(_vm.errorPopup.message)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }