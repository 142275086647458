var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"elevation":"2"}},[_c('v-card-title',{staticClass:"card-title-style justify-space-between"},[_vm._v(_vm._s(_vm.expansionHeader)+" "),_c('div',{staticClass:"d-flex"},[(!_vm.isEdit)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"global-button-vuetify",attrs:{"icon":"","color":"green"},on:{"click":_vm.enableEditing}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-pencil-plus")])],1)]}}],null,false,3874625812)},[_vm._v(" Edit general summary ")]):_vm._e(),(_vm.isEdit)?_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"green"},on:{"click":_vm.editSummary}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check")])],1)]}}],null,false,2107166668)},[_vm._v(" Apply changes ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"green"},on:{"click":_vm.cancelEditing}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-close")])],1)]}}],null,false,3930895646)},[_vm._v(" Cancel changes ")])],1):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.autoFill}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"light-blue"}},[_vm._v("mdi-auto-download")])],1)]}}])},[_vm._v(" Autofill general summary ")])],1)]),_c('div',{staticClass:"px-3"},[(!_vm.isEdit && _vm.data)?_c('div',{staticClass:"pa-4 text-to-show item-description-font"},[_vm._v(" "+_vm._s(_vm.data)+" ")]):_vm._e(),(!_vm.isEdit && !_vm.data)?_c('div',{staticClass:"red--text pa-4",staticStyle:{"font-size":"0.875rem"}},[_vm._v(" No data found! ")]):_vm._e(),(_vm.isEdit)?_c('v-textarea',{staticClass:"pt-4",attrs:{"multi-line":"","label":"Enter summary","auto-grow":"","outlined":"","rows":"1","row-height":"15"},model:{value:(_vm.inputData),callback:function ($$v) {_vm.inputData=$$v},expression:"inputData"}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }