<template>
  <div>
    <v-dialog persistent v-model="dialog" max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <div
          v-bind="attrs"
          v-on="on"
          @click="fillInfo(), getLeads(), (dialog = true)"
          class="option"
        >
          <i :class="className" class="mr-2 icon-style" />
          <div>{{ title }}</div>
        </div>
      </template>
      <v-card>
        <v-card-title class="d-flex justify-space-between">
          <span v-if="!isTitleEditing" class="headline">Make a copy</span>
          <span v-if="isTitleEditing" class="headline">Edit title</span>
          <v-progress-circular
            v-if="isDataSent"
            :width="3"
            color="primary"
            indeterminate
          />
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="form" class="mx-2" lazy-validation>
              <v-row>
                <v-col cols="12">
                  <v-textarea
                    label="Title"
                    v-model="localProposalTitle"
                    :rules="titleRules"
                    auto-grow
                  >
                  </v-textarea>
                </v-col>
                <v-col cols="12" sm="6" v-if="!isTitleEditing">
                  <v-select
                    placeholder="Assign lead"
                    :disabled="!isLeadSelectionEnabled"
                    :value="leadId"
                    item-text="title"
                    item-value="id"
                    :items="leads"
                    @change="handleLeadSelecting"
                  ></v-select>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="closeWindow()"
            :disabled="isDataSent"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            @click="copyProposal()"
            :disabled="isDataSent"
            v-if="!isTitleEditing"
          >
            Add
          </v-btn>
          <v-btn
            color="primary"
            @click="editTitle()"
            :disabled="isDataSent"
            v-if="isTitleEditing"
          >
            Edit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import _ from "lodash";
import axiosInstance from "@/axios";

export default {
  props: {
    proposalTitle: String,
    leadTitle: String,
    leadId: Number,
    className: String,
    title: String,
    id: Number,
    refresh: Function,
    closeMenu: Function,
    isLeadSelectionEnabled: Boolean,
    isTitleEditing: Boolean,
  },
  data: () => ({
    dialog: false,
    selectedLead: null,
    localProposalTitle: "",
    titleRules: [(v) => !!v || "Title is required"],
    leads: [],
    isDataSent: false,
  }),
  methods: {
    async getLeads() {
      try {
        const {
          data: { data },
        } = await axiosInstance.get(`/lead?filter=1`);
        let leadsList = [...data, { id: null, title: "Not assign" }];
        leadsList = _.sortBy(leadsList, (i) => i.id).reverse();
        this.$data.leads = leadsList;
      } catch (e) {
        console.log(e.message);
      }
    },
    handleLeadSelecting(value) {
      this.$data.selectedLead = value;
    },
    async copyProposal() {
      if (!this.$data.localProposalTitle) {
        return;
      }
      let obj = {
        title: this.$data.localProposalTitle,
        leadId: this.$data.selectedLead,
      };
      try {
        this.$data.isDataSent = true;
        await axiosInstance.post(`/Proposal/${this.$props.id}/copy`, obj);
        this.$data.isDataSent = false;
      } catch (e) {
        console.log(e.message);
      }
      this.$data.dialog = false;
      this.$props.closeMenu();
      this.$store.commit("setCardMenuCloseStatus", true);
      await this.$props.refresh();
    },
    fillInfo() {
      this.$store.commit("setCardMenuCloseStatus", false);
      if (this.$props.isTitleEditing) {
        this.$data.localProposalTitle = this.$props.proposalTitle;
      } else {
        this.$data.localProposalTitle = "Copy::" + this.$props.proposalTitle;
      }
      this.$data.selectedLead = this.$props.leadId;
      if (this.$props.leadTitle) {
        this.$data.leads[0] = this.$props.leadId;
      } else {
        this.$data.selectedLead = null;
        this.$data.leads[0] = null;
      }
    },
    closeWindow() {
      this.$props.closeMenu();
      this.$data.dialog = false;
      this.$store.commit("setCardMenuCloseStatus", true);
    },
    async editTitle() {
      if (!this.$data.localProposalTitle) {
        return;
      }
      const newTitle = this.$data.localProposalTitle;
      try {
        await axiosInstance.put(
          `/Proposal/${this.$props.id}/title/${newTitle}`
        );
      } catch (e) {
        console.log(e);
      }
      this.$data.dialog = false;
      this.$props.closeMenu();
      this.$store.commit("setCardMenuCloseStatus", true);
      await this.$props.refresh();
    },
  },
};
</script>
<style scoped>
.option {
  display: flex;
  align-items: center;
  padding: 12px;
}
.icon-style {
  width: 20px;
}
</style>
