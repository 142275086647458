<template>
  <div>
    <div class="full-block">
      <NavigationBar />
      <ProposalsList />
    </div>
  </div>
</template>
<script>
import ProposalsList from "../components/ProposalsMainPage/ProposalsList";
import NavigationBar from "../components/ProposalsMainPage/NavigationBar";
export default {
  components: { ProposalsList, NavigationBar },
};
</script>
<style scoped>
.full-block {
  max-width: 900px;
  margin: 0 auto;
}
</style>
