import VueRouter from "vue-router";
import LogIn from "./pages/LogIn.vue";
import LeadDetailed from "./pages/LeadDetailed.vue";
import ProposalDetailed from "./pages/ProposalDetailed.vue";
import Leads from "./pages/Leads";
import Proposals from "./pages/Proposals";
import MilestonesDetailed from "./pages/MilestonesDetailed.vue";
import TasksDetailed from "./pages/TasksDetailed.vue";
import ServicesDetailed from "./pages/ServicesDetailed.vue";
import SolutionArchitecture from "./pages/SolutionArchitecture.vue";
import Error404 from "./pages/Error404.vue";

export default new VueRouter({
  routes: [
    {
      path: "/",
      redirect: "/leads",
    },
    {
      path: "/login",
      component: LogIn,
    },
    {
      path: "/404",
      component: Error404,
    },
    {
      path: "*",
      component: Error404,
    },
    {
      path: "/leads",
      component: Leads,
    },
    {
      path: "/leads/:id",
      name: "LeadDetailed",
      component: LeadDetailed,
      props: true,
    },
    { path: "/proposals", component: Proposals },
    {
      path: "/proposals/:id/tasks",
      name: "TasksDetailed",
      component: TasksDetailed,
      props: true,
    },
    {
      path: "/proposals/:id",
      name: "ProposalDetailed",
      component: ProposalDetailed,
      props: true,
    },
    {
      path: "/proposals/:id/milestones",
      name: "MilestonesDetailed",
      component: MilestonesDetailed,
      props: true,
    },
    {
      path: "/proposals/:id/services",
      name: "ServicesDetailed",
      component: ServicesDetailed,
      props: true,
    },
    {
      path: "/proposals/:id/architecture",
      name: "SolutionArchitecture",
      component: SolutionArchitecture,
      props: true,
    },
  ],
  mode: "history",
});
