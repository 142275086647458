<template>
  <div>
    <v-tooltip right>
      <template v-slot:activator="{ on, attrs }">
        <div class="selector" v-bind="attrs" v-on="on">
          <div
            class="option"
            @click="selectType(1)"
            :class="{ active: type === 1 }"
          >
            Active
          </div>
          <div
            class="option"
            @click="selectType(2)"
            :class="{ active: type === 2 }"
          >
            Archived
          </div>
        </div>
      </template>
      <span>Filter type</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  computed: {
    type() {
      return this.$store.state.filterType;
    },
  },
  props: {
    reloadData: Function,
  },
  methods: {
    selectType(type) {
      this.$store.commit("setFilterType", type);
      this.$props.reloadData();
    },
  },
};
</script>

<style scoped>
.selector {
  display: flex;
  background-color: #f4f5f7;
  border-radius: 5px;
  overflow: hidden;
  height: 40px;
  /* max-width: 205px; */
  width: 100%;

  @media (min-width: 1280px) {
    width: 208px;
  }

  @media (min-width: 1920px) {
    width: 328px;
  }
}

.option {
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  flex: 1;
  text-align: center;
}

.option.active {
  background-color: #4CAF50;
  color: white;
}

.selector:hover .v-tooltip__content {
  opacity: 1;
}

.selector:hover .v-tooltip__popper {
  opacity: 1;
}

.selector .v-tooltip__content {
  opacity: 0;
  transition: opacity 0.3s;
}

.selector .v-tooltip__popper {
  opacity: 0;
  transition: opacity 0.3s;
}
</style>
