var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"elevation":"2"}},[_c('v-card-title',{staticClass:"card-title-style justify-space-between",attrs:{"id":"tasks"}},[_vm._v(_vm._s(_vm.expansionHeader)+" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$router.push(((_vm.$route.params.id) + "/tasks"))}}},[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-pencil-plus")])],1)],1)]}}])},[_c('span',[_vm._v("Open task detailed page")])])],1),(_vm.isEmptyObj)?_c('div',{staticClass:" black--text"},_vm._l((_vm.expansionPanelContent),function(ref,index){
        var id = ref.id;
        var name = ref.name;
        var projectName = ref.projectName;
        var milestoneName = ref.milestoneName;
        var estimateHours = ref.estimateHours;
        var standardDeviation = ref.standardDeviation;
return _c('div',{key:index,class:index % 2 === 0 ? '' : 'card-text-bg'},[_c('div',{staticClass:"d-flex flex-row px-3"},[_c('div',{staticClass:"pa-4"},[_c('div',{staticClass:"item-title-font"},[_vm._v(_vm._s(name))]),_c('div',{staticClass:"d-flex align-center mr-1 mt-1"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('div',{staticClass:"d-flex flex-row align-center mr-1"},[_c('v-icon',[_vm._v("mdi-folder")]),_c('div',{staticClass:"mx-1 item-description-font"},[_vm._v(" "+_vm._s(projectName)+" ")])],1)])]}}],null,true)},[_c('span',[_vm._v("Project")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('div',{staticClass:"d-flex flex-row align-center mr-1"},[_c('v-icon',[_vm._v("mdi-calendar-check")]),(milestoneName)?_c('div',{staticClass:"ml-1 item-description-font"},[_vm._v(" "+_vm._s(milestoneName)+" ")]):_c('div',{staticClass:"mx-1 item-description-font"},[_vm._v(" Unassigned ")])],1)])]}}],null,true)},[_c('span',[_vm._v("Milestone")])]),_c('div',{staticClass:"d-flex flex-row align-center mr-1"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('div',{staticClass:"d-flex"},[_c('v-icon',[_vm._v("mdi-calendar-clock")]),_c('div',{staticClass:"mx-1 item-description-font"},[_vm._v(" "+_vm._s(estimateHours + "hrs")+" ")])],1)])]}}],null,true)},[_c('span',[_vm._v("Estimate hours")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('div',{staticClass:"d-flex"},[_c('v-icon',[_vm._v("mdi-timelapse")]),_c('div',{staticClass:"mx-1 item-description-font"},[_vm._v(" "+_vm._s(standardDeviation)+" ")])],1)])]}}],null,true)},[_c('span',[_vm._v("Standart deviation")])])],1)],1)]),_c('div',{staticClass:"ml-auto my-auto"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-btn',{staticClass:"color-red",attrs:{"icon":""},on:{"click":function($event){return _vm.deleteAlert(id)}}},[_c('div',[_c('v-icon',[_vm._v("mdi-delete-empty-outline")])],1)])],1)]}}],null,true)},[_c('span',[_vm._v("Delete task")])])],1)])])}),0):_c('v-card-text',{staticClass:"black--text mt-5 pl-7"},[_c('div',{staticClass:"red--text"},[_vm._v("No data found!")]),_c('div',{staticClass:"item-description-font"},[_vm._v(" Try to create project, and fill them with tasks on the "),_c('a',{attrs:{"href":("/proposals/" + (_vm.$route.params.id) + "/tasks")}},[_vm._v("detail page")]),_vm._v(". ")])]),(_vm.isEmptyObj && this.$store.state.tasks.length >= 5)?_c('div',{staticClass:"py-3"},[_c('v-btn',{staticClass:"d-flex mx-auto",attrs:{"elevation":"1"},on:{"click":function($event){(_vm.showAll = !_vm.showAll), _vm.goTo('#tasks')}}},[_vm._v(_vm._s(_vm.showAllText))])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }