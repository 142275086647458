<template>
  <div class="d-flex">
    <div class="pr-2">
      <BackToProposal />
    </div>
    <div class="service-edit">
      <ServicesEdit />
    </div>
    <div class="toolbar px-5">
      <ToolBar class="toolbar-stick" />
    </div>
  </div>
</template>

<script>
import BackToProposal from "@/components/ProposalDetailed/Actions/BackToProposal";
import ServicesEdit from "../components/ProposalDetailed/Services/ServicesEdit";
import ToolBar from "../components/ProposalDetailed/Services/ServicesToolbar";
export default {
  components: {
    BackToProposal,
    ServicesEdit,
    ToolBar,
  },
};
</script>

<style scoped>
.service-edit {
  flex: 10;
  min-width: 365px;
  max-width: 365px;
}
.toolbar {
  flex: 2;
  min-width: 700px;
}

.toolbar-stick {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  right: 1px;
}
</style>
