var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('div',[_c('v-card-title',{staticClass:"card-title-style",on:{"click":_vm.refreshData}},[_vm._v(" "+_vm._s(_vm.expansionHeader)+" "),_c('div',{staticClass:"ml-auto"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('AddComments',{attrs:{"refreshData":_vm.refreshData}})],1)]}}])},[_c('span',[_vm._v("Add comment")])])],1)]),(_vm.isEmptyObj)?_c('v-card-text',{staticClass:"mt-5 pl-5"},_vm._l((_vm.expansionPanelContent),function(ref,index){
var id = ref.id;
var message = ref.message;
var createdByUser = ref.createdByUser;
var creationDate = ref.creationDate;
return _c('div',{key:index},[_c('div',[_c('div',[_c('v-list',{attrs:{"two-line":""}},[[_c('v-list-item',{key:index,staticClass:"pa-0"},[_c('v-list-item-content',{staticClass:"py-0"},[_c('div',{staticClass:"d-flex flex-row"},[_c('div',{staticClass:"my-auto mx-3"},[_c('i',{staticClass:"fas fa-comment",staticStyle:{"transform":"scaleX(-1)"}})]),_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"item-title-font",domProps:{"innerHTML":_vm._s(message)}}),_c('div',{staticClass:"item-description-font py-1",domProps:{"innerHTML":_vm._s(createdByUser)}}),_c('div',{staticClass:"item-description-font",staticStyle:{"font-size":"10px"},domProps:{"innerHTML":_vm._s(_vm.filterDate(creationDate))}})])])]),_c('div',{staticClass:"ml-auto my-auto"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-btn',{staticClass:"color-red",attrs:{"icon":""},on:{"click":function($event){return _vm.deleteAlert(id)}}},[_c('v-icon',[_vm._v("mdi-delete-empty-outline")])],1)],1)]}}],null,true)},[_c('span',[_vm._v("Delete comment")])])],1)],1)]],2)],1)])])}),0):_c('v-card-text',{staticClass:"mt-5"},[_c('div',{staticClass:"px-4"},[_c('div',{staticClass:"red--text"},[_vm._v("No data found!")]),_c('div',{staticClass:"item-description-font"},[_vm._v("You can add comment.")])])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }