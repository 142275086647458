<template>
  <div>
    <v-checkbox
      v-if="getStatus"
      v-model="getStatus.isEnabled"
      @click.stop="getStatus.isEnabled ? getData() : disableService()"
    />

    <v-dialog persistent v-model="dialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span>Add</span>
        </v-card-title>
        <v-card-text>
          <v-row no-gutters>
            <v-col>
              <div v-if="type === 2">
                <v-form ref="form">
                  <v-textarea
                    v-model="relative"
                    label="Relative %"
                    :rules="[rules.required, rules.val]"
                    auto-grow
                    rows="1"
                    row-height="15"
                    outlined
                    dense
                  ></v-textarea>
                </v-form>
              </div>
              <div v-if="type === 1">
                <v-form ref="form">
                  <v-textarea
                    v-model="optimistic"
                    label="Optimistic hours"
                    :rules="[rules.required, rules.hours]"
                    auto-grow
                    rows="1"
                    row-height="15"
                    outlined
                    dense
                  ></v-textarea>
                  <v-textarea
                    v-model="pessimistic"
                    label="Pessimistic hours"
                    :rules="[rules.required, rules.hours]"
                    auto-grow
                    rows="1"
                    row-height="15"
                    outlined
                    dense
                  ></v-textarea>
                  <v-textarea
                    v-model="realistic"
                    label="Realistic hours"
                    :rules="[rules.required, rules.hours]"
                    auto-grow
                    rows="1"
                    row-height="15"
                    outlined
                    dense
                  ></v-textarea>
                </v-form>
              </div>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="refreshModelData(), clearAllData()" text>
            Close
          </v-btn>
          <v-btn color="primary" @click="addServiceParams()">
            Add
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axiosInstance from "@/axios";
import _ from "lodash";
export default {
  props: {
    id: Number,
    type: Number,
    refreshData: Function,
  },
  created() {
    // this.refreshModelData();
  },
  data: () => ({
    dialog: false,
    rules: {
      required: (v) => !!v || "this field is required",
      val: (v) =>
        (v >= 1 && v <= 100) ||
        "Relative can`t be greater than 100 or less than 1",
      hours: (v) => v >= 0 || "Hours shourd be positive",
    },
    relative: null,
    optimistic: null,
    pessimistic: null,
    realistic: null,
  }),
  computed: {
    getStatus() {
      return _(this.$store.state.simpleServices).find(
        (el) => el.id == this.$props.id
      );
    },
  },
  methods: {
    clearAllData() {
      this.$data.dialog = false;
      this.$refs.form.reset();
      this.$data.relative = null;
      this.$data.optimistic = null;
      this.$data.pessimistic = null;
      this.$data.realistic = null;
    },
    getData() {
      this.$data.dialog = true;
      if (this.$props.type === 1) {
        this.$data.optimistic = this.getStatus.optimisticEstimate;
        this.$data.pessimistic = this.getStatus.pesimisticEstimate;
        this.$data.realistic = this.getStatus.realisticEstimate;
      } else {
        this.$data.relative = this.getStatus.relativeEstimate;
      }
    },
    async refreshModelData() {
      await this.$store.dispatch("getSimpleServices", this.$route.params.id);
    },
    async disableService() {
      try {
        await axiosInstance.put(
          `/proposal/${this.$route.params.id}/Service/${this.$props.id}/disable`
        );
        this.$props.refreshData();
      } catch (err) {
        console.log(err);
      }
    },
    async addServiceParams() {
      let validation = this.$refs.form.validate();
      console.log(validation);
      if (validation) {
        var obj = {};
        if (this.$props.type === 1) {
          obj = {
            optimisticHours: Number(this.$data.optimistic),
            pesimisticHours: Number(this.$data.pessimistic),
            realisticHours: Number(this.$data.realistic),
          };
        } else {
          obj = {
            relativeEstimate: Number(this.$data.relative),
          };
        }

        try {
          await axiosInstance.put(
            `/proposal/${this.$route.params.id}/Service/${this.$props.id}/enable`,
            obj
          );
          this.$props.refreshData();
          this.clearAllData();
        } catch (err) {
          console.log(err);
        }
      }
    },
  },
};
</script>
