<template>
  <div>
    <div class="header px-5">
      <div class="d-flex">
        <a class="logo" href="/leads">
          <img src="../../assets/header/logo.svg" alt="logo" />
        </a>
        <div class="d-flex align-center ml-3 ">
          <div v-for="(element, i) in headerItems" class="pl-10 links" :key="i">
            <a
              :class="isInclude(element.link) ? 'active-link' : 'links'"
              :href="element.link"
            >
              {{ element.title }}</a
            >
          </div>
        </div>
      </div>
      <div class="search">
        <Search style="height: 40px" class="d-flex my-auto" />
      </div>
      <div class="account-details"><NotificationWithDropdown /><Account /></div>
    </div>
  </div>
</template>

<script>
import NotificationWithDropdown from "./NotificationWithDropdown";
import Search from "./Search";
import Account from "./Account";
import _ from "lodash";

export default {
  data: () => ({
    headerItems: [
      {
        title: "Leads",
        link: "/leads",
      },
      {
        title: "Proposals",
        link: "/proposals",
      },
    ],
    drawer: false,
    group: null,
  }),
  computed: {
    getUrl() {
      return this.$router.currentRoute.path;
    },
  },
  components: {
    Search,
    NotificationWithDropdown,
    Account,
  },
  watch: {
    group() {
      this.drawer = false;
    },
  },
  mounted() {
    window.addEventListener("keydown", this.keyDownHandler);
  },
  destroyed() {
    window.removeEventListener("keydown", this.keyDownHandler);
  },
  methods: {
    isInclude(link) {
      return _(this.getUrl).includes(link);
    },
    keyDownHandler(event) {
      if (event.code === "Escape") {
        this.$data.drawer = false;
      }
    },
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
}

.header {
  display: flex;
  flex-direction: row;
  height: 60px;
  padding: 4px 10px;
  background: #232535;
  -webkit-box-shadow: 0px 9px 11px -11px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0px 9px 11px -11px rgba(34, 60, 80, 0.2);
  box-shadow: 0px 9px 11px -11px rgba(34, 60, 80, 0.2);
}

.search {
  display: flex;
  margin-left: auto;
  margin-right: 20px;
  align-items: center;
}

.account-details {
  display: flex;
  align-items: center;
}

.logo {
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 100%;
}

.active-link {
  color: #5097f3;
  border-bottom: 4px solid #5097f3;
  padding-bottom: 16.5px;
}

.links {
  color: white;
}

/* .links:hover {
  opacity: 0.85;
} */
</style>
