<template>
  <div>
    <v-card
      elevation="2"
      v-for="(item, index) in expansionPanelContent"
      :key="index"
      class="mb-10"
    >
      <v-card-title class="card-title-style"
        >{{ item.name }}
        <div class="ml-auto d-flex flex-row">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <AddTemplate
                  :refreshData="refreshData"
                  :projectId="item.projectId"
                />
              </div>
            </template>
            <span>Add tasks from templates</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <AddTask
                  :projectId="item.projectId"
                  :refreshData="refreshData"
                  :projects="groupedByNameId"
                />
              </div>
            </template>
            <span>Add task</span>
          </v-tooltip>
        </div>
      </v-card-title>
      <v-card-text>
        <div>
          <draggable
            v-model="item.tasks"
            :animation="200"
            style="height: 100%;"
            ghost-class="moving-card"
            group="tasks"
            @change="onGroupsChange($event, item.projectId)"
          >
            <v-card
              elevation="2"
              class="pa-3 py-5 mt-5 black--text"
              v-for="({
                id,
                name,
                optimisticHours,
                pesimisticHours,
                realisticHours,
              },
              index) in item.tasks"
              :key="index"
            >
              <div class="d-flex flex-row">
                <div>
                  <div>
                    <v-card-title class="pb-0 pt-0">{{ name }}</v-card-title>
                    <div>
                      <v-card-text class="pt-0 pb-0">
                        <div class="d-flex flex-row">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <div
                                class="d-flex flex-row align-center"
                                v-bind="attrs"
                                v-on="on"
                              >
                                <v-icon>mdi-clock-time-three</v-icon>
                                <div class="d-flex flex-row">
                                  <div class="mx-1">
                                    {{ optimisticHours + "hrs" }}
                                  </div>
                                  <div class="mx-1">
                                    {{ pesimisticHours + "hrs" }}
                                  </div>
                                  <div class="mx-1">
                                    {{ realisticHours + "hrs" }}
                                  </div>
                                </div>
                              </div>
                            </template>
                            <span>Optimistic, pesimistic, realistic hours</span>
                          </v-tooltip>
                        </div>
                      </v-card-text>
                    </div>
                  </div>
                </div>
                <div class="ml-auto">
                  <CardMenu
                    :id="id"
                    :taskId="id"
                    :alternativeDelete="true"
                    :menuOptions="menuOptions"
                    :reloadData="refreshData"
                    :singleEntityName="singleEntityName"
                  />
                </div>
              </div>
            </v-card>
          </draggable>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import _ from "lodash";
import axiosInstance from "@/axios";
import CardMenu from "../../LeadDraggble/CardMenu";
import AddTask from "../Actions/AddTask";

import AddTemplate from "../Actions/AddTemplate";
export default {
  components: {
    CardMenu,
    AddTask,
    AddTemplate,
    draggable,
  },
  data: () => ({
    expansionHeader: [],
    expansionPanelContent: [],
    includesWords: ["optimisticHours", "pesimisticHours", "realisticHours"],
    groupedByNameId: [],
    singleEntityName: "Task",
    menuOptions: [
      { title: "Edit", icon: "fas fa-pen", edit: true },
      {
        title: "Make a copy",
        methodName: "copy",
        icon: "fas fa-copy",
        copy: false,
      },
      { title: "Delete", methodName: "deleteItem", icon: "fas fa-trash-alt" },
    ],
  }),
  async created() {
    this.refreshData();
  },
  methods: {
    async changeCoordinates(evt, name, projectId) {
      let obj = {
        projectId: projectId,
        projectIndex: evt[name].newIndex,
      };

      let id = evt[name].element.id;
      try {
        await axiosInstance.put(
          `/proposal/${this.$route.params.id}/Task/${id}/coordinates`,
          obj
        );
        this.refreshData();
        await this.$store.dispatch("getSummaryData", this.$route.params.id);
      } catch (e) {
        console.log(e.message);
      }
    },
    async onGroupsChange(evt, projectId) {
      if ("moved" in evt) {
        this.changeCoordinates(evt, "moved", projectId);
      }
      if ("added" in evt) {
        this.changeCoordinates(evt, "added", projectId);
      }
    },
    async refreshData() {
      try {
        const {
          data: { data: tasks },
        } = await axiosInstance.get(`/proposal/${this.$route.params.id}/Task`);

        const {
          data: { data: proj },
        } = await axiosInstance.get(
          `/proposal/${this.$route.params.id}/Project`
        );

        let res = proj.map(function(project) {
          let obj = {
            projectId: project.id,
            name: project.title,
            tasks: _(tasks)
              .filter((el) => el.projectId == project.id)
              .sortBy((el) => el.projectIndex)
              .value(),
          };
          return obj;
        });

        for (let key in proj) {
          let tmp = {
            id: proj[key].id,
            name: proj[key].title,
          };
          this.$data.groupedByNameId.push(tmp);
        }

        this.$data.expansionPanelContent = res;

        await this.$store.dispatch("getSummaryTask", this.$route.params.id);
        await this.$store.dispatch("getSummaryData", this.$route.params.id);
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>

<style scoped>
.block-subtitle::first-letter {
  text-transform: uppercase;
}

.moving-card {
  opacity: 0.5;
  background: #f4f5f7;
  border: 3px solid blue !important;
}
</style>
