<template>
  <div class="page-container">
    <div class="d-flex col-12 pa-0">
      <Label v-if="proposalType" :text="proposalType" :customClass="'default'"/>
      <Label
        v-if="proposalState === 'Archived'"
        :text="proposalState"
        :customStyles="labelStyles"
        :customClass="'archived'"
      />
    </div>
    <div class="general-info">
      <Title v-if="proposalComponentsList.includes('Proposal title')" />
      <Projects v-if="proposalComponentsList.includes('Projects')" class="margins-between-blocks" />
      <Tasks v-if="proposalComponentsList.includes('Tasks')" class="margins-between-blocks" />
      <Services v-if="proposalComponentsList.includes('Services')" class="margins-between-blocks" />
      <Team v-if="proposalComponentsList.includes('Team')" class="margins-between-blocks" />
      <Milestones v-if="proposalComponentsList.includes('Milestones')" class="margins-between-blocks" />
      <SoftwareQualityAttributes v-if="proposalComponentsList.includes('Software quality attributes')" class="margins-between-blocks" />
      <Architecture v-if="proposalComponentsList.includes('Solution architecture')" class="margins-between-blocks" />
      <SDLC v-if="proposalComponentsList.includes('SDLC')" class="margins-between-blocks" />
      <Risks v-if="proposalComponentsList.includes('Risks')" class="margins-between-blocks" />
      <Deliverables v-if="proposalComponentsList.includes('Deliverables')" class="margins-between-blocks" />
      <SupportMaintenance v-if="proposalComponentsList.includes('Support/Maintenance')" class="margins-between-blocks" />
      <PaymentTerms v-if="proposalComponentsList.includes('Payment terms')" class="margins-between-blocks" />
      <Acceptance v-if="proposalComponentsList.includes('Acceptance & Warranty')" class="margins-between-blocks" />
      <GeneralSummary v-if="proposalComponentsList.includes('General summary')" class="margins-between-blocks" />
      <Settings v-if="proposalComponentsList.includes('Settings')" class="margins-between-blocks" />
    </div>
    <div class="toolbar px-8">
      <Toolbar class="toolbar-stick" />
    </div>
  </div>
</template>

<script>
import Title from "../components/ProposalDetailed/Title.vue";
import Projects from "../components/ProposalDetailed/Projects.vue";
import SoftwareQualityAttributes from "../components/ProposalDetailed/SoftwareQualityAttributes.vue";
import GeneralSummary from "../components/ProposalDetailed/GeneralSummary.vue";
import Tasks from "../components/ProposalDetailed/Tasks/Tasks.vue";
import SDLC from "../components/ProposalDetailed/SDLC.vue";
import Deliverables from "../components/ProposalDetailed/Deliverables.vue";
import Acceptance from "../components/ProposalDetailed/Acceptance.vue";
import PaymentTerms from "../components/ProposalDetailed/PaymentTerms.vue";
import SupportMaintenance from "../components/ProposalDetailed/SupportMaintenance.vue";
import Milestones from "../components/ProposalDetailed/Milestones/Milestones";
import Team from "../components/ProposalDetailed/Team";
import Risks from "../components/ProposalDetailed/Risks";
import Services from "../components/ProposalDetailed/Services/Services";
import Settings from "../components/ProposalDetailed/Settings";
import Architecture from "../components/ProposalDetailed/Architecture";
import Toolbar from "../components/ProposalDetailed/Toolbar";
import Label from "../components/Common/Label";
import { proposalTypes, stateProperty } from "../../config";
import axiosInstance from "@/axios";

export default {
  components: {
    Title,
    Projects,
    Milestones,
    SoftwareQualityAttributes,
    GeneralSummary,
    Tasks,
    SDLC,
    Deliverables,
    Acceptance,
    PaymentTerms,
    SupportMaintenance,
    Toolbar,
    Team,
    Risks,
    Services,
    Settings,
    Architecture,
    Label,
  },
  data: () => ({
    proposalTypes,
    stateProperty,
    labelStyles: {
      marginLeft: '10px',
    },
    proposalComponentsList: [],
  }),

  async created() {
    window.scrollTo(0, 0);
    const { data: { data } } = await axiosInstance.get(`/proposal/${this.$route.params.id}/ui`);
    this.proposalComponentsList = data;
    await this.$store.dispatch("getCurrentProposalData", this.$route.params.id);
    if (this.proposalBasicData) {
      this.$store.dispatch("getSummaryData", this.$route.params.id);
    }
  },
  computed: {
    proposalBasicData() {
      return this.$store.state.singleValue;
    },
    proposalState() {
      const proposalState = stateProperty.find(item => item.id === this.$store.state.proposalState);
      return proposalState?.value;
    },
    proposalType() {
      const proposalType = proposalTypes.find(item => item.id === this.$store.state.proposalType);
      return proposalType?.value;
    },
  },
};
</script>
<style scoped>
.general-info {
  flex: 1;
}
.toolbar {
  width: 400px;
}
.toolbar-stick {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  right: 1px;
}
</style>
