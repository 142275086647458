<template>
  <div>
    <v-card elevation="2">
      <v-card-title id="tasks" class="card-title-style justify-space-between"
        >{{ expansionHeader }}
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <v-btn @click="$router.push(`${$route.params.id}/tasks`)" icon>
                <v-icon color="green">mdi-pencil-plus</v-icon>
              </v-btn>
            </div>
          </template>
          <span>Open task detailed page</span>
        </v-tooltip>
      </v-card-title>
      <div class=" black--text" v-if="isEmptyObj">
        <div
          v-for="({
            id,
            name,
            projectName,
            milestoneName,
            estimateHours,
            standardDeviation,
          },
          index) in expansionPanelContent"
          :key="index"
          :class="index % 2 === 0 ? '' : 'card-text-bg'"
        >
          <div class="d-flex flex-row px-3">
            <div class="pa-4">
              <div class="item-title-font">{{ name }}</div>
              <div class="d-flex align-center mr-1 mt-1">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      <div class="d-flex flex-row align-center mr-1">
                        <v-icon>mdi-folder</v-icon>
                        <div class="mx-1 item-description-font">
                          {{ projectName }}
                        </div>
                      </div>
                    </div>
                  </template>
                  <span>Project</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      <div class="d-flex flex-row align-center mr-1">
                        <v-icon>mdi-calendar-check</v-icon>
                        <div
                          v-if="milestoneName"
                          class="ml-1 item-description-font"
                        >
                          {{ milestoneName }}
                        </div>
                        <div v-else class="mx-1 item-description-font">
                          Unassigned
                        </div>
                      </div>
                    </div>
                  </template>
                  <span>Milestone</span>
                </v-tooltip>
                <div class="d-flex flex-row align-center mr-1">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on">
                        <div class="d-flex">
                          <v-icon>mdi-calendar-clock</v-icon>
                          <div class="mx-1 item-description-font">
                            {{ estimateHours + "hrs" }}
                          </div>
                        </div>
                      </div>
                    </template>
                    <span>Estimate hours</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on">
                        <div class="d-flex">
                          <v-icon>mdi-timelapse</v-icon>
                          <div class="mx-1 item-description-font">
                            {{ standardDeviation }}
                          </div>
                        </div>
                      </div>
                    </template>
                    <span>Standart deviation</span>
                  </v-tooltip>
                </div>
              </div>
            </div>
            <div class="ml-auto my-auto">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    <v-btn class="color-red" @click="deleteAlert(id)" icon>
                      <div>
                        <v-icon>mdi-delete-empty-outline</v-icon>
                      </div>
                    </v-btn>
                  </div>
                </template>
                <span>Delete task</span>
              </v-tooltip>
            </div>
          </div>
        </div>
      </div>
      <v-card-text class="black--text mt-5 pl-7" v-else>
        <div class="red--text">No data found!</div>
        <div class="item-description-font">
          Try to create project, and fill them with tasks on the
          <a :href="`/proposals/${$route.params.id}/tasks`">detail page</a>.
        </div>
      </v-card-text>
      <div
        v-if="isEmptyObj && this.$store.state.tasks.length >= 5"
        class="py-3"
      >
        <v-btn
          elevation="1"
          class="d-flex mx-auto"
          @click="(showAll = !showAll), goTo('#tasks')"
          >{{ showAllText }}</v-btn
        >
      </div>
    </v-card>
  </div>
</template>

<script>
import _ from "lodash";
import axiosInstance from "@/axios";
export default {
  data: () => ({
    expansionHeader: "Tasks",
    showAll: false,
  }),
  computed: {
    isEmptyObj: function() {
      if (this.expansionPanelContent) {
        let isEmpty = _.isEmpty(this.expansionPanelContent);
        return !isEmpty;
      }
      return null;
    },
    showAllText: function() {
      return this.$data.showAll ? "Close" : "Show all";
    },
    expansionPanelContent() {
      let res = this.$store.state.tasks;
      if (this.$data.showAll) return res;
      return res.slice(0, 5);
    },
  },
  methods: {
    goTo(target) {
      setTimeout(this.$vuetify.goTo(target, { offset: 0 }), 1000);
    },
    deleteAlert(taskId) {
      this.$swal({
        title: "Do you want to delete this task?",
        showDenyButton: true,
        confirmButtonText: `Yes`,
        denyButtonText: `No`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteTasks(taskId);
        } else if (result.isDenied) {
          return;
        }
      });
    },
    async deleteTasks(taskId) {
      let proposalId = this.$route.params.id;
      this.$data.isDataDeleted = true;
      try {
        await axiosInstance.delete(`/proposal/${proposalId}/Task/${taskId}`, {
          headers: {},
        });
        await this.$store.dispatch(
          "getCurrentProposalData",
          this.$route.params.id
        );
        await this.$store.dispatch("getSummaryTask", this.$route.params.id);
        await this.$store.dispatch("getSummaryData", this.$route.params.id);
        this.$store.dispatch("getCurrentProposalData", this.$route.params.id);

        this.$data.isDataDeleted = false;
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>

<style scoped></style>
