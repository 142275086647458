<template>
  <div>
    <v-card elevation="2">
      <v-card-title class="card-title-style justify-space-between"
        >{{ expansionHeader }}
        <div class="d-flex ml-auto">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-btn
                  @click="
                    $router.push(`/proposals/${$route.params.id}/services`)
                  "
                  icon
                >
                  <v-icon color="green">mdi-pencil-plus</v-icon>
                </v-btn>
              </div>
            </template>
            <span>Open services detail page</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-btn @click="autoFill()" icon>
                  <v-icon color="blue"> mdi-auto-download</v-icon>
                </v-btn>
              </div>
            </template>
            <span>Autofill services</span>
          </v-tooltip>
        </div>
      </v-card-title>
      <v-card-text class="px-0 pb-0 black--text" v-if="isEmptyObj">
        <div
          v-for="({
            title,
            serviceType,
            relativeEstimate,
            estimateType,
            optimisticEstimate,
            pesimisticEstimate,
            realisticEstimate,
            isFree,
          },
          index) in expansionPanelContent"
          :key="index"
          v-bind:class="{ 'card-text-bg': index % 2 !== 0 }"
          class="px-3 d-flex"
        >
          <div class="pa-4">
            <div class="item-title-font">{{ title }}</div>
            <div class="d-flex mt-1" v-if="estimateType === 2">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    <div class="mr-2 item-description-font">
                      {{ getService(serviceType) }}
                    </div>
                  </div>
                </template>
                <span>Service type</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on" class="item-description-font">
                    {{ relativeEstimate + "%" }}
                  </div>
                </template>
                <span>Relative estimate</span>
              </v-tooltip>
            </div>
            <div class="d-flex mt-1" v-else>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    <div class="mr-2 item-description-font">
                      {{ getService(serviceType) }}
                    </div>
                  </div>
                </template>
                <span>Service type</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    <div class="mr-2 item-description-font">
                      {{ optimisticEstimate + "hrs" }}
                    </div>
                  </div>
                </template>
                <span>Optimistic estimate</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    <div class="mr-2 item-description-font">
                      {{ pesimisticEstimate + "hrs" }}
                    </div>
                  </div>
                </template>
                <span>Pesimistic estimate</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    <div class="mr-2 item-description-font">
                      {{ realisticEstimate + "hrs" }}
                    </div>
                  </div>
                </template>
                <span>Realistic estimate</span>
              </v-tooltip>
            </div>
          </div>

          <div class="ml-auto my-auto">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <v-icon class="px-1" color="green" v-if="!isFree"
                    >mdi-currency-usd</v-icon
                  >
                </div>
              </template>
              <span>Paid</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <v-icon class="px-1" v-if="isFree">mdi-currency-usd</v-icon>
                </div>
              </template>
              <span>Free</span>
            </v-tooltip>
          </div>
        </div>
      </v-card-text>
      <v-card-text class="black--text mt-5 pl-7" v-else>
        <div class="red--text">No data found!</div>
        <div class="item-description-font">
          Try to enable services on the
          <a :href="`/proposals/${$route.params.id}/services`">detail page</a>,
          or use autofill.
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import _ from "lodash";
import axiosInstance from "@/axios";
export default {
  data: () => ({
    expansionHeader: "Services",
  }),
  async created() {
    await this.$store.dispatch("getSummaryData", this.$route.params.id);
    this.$store.dispatch("getCurrentProposalData", this.$route.params.id);
  },
  computed: {
    isEmptyObj: function() {
      if (this.expansionPanelContent) {
        let isEmpty = _.isEmpty(this.expansionPanelContent);
        return !isEmpty;
      }
      return null;
    },
    expansionPanelContent: function() {
      return _(this.$store.state.services)
        .filter((el) => el.isEnabled)
        .value();
    },
    lookups: function() {
      return this.$store.state.lookups;
    },
  },
  methods: {
    getService(service) {
      let serviceTypeLookup = _.find(this.lookups, function(o) {
        return o.lookupName.includes("ServiceType");
      });
      let currentService = serviceTypeLookup.values.find(
        (el) => el.key == service
      );
      if (currentService.value) {
        return currentService.value;
      }
    },
    async autoFill() {
      try {
        await axiosInstance.post(
          `/proposal/${this.$route.params.id}/Service/auto`,
          {}
        );
        await this.$store.dispatch("getSummaryData", this.$route.params.id);
        this.$store.dispatch("getCurrentProposalData", this.$route.params.id);
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>

<style scoped></style>
