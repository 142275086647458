<template>
  <div>
    <template>
      <v-dialog persistent v-model="dialog" max-width="600px">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click="refreshTasks()"
            v-bind="attrs"
            v-on="on"
            class="global-button-vuetify"
            icon
          >
            <v-icon color="green">mdi-plus</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span>Add tasks</span>
          </v-card-title>
          <v-card-text class="pb-0">
            <v-row no-gutters>
              <v-col>
                <v-combobox
                  v-model="select"
                  :items="items"
                  label="Select tasks"
                  item-text="name"
                  multiple
                  outlined
                  dense
                  return-object
                ></v-combobox>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="(dialog = false), (select = [])">
              Close
            </v-btn>
            <v-btn
              color="primary"
              @click="addTasks(), (select = []), (dialog = false)"
            >
              Add
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </div>
</template>

<script>
import axiosInstance from "@/axios";
//import { config } from "../../../../config.js";
import _ from "lodash";
export default {
  props: {
    id: Number,
  },
  data: () => ({
    select: [],
    dialog: false,
    items: [],
  }),
  async created() {
    this.refreshTasks();
  },
  methods: {
    async addTasks() {
      try {
        let obj = _.flatMap(this.$data.select, function(o) {
          return o.id;
        });
        await axiosInstance.post(
          `/proposal/${this.$route.params.id}/Milestone/tasks/${this.$props.id}`,
          obj,
          {
            headers: {},
          }
        );
        this.$store.dispatch("getMilestoneSummary", this.$route.params.id);
        this.$store.dispatch("refreshMilestones", this.$route.params.id);
        this.refreshTasks();
      } catch (e) {
        console.log(e);
      }
    },
    async refreshTasks() {
      try {
        const { data } = await axiosInstance.get(
          `/proposal/${this.$route.params.id}/Milestone/tasks`,
          {
            headers: {},
          }
        );

        if (data) {
          this.$data.items = data.data;
        }
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>
