import Vue from "vue";
import VueDraggable from "vue-draggable";
import VueRouter from "vue-router";
import VueSweetalert2 from "vue-sweetalert2";
import GAuth from "vue-google-oauth2";
import ls from "local-storage";

import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";
import { config } from "../config";
import axiosInstance from "./axios";

import "sweetalert2/dist/sweetalert2.min.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";

const userData = ls.get("userData");
if (userData) {
  axiosInstance.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${userData.token}`;
  store.state.userData = userData;
}

Vue.use(GAuth, config.gauthOptions);

Vue.use(VueRouter);
Vue.use(VueDraggable);
Vue.use(VueSweetalert2);

Vue.config.productionTip = false;

new Vue({
  vuetify,
  router,
  render: (h) => h(App),
}).$mount("#app");
