<template>
  <div class="d-flex justify-space-between">
    <v-dialog v-model="dialog" max-width="600px">
      <template v-slot:activator="{ on: dialogOn, attrs: dialogAttrs }">
        <v-tooltip style="z-index: 10;" bottom>
          <template v-slot:activator="{ on: tooltipOn, attrs: tooltipAttrs }">
            <div class="button-container mb-2">
              <div v-on="tooltipOn" v-bind="tooltipAttrs">
                <v-btn
                  fab
                  dark
                  small
                  color="green"
                  v-on="dialogOn"
                  v-bind="dialogAttrs"
                  @click="getLeads"
                >
                  <v-icon dark>
                    {{ "mdi-plus" }}
                  </v-icon>
                </v-btn>
              </div>
            </div>
          </template>
          <span>Add proposal</span>
        </v-tooltip>
      </template>
      <v-card>
        <v-card-title>
          <span class="headline">New proposal</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="form" class="mx-2" lazy-validation>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="Title"
                    v-model="proposalTitle"
                    :rules="rules"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-select
                    :items="proposalTypes"
                    item-text="value"
                    item-value="id"
                    v-model="selectedProposalType"
                    label="Type"
                    :rules="rules"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-select
                    :items="leads"
                    item-text="title"
                    item-value="id"
                    v-model="selectedLead"
                    label="Lead(Optional)"
                  ></v-select>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDialog">
            Cancel
          </v-btn>
          <v-btn color="primary" @click="addProposal">
            Add
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-radio-group
          v-model="itemsPerPage"
          @change="handleChange(itemsPerPage)"
          row
          ><div v-bind="attrs" v-on="on" class="d-flex">
            <v-radio
              v-for="(item, i) in itemsPerPageOptions"
              :key="i"
              :label="item.toString()"
              :value="item"
            ></v-radio>
          </div> </v-radio-group></template
      >Proposals per page</v-tooltip
    > -->
  </div>
</template>
<script>
import { proposalTypes } from "../../../config";
import axiosInstance from "@/axios";

export default {
  data: () => ({
    leads: [],
    proposalTypes,
    selectedProposalType: null,
    dialog: false,
    proposalTitle: "",
    selectedLead: null,
    rules: [(v) => !!v || "Field is required"],
    itemsPerPage: 10,
    itemsPerPageOptions: [5, 10, 15, 20, 30],
  }),
  methods: {
    async getLeads() {
      try {
        const {
          data: { data },
        } = await axiosInstance.get(`/lead?filter=1`, {
          headers: {},
        });
        this.$data.leads = data;
      } catch (e) {
        console.log(e.message);
      }
    },
    async addProposal() {
      if (this.$refs.form.validate()) {
        const params = {
          title: this.$data.proposalTitle,
          proposalType: this.$data.selectedProposalType,
          leadId: this.$data.selectedLead || null,
        };

        try {
          const {
            data: { data },
          } = await axiosInstance.post(`/proposal`, params);
          this.closeDialog();
          this.$store.commit("setPageNumber", 1);
          await this.$store.dispatch("getSummaryData", data.id);
          await this.$store.dispatch("getProposalsList");
        } catch (e) {
          console.log(e.message);
        }
      }
    },
    closeDialog() {
      this.$data.dialog = false;
      this.$refs.form.reset();
    },
    async handleChange(selected) {
      this.$store.commit("setPageSize", selected);
      this.$store.commit("setPageNumber", 1);
      this.$store.commit("setLoadMoreButtonState", true);
      await this.$store.dispatch("getProposalsList", this.newProposalId);
    },
  },
};
</script>
<style scoped>
.button-container {
  display: flex;
  align-items: center;
}
.action-name {
  margin-left: 5px;
}
</style>
