var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.isEdit)?_c('div',{staticClass:"title-block"},[_c('div',{staticClass:"proposal-title"},[_vm._v(_vm._s(_vm.title))]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"green","icon":""},on:{"click":_vm.enableEditing}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil-plus")])],1)]}}],null,false,3571576327)},[_vm._v(" Edit proposal title ")])],1):_vm._e(),_c('div',{staticClass:"editing-block"},[(_vm.isEdit)?_c('v-textarea',{attrs:{"label":"Enter title","auto-grow":"","outlined":"","rows":"1","row-height":"15","rules":_vm.titleRules},model:{value:(_vm.inputData),callback:function ($$v) {_vm.inputData=$$v},expression:"inputData"}}):_vm._e(),(_vm.isEdit)?_c('div',{staticClass:"edit-buttons"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"green"},on:{"click":_vm.editTitle}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check")])],1)]}}],null,false,1158792160)},[_vm._v(" Apply changes ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"green"},on:{"click":_vm.cancelEditing}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-close")])],1)]}}],null,false,3930895646)},[_vm._v(" Cancel changes ")])],1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }