var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.preventRouting}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-list',_vm._l((_vm.menuOptions),function(ref,i){
var title = ref.title;
var methodName = ref.methodName;
var icon = ref.icon;
return _c('v-list-item',{key:i,staticClass:"menu-item px-0",on:{"click":function($event){return _vm.handleFunctionCall(methodName, _vm.id)}}},[(icon.includes('mdi') || icon.includes('fas'))?_c('div',{staticClass:"d-flex align-center option",staticStyle:{"width":"100%"}},[_c('v-icon',{staticClass:"icon-style mr-2"},[_vm._v(_vm._s(icon))]),_c('div',[_vm._v(_vm._s(title))])],1):_vm._e()])}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }