var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._l((_vm.proposals),function(ref,i){
    var id = ref.id;
    var title = ref.title;
    var industry = ref.industry;
    var completness = ref.completness;
    var technologies = ref.technologies;
    var leadId = ref.leadId;
    var leadTitle = ref.leadTitle;
    var proposalType = ref.proposalType;
return _c('div',{key:i,staticClass:"proposal-card"},[_c('router-link',{style:({ textDecoration: 'none' }),attrs:{"to":("/" + _vm.entitiesName + "/" + id)}},[_c('div',{staticClass:"proposal-container",attrs:{"elevation":"2"}},[(_vm.proposalTypeTitle(proposalType))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"d-flex"},'div',attrs,false),on),[_c('Label',{attrs:{"text":_vm.proposalTypeTitle(proposalType),"customClass":'default'}})],1)]}}],null,true)},[_vm._v("Proposal type")]):_vm._e(),_c('div',{staticClass:"d-flex justify-space-between"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"proposal-name"},'div',attrs,false),on),[_vm._v(" "+_vm._s(title)+" ")])]}}],null,true)},[_vm._v("Title")]),_c('div',{staticClass:"d-flex justify-end"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-progress-circular',_vm._g(_vm._b({attrs:{"rotate":-90,"size":45,"width":5,"value":Math.ceil(completness),"color":"#92278e"}},'v-progress-circular',attrs,false),on),[_vm._v(" "+_vm._s(completness)+" ")])]}}],null,true)},[_vm._v("Completness")]),_c('CardMenu',{attrs:{"id":id,"title":title,"proposalTitle":title,"leadTitle":leadTitle,"leadId":leadId,"entitiesName":_vm.entitiesName,"singleEntityName":_vm.singleEntityName,"reloadData":_vm.reloadData,"menuOptions":_vm.menuOptions,"isCopyProposal":true,"isLeadSelectionEnabled":true}})],1)],1),(leadTitle)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [(leadTitle)?_c('v-btn',_vm._g(_vm._b({staticClass:"lead-button",attrs:{"small":"","text":"","color":"primary"}},'v-btn',attrs,false),on),[_c('router-link',{staticClass:"lead-title",attrs:{"to":("/leads/" + leadId)}},[_vm._v(" "+_vm._s(leadTitle))])],1):_vm._e()]}}],null,true)},[_vm._v("Assigned lead")]):_c('div',{staticClass:"no-data"},[_vm._v("No assigned lead")]),(industry)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"industry"},'div',attrs,false),on),[_vm._v(" "+_vm._s(industry)+" ")])]}}],null,true)},[_vm._v("Industry")]):_c('div',{staticClass:"no-data"},[_vm._v("No selected industry")]),(technologies.length)?_c('div',{staticClass:"technologies"},_vm._l((technologies),function(ref,j){
                var technologyName = ref.technologyName;
                var description = ref.description;
return _c('v-chip',{key:j,staticClass:"current-technology",attrs:{"small":"","color":"rgb(234 237 245)","label":""}},[(description)?_c('v-tooltip',{attrs:{"bottom":"","max-width":"300px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_vm._v(" "+_vm._s(technologyName)+" ")])]}}],null,true)},[_vm._v(_vm._s(description))]):_vm._e()],1)}),1):_c('div',{staticClass:"no-data"},[_vm._v("No selected technologies")])],1)])],1)}),_c('div',{staticClass:"d-flex justify-center"},[(_vm.isLoadMoreActive)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.loadMoreCards}},[_vm._v(" Load more ")]):_vm._e()],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }