<template>
  <div>
    <button v-on:click="scrollToTop()" id="myBtn" title="Go to top">
      <div class="icon"><i class="icon fa fa-chevron-up fa-2x "></i></div>
    </button>
  </div>
</template>

<script>
export default {
  data: () => ({ visible: false }),
  methods: {
    scrollToTop: function() {
      document.documentElement.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    scrollListener: function() {
      let mybutton = document.getElementById("myBtn");
      if (
        document.body.scrollTop > 400 ||
        document.documentElement.scrollTop > 400
      ) {
        mybutton.style.cssText = "transform: scale(1);";
      } else {
        mybutton.style.cssText = "transform: scale(0);";
      }
    },
  },
  created: function() {
    window.addEventListener("scroll", this.scrollListener);
  },
  beforeDestroy: function() {
    window.removeEventListener("scroll", this.scrollListener);
  },
};
</script>

<style lang="scss" scoped>
#myBtn {
  position: fixed;
  bottom: 30px;
  right: 15px;
  z-index: 99;
  border: none;
  outline: none;
  background-color: rgba(0, 0, 0, 0.87);
  color: white;
  cursor: pointer;
  padding: 10px;
  border-radius: 50%;
  font-size: 12px;
  transform: scale(0);
  transition: all 0.5s ease-in-out;
}

#myBtn:hover {
  background-color: #92278e;
}
#myBtn:hover .icon {
  animation: shake 0.7s;
  animation-iteration-count: infinite;
  transition: all 1s ease-in-out;
}
@keyframes shake {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(0px, 1px);
  }
  100% {
    transform: translate(0px, -1px);
  }
}
.icon {
  width: 25px;
  height: 25px;
}
</style>
