<template>
  <div class="full-block">
    <div class="not-exist">404</div>
    <div class="went-wrong-message">Nothing was found :(</div>
    <div class="error-message">{{ message }}</div>
    <div @click="clearErrorMessage">
      <router-link to="/leads" class="link-message"
        >return to main page</router-link
      >
    </div>
  </div>
</template>
<script>
export default {
  computed: {
    message() {
      return this.$store.state.error404Message;
    },
  },
  methods: {
    clearErrorMessage() {
      this.$store.commit("handleError404", "");
    },
    handleWrongPath() {
      if (!this.message) {
        this.$store.commit(
          "handleError404",
          "It seems you're in the wrong page"
        );
      }
    },
  },
  created() {
    this.handleWrongPath();
  },
};
</script>
<style scoped>
.full-block {
  padding: 15% 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.not-exist {
  font-size: 72px;
  font-weight: 900;
}
.went-wrong-message {
  font-size: 28px;
}
.error-message {
  font-size: 36px;
  font-weight: 600;
  padding: 30px 0;
}
.link-message {
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
  color: #92278e;
  text-transform: uppercase;
}
.link-message:hover {
  opacity: 0.7;
}
</style>
