<template>
  <v-card>
    <div>
      <v-card-title class="card-title-style" @click="refreshData">
        {{ expansionHeader }}

        <div class="ml-auto">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <AddComments :refreshData="refreshData" />
              </div>
            </template>
            <span>Add comment</span>
          </v-tooltip>
        </div>
      </v-card-title>
      <v-card-text class="mt-5 pl-5" v-if="isEmptyObj">
        <div
          v-for="({ id, message, createdByUser, creationDate },
          index) in expansionPanelContent"
          :key="index"
        >
          <div>
            <div>
              <v-list two-line>
                <template>
                  <v-list-item class="pa-0" :key="index">
                    <v-list-item-content class="py-0">
                      <div class="d-flex flex-row">
                        <div class="my-auto mx-3">
                          <i
                            style="transform: scaleX(-1);"
                            class="fas fa-comment"
                          ></i>
                        </div>
                        <div class="d-flex flex-column">
                          <div class="item-title-font" v-html="message"></div>
                          <div
                            class="item-description-font py-1"
                            v-html="createdByUser"
                          ></div>
                          <div
                            class="item-description-font"
                            style="font-size: 10px"
                            v-html="filterDate(creationDate)"
                          ></div>
                        </div>
                      </div>
                    </v-list-item-content>
                    <div class="ml-auto my-auto">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <div v-bind="attrs" v-on="on">
                            <v-btn
                              class="color-red"
                              @click="deleteAlert(id)"
                              icon
                            >
                              <v-icon>mdi-delete-empty-outline</v-icon>
                            </v-btn>
                          </div>
                        </template>
                        <span>Delete comment</span>
                      </v-tooltip>
                    </div>
                  </v-list-item>
                </template>
              </v-list>
            </div>
          </div>
        </div>
      </v-card-text>
      <v-card-text class="mt-5" v-else>
        <div class="px-4">
          <div class="red--text">No data found!</div>
          <div class="item-description-font">You can add comment.</div>
        </div>
      </v-card-text>
    </div>
  </v-card>
</template>

<script>
import axiosInstance from "@/axios";
//import { config } from "../../../config.js";
import AddComments from "./Actions/AddComments";
import _ from "lodash";
export default {
  components: {
    AddComments,
  },
  async mounted() {
    this.refreshData();
  },
  computed: {
    isEmptyObj: function() {
      if (this.$data.expansionPanelContent) {
        let isEmpty = _.isEmpty(this.$data.expansionPanelContent);
        return !isEmpty;
      }
      return null;
    },
  },
  methods: {
    filterDate(creationDate) {
      let newDate = new Date(creationDate.split(".").shift() + "Z");

      let date = newDate.toLocaleString().split(",");
      let time = date.pop();

      let result = date.shift() + " | " + time;
      return result;
    },
    async refreshData() {
      try {
        const { data } = await axiosInstance.get(
          `/lead/${this.$route.params.id}/Comments`
        );

        if (data) {
          this.$data.expansionPanelContent = data.data;
        }
      } catch (err) {
        console.log(err);
      }
    },
    deleteAlert(commnetId) {
      this.$swal({
        title: "Do you want to delete this comment?",
        showDenyButton: true,
        confirmButtonText: `Yes`,
        denyButtonText: `No`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteComments(commnetId);
        } else if (result.isDenied) {
          return;
        }
      });
    },
    async deleteComments(commnetId) {
      try {
        await axiosInstance.delete(
          `/lead/${this.$route.params.id}/Comments/${commnetId}`
        );
        this.refreshData();
      } catch (err) {
        console.log(err);
      }
    },
  },
  data: () => ({
    expansionHeader: "Comments",
    expansionPanelContent: null,
  }),
};
</script>
