<template>
  <v-card>
    <div>
      <v-card-title class="card-title-style" @click="refreshData">
        {{ expansionHeader }}
        <div class="ml-auto">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <AddProposal :refreshData="refreshData" />
              </div>
            </template>
            <span>Add proposal</span>
          </v-tooltip>
        </div>
      </v-card-title>
      <v-card-text class="mt-3" v-if="isEmptyObj">
        <div
          v-for="({
            id,
            title,
            createdByUser,
            completness,
            technologies,
            leadTitle,
            leadId,
            proposalType,
          },
          index) in expansionPanelContent"
          :key="index"
        >
          <div class="d-flex flex-row">
            <div class="flex-grow-1">
              <v-card elevation="0">
                <v-col cols="3">
                  <v-divider v-if="index"></v-divider>
                </v-col>
                <div v-if="proposalType" class="d-flex mx-4 mb-2">
                  <Label 
                    :text="proposalTypeTitle(proposalType)" 
                    :customStyles="labelStyles"
                    :customClass="'default'"
                  />
                </div>
                <div class="d-flex align-center pt-0 mx-4 item-title-font">
                  <a class="black--text" :href="`/proposals/${id}`">{{
                    title
                  }}</a>
                </div>
                <div class="item-description-font px-4 pt-1">
                  {{ createdByUser }}
                </div>
                <div class="px-4 py-2" v-if="technologies.length">
                  <v-chip
                    small
                    v-for="({ technologyName, description }, i) in technologies"
                    :key="i"
                    class="mr-2"
                    color="rgb(234 237 245)"
                  >
                    <v-tooltip v-if="description" max-width="300px" bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on">
                          {{ technologyName }}
                        </div>
                      </template>
                      <span>{{ description }}</span>
                    </v-tooltip>
                  </v-chip>
                </div>
              </v-card>
            </div>
            <div class="ml-auto d-flex flex-row  align-center">
              <div>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      <v-progress-circular
                        :rotate="360"
                        :size="35"
                        :width="5"
                        :value="completness"
                        color="#92278e"
                      >
                        {{ completness }}
                      </v-progress-circular>
                    </div>
                  </template>
                  <span>Сompletness</span>
                </v-tooltip>
              </div>
              <CardMenu
                :id="id"
                :proposalTitle="title"
                :leadTitle="leadTitle"
                :leadId="leadId"
                :singleEntityName="singleEntityName"
                :menuOptions="menuOptions"
                :reloadData="refreshData"
                :isLeadSelectionEnabled="false"
              />
            </div>
          </div>
        </div>
      </v-card-text>
      <v-card-text class="mt-5" v-else>
        <div class="px-4">
          <div style="color: red;">No data found!</div>
          <div class="item-description-font">You can add proposals.</div>
        </div>
      </v-card-text>
    </div>
  </v-card>
</template>

<script>
import CardMenu from "../LeadDraggble/CardMenu";
import Label from "../Common/Label.vue"
import axiosInstance from "@/axios";
//import { config } from "../../../config.js";
import AddProposal from "./Actions/AddProposal";
import { proposalTypes } from "../../../config";
import _ from "lodash";
export default {
  components: {
    AddProposal,
    CardMenu,
    Label,
  },
  data: () => ({
    singleEntityName: "proposal",
    expansionHeader: "Proposals",
    expansionPanelContent: null,
    proposalTypes,
    menuOptions: [
      { title: "Make a copy", icon: "fas fa-copy", copy: true },
      { title: "Delete", methodName: "deleteItem", icon: "fas fa-trash-alt" },
    ],
    labelStyles: {
      fontSize: '15px',
      borderWidth: '3px',
      padding: '0 6px',
    },
  }),
  async mounted() {
    this.refreshData();
  },
  computed: {
    isEmptyObj: function() {
      if (this.$data.expansionPanelContent) {
        let isEmpty = _.isEmpty(this.$data.expansionPanelContent);
        return !isEmpty;
      }
      return null;
    },
  },
  methods: {
    async refreshData() {
      try {
        const { data } = await axiosInstance.get(
          `/Proposal/lead/${this.$route.params.id}`
        );

        if (data) {
          this.$data.expansionPanelContent = data.data;
        }
      } catch (err) {
        console.log(err);
      }
    },
    deleteAlert(proposalId) {
      this.$swal({
        title: "Do you want to delete this proposal?",
        showDenyButton: true,
        confirmButtonText: `Yes`,
        denyButtonText: `No`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteProposals(proposalId);
        } else if (result.isDenied) {
          return;
        }
      });
    },
    async deleteProposals(proposalId) {
      try {
        await axiosInstance.delete(`/Proposal/${proposalId}`);
        this.refreshData();
      } catch (err) {
        console.log(err);
      }
    },
    proposalTypeTitle(type) {
      const proposalType = proposalTypes.find(item => item.id === type);
      return proposalType?.value;
    },
  },
};
</script>

<style>
.details {
  font-size: 15px;
  color: rgba(0, 0, 0, 0.8);
}

.sub-card-title {
  display: list-item;
  list-style-type: square;
  list-style-position: inside;
}
</style>
