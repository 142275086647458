var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"elevation":"2"}},[_c('v-card-title',{staticClass:"card-title-style justify-space-between"},[_vm._v(_vm._s(_vm.expansionHeader)+" "),_c('div',{staticClass:"d-flex ml-auto"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$router.push(("/proposals/" + (_vm.$route.params.id) + "/services"))}}},[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-pencil-plus")])],1)],1)]}}])},[_c('span',[_vm._v("Open services detail page")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.autoFill()}}},[_c('v-icon',{attrs:{"color":"blue"}},[_vm._v(" mdi-auto-download")])],1)],1)]}}])},[_c('span',[_vm._v("Autofill services")])])],1)]),(_vm.isEmptyObj)?_c('v-card-text',{staticClass:"px-0 pb-0 black--text"},_vm._l((_vm.expansionPanelContent),function(ref,index){
        var title = ref.title;
        var serviceType = ref.serviceType;
        var relativeEstimate = ref.relativeEstimate;
        var estimateType = ref.estimateType;
        var optimisticEstimate = ref.optimisticEstimate;
        var pesimisticEstimate = ref.pesimisticEstimate;
        var realisticEstimate = ref.realisticEstimate;
        var isFree = ref.isFree;
return _c('div',{key:index,staticClass:"px-3 d-flex",class:{ 'card-text-bg': index % 2 !== 0 }},[_c('div',{staticClass:"pa-4"},[_c('div',{staticClass:"item-title-font"},[_vm._v(_vm._s(title))]),(estimateType === 2)?_c('div',{staticClass:"d-flex mt-1"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('div',{staticClass:"mr-2 item-description-font"},[_vm._v(" "+_vm._s(_vm.getService(serviceType))+" ")])])]}}],null,true)},[_c('span',[_vm._v("Service type")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"item-description-font"},'div',attrs,false),on),[_vm._v(" "+_vm._s(relativeEstimate + "%")+" ")])]}}],null,true)},[_c('span',[_vm._v("Relative estimate")])])],1):_c('div',{staticClass:"d-flex mt-1"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('div',{staticClass:"mr-2 item-description-font"},[_vm._v(" "+_vm._s(_vm.getService(serviceType))+" ")])])]}}],null,true)},[_c('span',[_vm._v("Service type")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('div',{staticClass:"mr-2 item-description-font"},[_vm._v(" "+_vm._s(optimisticEstimate + "hrs")+" ")])])]}}],null,true)},[_c('span',[_vm._v("Optimistic estimate")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('div',{staticClass:"mr-2 item-description-font"},[_vm._v(" "+_vm._s(pesimisticEstimate + "hrs")+" ")])])]}}],null,true)},[_c('span',[_vm._v("Pesimistic estimate")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('div',{staticClass:"mr-2 item-description-font"},[_vm._v(" "+_vm._s(realisticEstimate + "hrs")+" ")])])]}}],null,true)},[_c('span',[_vm._v("Realistic estimate")])])],1)]),_c('div',{staticClass:"ml-auto my-auto"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[(!isFree)?_c('v-icon',{staticClass:"px-1",attrs:{"color":"green"}},[_vm._v("mdi-currency-usd")]):_vm._e()],1)]}}],null,true)},[_c('span',[_vm._v("Paid")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[(isFree)?_c('v-icon',{staticClass:"px-1"},[_vm._v("mdi-currency-usd")]):_vm._e()],1)]}}],null,true)},[_c('span',[_vm._v("Free")])])],1)])}),0):_c('v-card-text',{staticClass:"black--text mt-5 pl-7"},[_c('div',{staticClass:"red--text"},[_vm._v("No data found!")]),_c('div',{staticClass:"item-description-font"},[_vm._v(" Try to enable services on the "),_c('a',{attrs:{"href":("/proposals/" + (_vm.$route.params.id) + "/services")}},[_vm._v("detail page")]),_vm._v(", or use autofill. ")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }