<template>
  <div>
    <div class="mx-3">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            dark
            small
            color="light-blue"
            @click="autoGenerate()"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon dark>mdi-auto-download</v-icon>
          </v-btn>
        </template>
        Automatically fill architectures from template</v-tooltip
      >
    </div>
    <v-snackbar
      v-model="errorPopup.status"
      :timeout="errorPopup.timeout"
      bottom
    >
      {{ errorPopup.message }}

      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="closePopup()">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
export default {
  methods: {
    async autoGenerate() {
      await this.$store.dispatch(
        "autoGenerateArchitectures",
        this.$route.params.id
      );
      await this.$store.dispatch("getSummaryData", this.$route.params.id);
    },
    closePopup() {
      this.$store.commit("handleNoDirection", {
        status: false,
        message: "",
        timeout: null,
      });
    },
  },
  computed: {
    errorPopup() {
      return this.$store.state.architecturesList.errorPopup;
    },
  },
};
</script>
