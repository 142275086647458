<template>
  <div>
    <v-combobox
      v-model="searchedElem"
      append-icon=""
      prepend-inner-icon="mdi-magnify"
      style="z-index: 1"
      :items="result"
      :loading="isLoading"
      item-text="name"
      color="#FFFFFF"
      label="Search"
      :search-input.sync="text"
      @keyup.enter="redirectOnClickEnter(searchedElem)"
      background-color="blue-grey darken-1"
      no-filter
      solo
      dense
      filled
      clearable
      id="autocomplete"
    >
      <template v-slot:item="{ item }">
        <template>
          <v-list-item-content
            class="dropdown-items"
            @click="redirectOnClickEnter(item)"
          >
            <div class="d-flex flex-row align-center justify-center">
              <div class="mr-3">
                <v-list-item-title v-if="item.category.includes('LEADS')">
                  <v-icon small>mdi-account</v-icon>
                </v-list-item-title>
                <v-list-item-title v-else>
                  <v-icon small>mdi-file-document</v-icon>
                </v-list-item-title>
              </div>
              <v-list-item-title v-html="item.name"></v-list-item-title>
            </div>
          </v-list-item-content>
        </template>
      </template>
    </v-combobox>
  </div>
</template>

<script>
//import { config } from "../../../config.js";
import axiosInstance from "@/axios";
export default {
  data: () => ({
    text: "",
    result: [],
    searchedElem: "",
    isLoading: false,
  }),
  watch: {
    searchedElem: function() {
      if (!this.searchedElem) {
        this.$data.result = [];
      }
    },
    text: async function() {
      try {
        if (!this.text) {
          return;
        }
        this.$data.isLoading = true;
        const { data } = await axiosInstance.get(
          `/search?request=${this.text}`
        );

        let combinedResult = [];

        let leads = data.data.filter((el) => el.category === "LEADS");
        if (leads.length) {
          combinedResult = [{ header: "Leads" }, ...leads];
        }

        let proposals = data.data.filter((el) => el.category === "PROPOSALS");
        if (proposals.length) {
          combinedResult.push({ header: "Proposals" }, ...proposals);
        }

        this.$data.result = combinedResult;
        this.$data.isLoading = false;
      } catch (err) {
        console.log(err);
      }
    },
  },
  methods: {
    redirectTemplate(type, elem) {
      this.$data.searchedElem = "";
      this.$router.replace(`/${type}/${elem.id}`);
      this.$router.go(0);
    },
    redirectOnClickEnter(elem) {
      if (elem.category.includes("LEADS")) {
        this.redirectTemplate("leads", elem);
      } else {
        this.redirectTemplate("proposals", elem);
      }
    },
  },
};
</script>

<style scoped>
::v-deep .v-select.v-select--is-menu-active .v-input__icon--append .v-icon {
  transform: none;
}
::v-deep .theme--light.v-input input,
.theme--light.v-input textarea {
  color: #ffffff;
}
.dropdown-items {
  max-width: 232px;
}
</style>

<style>
#autocomplete {
  max-width: 232px;
}
</style>
