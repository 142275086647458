<template>
  <v-card>
    <div>
      <v-card-title class="card-title-style" @click="refreshData">
        {{ expansionHeader }}

        <div class="ml-auto d-flex flex-row">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <AddQuestion :type="type" :refreshQuestions="refreshData" />
              </div>
            </template>
            <span>Add requirements question</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-btn color="blue" @click="autoFillQuestions" icon>
                  <v-icon color="blue">mdi-auto-download</v-icon>
                </v-btn>
              </div>
            </template>
            <span>Autofill questions</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-dialog persistent v-model="dialog" max-width="830px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      color="#92278e"
                      class="global-button-vuetify"
                      icon
                      @click="generateGoogleDoc"
                    >
                      <v-icon>
                        mdi-cloud-download
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-card>
                    <div v-if="isBuilding">
                      <div class="d-flex align-center justify-center">
                        <v-card-title
                          >Please wait for building requirements
                          questions</v-card-title
                        >
                        <v-progress-circular
                          :size="30"
                          color="primary"
                          indeterminate
                        ></v-progress-circular>
                      </div>
                      <v-card-text class="d-flex justify-center"
                        >it may take up to 60 seconds</v-card-text
                      >
                    </div>

                    <div v-if="!isBuilding && !errorOnBuild">
                      <div class="d-flex align-center justify-center">
                        <v-card-title
                          >Requirements questions built</v-card-title
                        >
                        <v-icon :size="30" color="green">mdi-check</v-icon>
                      </div>
                      <v-card-text class="d-flex justify-center pb-1 pt-1 px-4">
                        <div class="link-box">
                          <a
                            :href="`${googleDriveLink}${documentId}`"
                            target="_blank"
                          >
                            <div>
                              {{ `${googleDriveLink}${documentId}` }}
                            </div>
                          </a>
                        </div>

                        <v-tooltip right>
                          <template v-slot:activator="{ on, attrs }">
                            <div>
                              <v-btn
                                color="rgba(224, 227, 235, 0.3)"
                                v-bind="attrs"
                                elevation="0"
                                v-on="on"
                                class="px-2 ml-2 copy-button"
                                @click="copyDocumentLink"
                                @mouseleave="copyText = 'Copy'"
                              >
                                <v-icon color="rgb(66, 82, 110)"
                                  >mdi-content-copy</v-icon
                                >
                              </v-btn>
                            </div>
                          </template>
                          <span>{{ copyText }}</span>
                        </v-tooltip>
                      </v-card-text>
                      <v-card-actions class="px-4">
                        <v-spacer></v-spacer>
                        <v-btn
                          color="primary"
                          min-width="0px"
                          class="px-0"
                          text
                          @click="dialog = false"
                        >
                          Close
                        </v-btn>
                      </v-card-actions>
                    </div>

                    <div v-if="!isBuilding && errorOnBuild">
                      <div class="d-flex align-center justify-center">
                        <v-card-title
                          >Something went wrong during building :(</v-card-title
                        >
                      </div>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="dialog = false"
                        >
                          Close
                        </v-btn>
                      </v-card-actions>
                    </div>
                  </v-card>
                </v-dialog>
              </div>
            </template>
            <span>Generate google doc</span>
          </v-tooltip>
        </div>
      </v-card-title>
      <v-card-text class="mt-5" v-if="isEmptyObj">
        <div
          v-for="({ id, text, answer }, index) in expansionPanelContent"
          :key="index"
        >
          <div class="d-flex flex-row ">
            <v-list two-line>
              <template>
                <v-divider v-if="index" :key="id"></v-divider>
                <v-list-item :key="index">
                  <v-list-item-content class="d-flex flex-row">
                    <h3 class="mb-3 item-title-font" v-html="'Q: ' + text"></h3>
                    <div
                      class="item-description-font"
                      v-html="answer ? 'A: ' + answer : 'A: '"
                    ></div>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list>
            <div class="d-flex flex-row ml-auto my-auto">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    <AddEditQuestionSections
                      :refreshData="refreshData"
                      :question="text"
                      :questionId="id"
                      :answer="answer"
                      :type="type"
                    />
                  </div>
                </template>
                <span>Edit question and answer</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    <v-btn class="color-red" @click="deleteAlert(id)" icon>
                      <v-icon>mdi-delete-empty-outline</v-icon>
                    </v-btn>
                  </div>
                </template>
                <span>Delete question</span>
              </v-tooltip>
            </div>
          </div>
        </div>
      </v-card-text>
      <v-card-text class="mt-5" v-else
        ><div class="px-4">
          <div style="color: red;">No data found!</div>
          <div class="item-description-font">You can add or set auto fill.</div>
        </div>
      </v-card-text>
    </div>
  </v-card>
</template>

<script>
import axiosInstance from "@/axios";
//import { config } from "../../../config.js";
import AddQuestion from "../LeadDetailed/Actions/AddQuestion";
import AddEditQuestionSections from "./Actions/AddEditQuestionSections";
import _ from "lodash";

export default {
  computed: {
    isEmptyObj: function() {
      if (this.$data.expansionPanelContent) {
        let isEmpty = _.isEmpty(this.$data.expansionPanelContent);
        return !isEmpty;
      }
      return null;
    },
  },
  data: () => ({
    expansionHeader: "Requirements questions",
    expansionPanelContent: null,
    type: 2,
    dialog: false,
    isBuilding: false,
    errorOnBuild: false,
    googleDriveLink: "https://docs.google.com/document/d/",
    documentId: "",
    copyText: "Copy",
  }),
  components: {
    AddQuestion,
    AddEditQuestionSections,
  },
  async mounted() {
    this.refreshData();
  },
  methods: {
    async refreshData() {
      try {
        const { data } = await axiosInstance.get(
          `/lead/${this.$route.params.id}/Question?type=${this.$data.type}`
        );

        if (data) {
          this.$data.expansionPanelContent = _.sortBy(data.data, (i) => i.id);
        }
      } catch (err) {
        console.log(err);
      }
    },

    deleteAlert(questionId) {
      this.$swal({
        title: "Do you want to delete question?",
        showDenyButton: true,
        confirmButtonText: `Yes`,
        denyButtonText: `No`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteQuestion(questionId);
        } else if (result.isDenied) {
          return;
        }
      });
    },

    async deleteQuestion(questionId) {
      try {
        await axiosInstance.delete(
          `/lead/${this.$route.params.id}/Question/${questionId}`
        );
        this.refreshData();
      } catch (err) {
        console.log(err);
      }
    },

    async autoFillQuestions() {
      try {
        await axiosInstance.post(
          `/lead/${this.$route.params.id}/Question/auto`,
          { type: this.$data.type }
        );
        this.refreshData();
      } catch (err) {
        console.log(err);
      }
    },

    async generateGoogleDoc() {
      try {
        this.$data.isBuilding = true;
        const { data } = await axiosInstance.get(
          `/lead/${this.$route.params.id}/Question/document?type=${this.$data.type}`
        );
        this.$data.documentId = data.data;
        this.$data.isBuilding = false;
      } catch (e) {
        this.$data.errorOnBuild = true;
        this.$data.isBuilding = false;
        console.log(e);
      }
    },

    copyDocumentLink() {
      navigator.clipboard.writeText(
        `${this.$data.googleDriveLink}/${this.$data.documentId}`
      );

      this.$data.copyText = "Copied to clipboard";
    },

    openLink() {
      window.open(`${this.$data.googleDriveLink}/${this.$data.documentId}`);
    },
  },
};
</script>

<style scoped>
.flex-wrap {
  display: flex;
  flex-direction: row;
}

.description {
  display: flex;
  flex-direction: column;
}

.block-title::first-letter {
  text-transform: uppercase;
}
.link-block {
  padding: 10px 0;
  display: flex;
  justify-content: center;
}

.link-box {
  display: flex;
  justify-content: center;
  border-color: rgba(224, 227, 235, 0.7);
  padding: 10px;
  margin-left: 30px;
  border-radius: 3px;
  border-width: 2px;
  border-style: solid;
  background-color: rgba(224, 227, 235, 0.3);
  height: fit-content;
}
.copy-link-button {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}
</style>
