<template>
  <div>
    <v-card elevation="2">
      <v-card-title class="card-title-style justify-space-between"
        >{{ expansionHeader }}
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" @click="autoFill" icon>
              <v-icon color="light-blue">mdi-auto-download</v-icon>
            </v-btn>
          </template>
          Autofill quality attributes
        </v-tooltip>
      </v-card-title>
      <div>
        <div
          v-for="(item, i) in items"
          :key="i"
          :class="i % 2 === 0 ? 'px-3' : 'card-text-bg px-3'"
        >
          <div class="pa-4">
            <v-checkbox
              hide-details
              :label="item.title"
              v-model="item.isSelected"
              @change="changeSelect(item.id, item.isSelected)"
              class="item-title-font"
            ></v-checkbox>
            <div class="item-description-font mt-1">{{ item.description }}</div>
          </div>
        </div>
      </div>
    </v-card>
  </div>
</template>
<script>
import axiosInstance from "@/axios";

export default {
  data: () => ({
    expansionHeader: "Software quality attributes",
  }),
  methods: {
    async changeSelect(id, status) {
      try {
        await axiosInstance.put(
          `/proposal/${this.$route.params.id}/QualityAttribute/${id}`,
          status,
          {
            headers: {
              "Content-Type": "application/problem+json; charset=utf-8",
            },
          }
        );
        await this.$store.dispatch("getSummaryData", this.$route.params.id);
        this.$store.dispatch("getCurrentProposalData", this.$route.params.id);
      } catch (e) {
        console.log(e);
      }
    },
    async autoFill() {
      await this.$store.dispatch("getSQAAutoData", this.$route.params.id);
      await this.$store.dispatch("getSummaryData", this.$route.params.id);
    },
  },
  computed: {
    items() {
      return this.$store.state.qualityAttributes;
    },
  },
};
</script>
<style scoped>
.block-title {
  display: inline-block;
}
::v-deep .v-input--selection-controls {
  margin: 0;
  padding: 0;
}
::v-deep .v-input--selection-controls__input input[role="checkbox"],
.v-input--selection-controls__input input[role="radio"],
.v-input--selection-controls__input input[role="switch"] {
  position: relative;
}
</style>
