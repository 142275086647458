var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._l((_vm.expansionPanelContent),function(milestone,index){return _c('v-card',{key:index,staticClass:"mb-10",attrs:{"elevation":"2"}},[_c('v-card-title',{staticClass:"card-title-style",class:_vm.getAnchour ? _vm.setAnchour(_vm.getAnchour, milestone.id) : ''},[_c('div',[_vm._v(" "+_vm._s(milestone.name)+" ")]),_c('div',{staticClass:"ml-auto d-flex flex-row"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('AddMilestoneTasks',{attrs:{"id":milestone.id}})],1)]}}],null,true)},[_c('span',[_vm._v("Add tasks")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('EditMilestoneName',{attrs:{"id":milestone.id,"name":milestone.name,"refreshData":_vm.refreshData}})],1)]}}],null,true)},[_c('span',[_vm._v("Edit milestone")])]),_c('MilestoneMenu',{attrs:{"id":milestone.id,"reloadData":_vm.refreshData,"menuOptions":_vm.milestoneMenuOptions}})],1)]),_c('v-card-text',[(_vm.isEmptyObj)?_c('div',[_c('draggable',{staticStyle:{"height":"100%"},attrs:{"animation":200,"ghost-class":"moving-card","group":"milestones"},on:{"change":function($event){return _vm.onGroupsChange($event, milestone.id)}},model:{value:(milestone.tasks),callback:function ($$v) {_vm.$set(milestone, "tasks", $$v)},expression:"milestone.tasks"}},_vm._l((milestone.tasks),function(item,index){return _c('v-card',{key:index,staticClass:"pa-3 py-5 mt-5 black--text ",attrs:{"elevation":"2"}},[_c('div',{staticClass:"d-flex flex-row"},[_c('div',[_c('v-card-title',{staticClass:"pb-0 pt-0"},[_vm._v(" "+_vm._s(item.name))]),_c('v-card-text',{staticClass:"pt-0 pb-0 d-flex "},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"d-flex flex-row align-center"},'div',attrs,false),on),[_c('v-icon',[_vm._v("mdi-clock-time-three")]),_c('div',{staticClass:"d-flex flex-row"},[_c('div',{staticClass:"mx-1"},[_vm._v(" "+_vm._s(item.optimisticHours + "hrs")+" ")]),_c('div',{staticClass:"mx-1"},[_vm._v(" "+_vm._s(item.pesimisticHours + "hrs")+" ")]),_c('div',{staticClass:"mx-1"},[_vm._v(" "+_vm._s(item.realisticHours + "hrs")+" ")])])],1)]}}],null,true)},[_c('span',[_vm._v("Optimistic, pesimistic, realistic hours")])]),_c('div',{staticClass:"d-flex flex-row align-center mr-1"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('div',{staticClass:"d-flex"},[_c('v-icon',[_vm._v("mdi-calendar-clock")]),_c('div',{staticClass:"mx-1"},[_vm._v(" "+_vm._s(item.estimateHours + "hrs")+" ")])],1)])]}}],null,true)},[_c('span',[_vm._v("Estimate hours")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('div',{staticClass:"d-flex"},[_c('v-icon',[_vm._v("mdi-timelapse")]),_c('div',{staticClass:"mx-1"},[_vm._v(" "+_vm._s(item.standardDeviation)+" ")])],1)])]}}],null,true)},[_c('span',[_vm._v("Standart deviation")])])],1)],1)],1),_c('div',{staticClass:"ml-auto my-auto"},[_c('v-btn',{staticClass:"color-red",attrs:{"icon":""},on:{"click":function($event){return _vm.deleteAlert(milestone.id, item.id)}}},[_c('v-icon',[_vm._v("mdi-delete-empty-outline")])],1)],1)])])}),1)],1):_c('v-card',{staticClass:"black--text mt-5"},[_c('div',{staticClass:"red--text"},[_vm._v("No data found!")])])],1)],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }