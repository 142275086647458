<template>
  <div class="nav-bar">
    <div class="d-flex">
      <BackToProposal />
      <AddArchitecture />
      <AddFromTemplate />
      <AutogenerateArchitecture />
    </div>
    <div><RefreshData /></div>
  </div>
</template>
<script>
import BackToProposal from "@/components/ProposalDetailed/Actions/BackToProposal";
import AddArchitecture from "./NavigationBar/AddArchitecture";
import AddFromTemplate from "./NavigationBar/AddFromTemplate";
import AutogenerateArchitecture from "./NavigationBar/AutogenerateArchitecture";
import RefreshData from "./NavigationBar/RefreshData";
export default {
  components: {
    BackToProposal,
    AddArchitecture,
    AddFromTemplate,
    AutogenerateArchitecture,
    RefreshData,
  },
};
</script>
<style scoped>
.nav-bar {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
</style>
