<template>
  <v-menu v-model="menu" bottom left>
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-bind="attrs" v-on="on" @click="preventRouting">
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
    </template>

    <v-list>
      <v-list-item
        v-for="({ title, methodName, icon }, i) in menuOptions"
        :key="i"
        class="menu-item px-0"
        @click="handleFunctionCall(methodName, id)"
      >
        <div
          v-if="icon.includes('mdi') || icon.includes('fas')"
          class="d-flex align-center option"
          style="width: 100%"
        >
          <v-icon class="icon-style mr-2">{{ icon }}</v-icon>
          <div>{{ title }}</div>
        </div>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import axiosInstance from "@/axios";

export default {
  data: () => ({
    menu: false,
  }),
  props: {
    id: Number,
    reloadData: Function,
    menuOptions: Array,
  },
  methods: {
    preventRouting(e) {
      e.preventDefault();
    },
    handleFunctionCall(methodName, id) {
      this[methodName](id);
    },
    async autoFill() {
      try {
        await axiosInstance.post(
          `/proposal/${this.$route.params.id}/Milestone/auto/${this.$props.id}`,
          {}
        );
        this.$props.reloadData();
      } catch (err) {
        console.log(err);
      }
    },
    deleteAlert() {
      this.$swal({
        title: "Do you want to delete this milestone?",
        showDenyButton: true,
        confirmButtonText: `Yes`,
        denyButtonText: `No`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteMilestone(this.$props.id);
        } else if (result.isDenied) {
          return;
        }
      });
    },
    async deleteMilestone(id) {
      let proposalId = this.$route.params.id;
      console.log(id);
      try {
        await axiosInstance.delete(`/proposal/${proposalId}/Milestone/${id}`);
        this.$props.reloadData();
      } catch (err) {
        console.log(err);
      }
    },

    async pushMilestoneDown() {
      try {
        await axiosInstance.post(
          `/proposal/${this.$route.params.id}/Milestone/index/down/${this.$props.id}`,
          {}
        );
        this.$props.reloadData();
      } catch (err) {
        console.log(err);
      }
    },

    async pushMilestoneUp() {
      try {
        await axiosInstance.post(
          `/proposal/${this.$route.params.id}/Milestone/index/up/${this.$props.id}`,
          {}
        );
        this.$props.reloadData();
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>

<style scoped>
.menu-item {
  cursor: pointer;
}
.menu-item:hover {
  background-color: rgba(224, 227, 235, 0.3);
}
.icon-style {
  color: rgba(0, 0, 0, 0.87);
  width: 20px;
  height: 16px;
}
.option {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 12px;
}
::v-deep .v-btn__content {
  justify-content: center;
}
</style>
