import Vue from "vue";
import Vuex from "vuex";
import axiosInstance from "@/axios";
import _ from "lodash";
import proposalsListModule from "./proposalsListModule";
import architecturesListModule from "./architecturesListModule";
import historyModule from "./historyModule";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    proposalsList: proposalsListModule,
    history: historyModule,
    architecturesList: architecturesListModule,
  },
  state: {
    proposalTitle: "",
    proposalState: null,
    proposalType: null,
    userData: null,
    lookups: [],
    completness: null,
    durations: [],
    errors: [],
    snackbar: false,
    errorMessage: "",
    leads: [],
    filter: false,
    filterName: "",
    asigneeId: null,
    filterType: 1,
    errorPopup: { status: false, message: "", timeout: null },
    qualityAttributes: [],
    deliverables: [],
    settings: {},
    singleValue: {},
    assignedLeadToProposal: null,
    projects: [],
    tasks: [],
    milestones: [],
    teamMembers: [],
    risks: [],
    services: [],
    simpleServices: [],
    taskDataSummary: [],
    milestoneSummary: [],
    error404Message: "",
    servicesSummary: [],
    cardMenuClockCloseStatus: true,
    anchour: "",
    redirectTo: null,
  },
  mutations: {
    setMilestones(state, value) {
      state.milestones = value;
    },
    setServices(state, value) {
      state.services = value;
    },
    setSimpleServices(state, value) {
      state.simpleServices = value;
    },
    setTaskDataSummary(state, value) {
      state.taskDataSummary = value;
    },
    setMilestoneSummary(state, value) {
      state.milestoneSummary = value;
    },
    setFilterStatus(state, value) {
      state.filter = value;
    },
    setFilterName(state, value) {
      state.filterName = value;
    },
    setAsigneeId(state, value) {
      state.asigneeId = value;
    },
    setFilterType(state, value) {
      state.filterType = value;
    },
    setLeads(state, value) {
      state.leads = value;
    },
    setUserData(state, value) {
      state.userData = value;
    },
    setSQAAutoData(state, value) {
      state.qualityAttributes = value;
    },
    setDeliverablesAutoData(state, value) {
      state.deliverables = value;
    },
    setSupportMaintenanceAutoData(state, value) {
      state.singleValue = value;
    },
    setGeneralSummaryAutoData(state, value) {
      state.singleValue = value;
    },
    setLookupData(state, value) {
      state.lookups = value;
    },
    setSummaryData(state, value) {
      state.completness = value.completness.value;
      state.durations = value.durations;
      state.errors = value.errors;
    },
    handleSnackbar(state, data) {
      state.errorPopup = data;
    },
    setTasksData(state, data) {
      state.tasks = data;
    },
    handleError404(state, data) {
      state.error404Message = data;
    },
    setServicesSummary(state, data) {
      state.servicesSummary = data;
    },
    setCurrentProposalData(state, data) {
      if (data) {
        state.deliverables = _.sortBy(data.deliverables, (i) => i.id);
        state.qualityAttributes = data.qualityAttributes;
        state.settings = data.settings;
        state.singleValue = data.singleValue;
        state.assignedLeadToProposal = data.lead;
        state.projects = data.projects;
        state.milestones = _(data.milestones)
          .sortBy((el) => el.index)
          .value();
        state.teamMembers = data.teamMembers;
        state.risks = data.risks;
        state.services = data.serviceTasks;
        state.proposalTitle = data.title;
        state.proposalType = data.proposalType;
        state.proposalState = data.state;
      } else state.singleValue = null;
    },
    setCardMenuCloseStatus(state, value) {
      state.cardMenuClockCloseStatus = value;
    },
    setAnchour(state, value) {
      state.anchour = value;
    },
    setRedirectTo(state, url) {
      state.redirectTo = url;
    },
  },
  actions: {
    async getLeads({ commit }) {
      let data = [];
      if (!this.state.asigneeId) {
        data = await axiosInstance.get(`/lead?filter=${this.state.filterType}`);
      } else {
        //#r change params to {queryParams: object}
        data = await axiosInstance.get(`/lead?filter=${this.state.filterType}&UserId=${this.state.asigneeId}`);
      }
      commit("setLeads", data.data.data);
    },
    async getSummaryTask({ commit }, id) {
      try {
        const { data } = await axiosInstance.get(
          `/proposal/${id}/Task/summary`
        );

        commit("setTaskDataSummary", data.data);
      } catch (err) {
        console.log(err);
      }
    },
    async getSimpleServices({ commit }, id) {
      let {
        data: { data: service },
      } = await axiosInstance.get(`/proposal/${id}/Service`);
      service = _(service)
        .sortBy("id")
        .value();

      commit("setSimpleServices", service);
    },
    async getSummaryServices({ commit }, id) {
      try {
        const { data } = await axiosInstance.get(
          `/proposal/${id}/Service/summary`
        );

        commit("setServicesSummary", data.data);
      } catch (err) {
        console.log(err);
      }
    },
    async getMilestoneSummary({ commit }, id) {
      try {
        const { data } = await axiosInstance.get(
          `/proposal/${id}/Milestone/summary`
        );
        commit("setMilestoneSummary", data.data);
      } catch (err) {
        console.log(err);
      }
    },

    async refreshMilestones({ commit }, id) {
      try {
        const { data } = await axiosInstance.get(`/proposal/${id}/Milestone`);
        let sortedByMilestoneIndex = _(data.data)
          .sortBy((el) => el.index)
          .value();
        let result = _(sortedByMilestoneIndex).forEachRight(
          (el) => (el.tasks = _.sortBy(el.tasks, (item) => item.milestoneIndex))
        );

        commit("setMilestones", result);
      } catch (err) {
        console.log(err);
      }
    },
    async refreshServices({ commit }, id) {
      try {
        const { data } = await axiosInstance.get(`/proposal/${id}/Service`);

        let serviceTypes = await this.state.lookups.find(function(val) {
          return val.lookupName.includes("ServiceType");
        });

        data.data.forEach((element) => {
          serviceTypes.values.forEach((el) => {
            if (element.serviceType === el.key) {
              element.serviceType = el.value;
              element.key = el.key;
            }
          });
        });

        let grouped = _.groupBy(data.data, function(data) {
          return data.serviceType;
        });

        var ordered = {};
        _(grouped)
          .keys()
          .sort()
          .each(function(key) {
            ordered[key] = grouped[key];
          });

        for (const key in ordered) {
          ordered[key] = _(ordered[key])
            .sortBy("id")
            .value();
        }

        commit("setServices", ordered);
      } catch (err) {
        console.log(err);
      }
    },
    async getLookups({ commit }) {
      try {
        const {
          data: { data },
        } = await axiosInstance.get(`/Dictionary/lookups`, {
          headers: {},
        });
        commit("setLookupData", data);
      } catch (e) {
        console.log(e);
      }
    },
    async getSQAAutoData({ commit }, proposalId) {
      try {
        const {
          data: { data },
        } = await axiosInstance.post(
          `/proposal/${proposalId}/QualityAttribute/`,
          {}
        );
        commit("setSQAAutoData", data);
      } catch (e) {
        console.log(e);
      }
    },
    async getDeliverablesAutoData({ commit }, proposalId) {
      try {
        const {
          data: { data },
        } = await axiosInstance.post(
          `/proposal/${proposalId}/Deliverable/`,
          {}
        );
        const sortedData = _.sortBy(data, (i) => i.id);
        commit("setDeliverablesAutoData", sortedData);
      } catch (e) {
        console.log(e);
      }
    },
    async getSupportMaintenanceAutoData({ commit }, proposalId) {
      try {
        const {
          data: { data },
        } = await axiosInstance.post(
          `/proposal/${proposalId}/SingleValue/support/auto`,
          {}
        );
        commit("setSupportMaintenanceAutoData", data);
      } catch (e) {
        console.log(e);
      }
    },
    async getGeneralSummaryAutoData({ commit }, proposalId) {
      try {
        const {
          data: { data },
        } = await axiosInstance.post(
          `/proposal/${proposalId}/SingleValue/summary/auto`,
          {}
        );
        commit("setGeneralSummaryAutoData", data);
      } catch (e) {
        console.log(e);
      }
    },
    async getTasksData({ commit }, proposalId) {
      try {
        const {
          data: { data },
        } = await axiosInstance.get(`/proposal/${proposalId}/Task`, {
          headers: {},
        });
        commit("setTasksData", data);
      } catch (err) {
        console.log(err);
      }
    },
    async getSummaryData({ commit }, proposalId) {
      if (proposalId) {
        try {
          const {
            data: { data },
          } = await axiosInstance.get(`/proposal/${proposalId}/summary`);
          commit("setSummaryData", data);
        } catch (e) {
          console.log(e);
        }
      }
    },

    async getCurrentProposalData({ commit, dispatch }, proposalId) {
      try {
        const {
          data: { data },
        } = await axiosInstance.get(`/proposal/${proposalId}`);
        commit("setCurrentProposalData", data);
        dispatch("getTasksData", proposalId);
      } catch (e) {
        commit("setCurrentProposalData", null);
        console.log(e);
      }
    },
  },
});
