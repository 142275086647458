<template>
  <v-dialog persistent v-model="dialog" max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn class="color-green" icon v-bind="attrs" v-on="on">
        <v-icon v-if="!edit" color="green">mdi-plus</v-icon>
        <v-icon v-else @click="autoFillFields()">mdi-pencil-outline</v-icon>
      </v-btn>
    </template>
    <v-card>
      <div v-if="isDataLoad && edit">
        <v-progress-circular
          indeterminate
          color="primary"
          style="overflow: hidden"
          class="d-flex my-auto mx-auto"
        ></v-progress-circular>
      </div>
      <div v-else>
        <v-card-title>
          <span v-if="!edit" class="headline">Add project</span>
          <span v-else class="headline">Edit project</span>
        </v-card-title>
        <v-card-text>
          <v-row no-gutters>
            <v-col>
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-textarea
                  label="Enter project name:"
                  auto-grow
                  outlined
                  rows="1"
                  row-height="15"
                  :rules="nameRules"
                  v-model="projName"
                >
                </v-textarea>
              </v-form>
              <v-card elevation="0" class="text--black mb-2">
                <v-checkbox
                  class="pt-0 mt-0 mb-1 "
                  v-for="(items, index) in stackTechnologie"
                  :key="index"
                  :label="`${items.title}`"
                  v-model="items.status"
                ></v-checkbox>
              </v-card>
              <v-combobox
                append-icon=""
                v-model="technologieSelect"
                :items="technologies"
                item-text="technologyName"
                color="blue"
                label="Technologies"
                small-chips
                hide-selected
                multiple
                :return-object="true"
                :search-input.sync="technologyName"
              >
                <template v-slot:append>
                  <AddTechnology
                    :key="technologyName"
                    v-bind:text="technologyName"
                    v-bind:addTechnologyLocal="addTechnologyFromDialog"
                  />
                </template>
                <template v-slot:no-data>
                  <v-list-item v-if="technologyName">
                    <span class="subheading ">
                      No technologie with name
                      <span class="red--text">"{{ technologyName }}"</span>
                      found!
                    </span>
                  </v-list-item>
                </template>
                <template v-slot:selection="{ attrs, item, parent, selected }">
                  <v-chip
                    small
                    v-if="item.technologyName"
                    v-bind="attrs"
                    :input-value="selected"
                    label
                    color="rgb(234 237 245)"
                  >
                    <span class="pr-5">
                      {{ item.technologyName }}
                    </span>
                    <v-icon color="red" small @click="parent.selectItem(item)">
                      mdi-minus
                    </v-icon>
                  </v-chip>
                </template>
              </v-combobox>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="clearAllData(), autoFillFields()">
            Close
          </v-btn>
          <v-btn v-if="!edit" color="primary" @click="AddProject()">
            Add
          </v-btn>
          <v-btn v-else color="primary" @click="EditProject()">
            Save
          </v-btn>
        </v-card-actions>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import axiosInstance from "@/axios";
//import { config } from "../../../../config.js";
import AddTechnology from "./AddTechnology";
export default {
  props: {
    edit: Boolean,
    id: Number,
  },
  components: {
    AddTechnology,
  },
  data: () => ({
    projName: "",
    valid: false,
    isDataLoad: false,
    technologyName: "",
    technologies: [],
    allProjects: [],
    nameRules: [(v) => !!v || "Name is required"],
    technologieSelect: [],
    dialog: false,
    stackTechnologie: [
      {
        title: "Web Frontend",
        status: false,
      },
      {
        title: "Mobile Frontend",
        status: false,
      },
      {
        title: "Backend",
        status: false,
      },
      {
        title: "Database",
        status: false,
      },
    ],
  }),
  watch: {
    technologyName: async function() {
      try {
        const { data } = await axiosInstance.get(
          `/Dictionary/technologies?search=${this.$data.technologyName}`
        );

        if (data) {
          this.$data.technologies = data.data;
        }
      } catch (err) {
        console.log(err);
      }
    },
  },
  methods: {
    async autoFillFields() {
      if (this.$props.edit && this.$props.id) {
        this.$data.isDataLoad = true;
        let { data: projects } = await axiosInstance.get(
          `/proposal/${this.$route.params.id}/Project`
        );
        this.$data.allProjects = projects.data;
        this.$data.isDataLoad = false;

        let currentProj = this.$data.allProjects.find(
          (el) => el.id == this.$props.id
        );

        this.$data.projName = currentProj.title;
        this.$data.stackTechnologie.forEach((element) => {
          switch (element.title) {
            case "Web Frontend":
              element.status = currentProj.isWebFrontend;
              break;
            case "Mobile Frontend":
              element.status = currentProj.isMobileFrontend;
              break;
            case "Backend":
              element.status = currentProj.isBackend;
              break;
            case "Database":
              element.status = currentProj.isDatabase;
              break;
          }
        });
        this.$data.technologieSelect = currentProj.technologies;
      }
    },

    addTechnologyFromDialog(elem) {
      this.technologieSelect.push(elem);
      this.technologyName = "";
    },

    clearAllData() {
      this.$data.projName = "";
      this.$data.stackTechnologie.forEach((element) => {
        switch (element.title) {
          case "Web Frontend":
            element.status = false;
            break;
          case "Mobile Frontend":
            element.status = false;
            break;
          case "Backend":
            element.status = false;
            break;
          case "Database":
            element.status = false;
            break;
        }
      });
      this.$data.technologieSelect = [];
      this.$refs.form.resetValidation();
      this.$data.dialog = false;
    },

    async AddProject() {
      let validation = this.$refs.form.validate();
      if (validation) {
        try {
          let isTechnologie = {
            isWebFrontend: false,
            isMobileFrontend: false,
            isBackend: false,
            isDatabase: false,
          };
          this.$data.stackTechnologie.forEach((element) => {
            switch (element.title) {
              case "Web Frontend":
                isTechnologie.isWebFrontend = element.status;
                break;
              case "Mobile Frontend":
                isTechnologie.isMobileFrontend = element.status;
                break;
              case "Backend":
                isTechnologie.isBackend = element.status;
                break;
              case "Database":
                isTechnologie.isDatabase = element.status;
                break;
            }
          });
          this.$data.technologieSelect.forEach((elem) => {
            delete elem.id;
          });

          let obj = {
            title: this.$data.projName,
            isWebFrontend: isTechnologie.isWebFrontend,
            isMobileFrontend: isTechnologie.isMobileFrontend,
            isBackend: isTechnologie.isBackend,
            isDatabase: isTechnologie.isDatabase,
            technologies: this.$data.technologieSelect,
          };

          await axiosInstance.post(
            `/proposal/${this.$route.params.id}/Project`,
            obj
          );
          this.clearAllData();
          await this.$store.dispatch("getSummaryData", this.$route.params.id);
          this.$store.dispatch("getCurrentProposalData", this.$route.params.id);
        } catch (err) {
          console.log(err);
        }
      }
    },
    async EditProject() {
      let validation = this.$refs.form.validate();
      if (validation) {
        try {
          let isTechnologie = {
            isWebFrontend: false,
            isMobileFrontend: false,
            isBackend: false,
            isDatabase: false,
          };
          this.$data.stackTechnologie.forEach((element) => {
            switch (element.title) {
              case "Web Frontend":
                isTechnologie.isWebFrontend = element.status;
                break;
              case "Mobile Frontend":
                isTechnologie.isMobileFrontend = element.status;
                break;
              case "Backend":
                isTechnologie.isBackend = element.status;
                break;
              case "Database":
                isTechnologie.isDatabase = element.status;
                break;
            }
          });

          let obj = {
            title: this.$data.projName,
            isWebFrontend: isTechnologie.isWebFrontend,
            isMobileFrontend: isTechnologie.isMobileFrontend,
            isBackend: isTechnologie.isBackend,
            isDatabase: isTechnologie.isDatabase,
            technologies: this.$data.technologieSelect,
          };

          await axiosInstance.put(
            `/proposal/${this.$route.params.id}/Project/${this.$props.id}`,
            obj
          );
          this.$data.dialog = false;
          await this.$store.dispatch("getSummaryData", this.$route.params.id);
          this.$store.dispatch("getCurrentProposalData", this.$route.params.id);
        } catch (err) {
          console.log(err);
        }
      }
    },
  },
};
</script>
