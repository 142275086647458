<template>
  <div>
    <v-card
      elevation="2"
      v-for="(milestone, index) in expansionPanelContent"
      :key="index"
      class="mb-10"
    >
      <v-card-title
        :class="getAnchour ? setAnchour(getAnchour, milestone.id) : ''"
        class="card-title-style"
        ><div>
          {{ milestone.name }}
        </div>

        <div class="ml-auto d-flex flex-row">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <AddMilestoneTasks :id="milestone.id" />
              </div>
            </template>
            <span>Add tasks</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <EditMilestoneName
                  :id="milestone.id"
                  :name="milestone.name"
                  :refreshData="refreshData"
                />
              </div>
            </template>
            <span>Edit milestone</span>
          </v-tooltip>

          <MilestoneMenu
            :id="milestone.id"
            :reloadData="refreshData"
            :menuOptions="milestoneMenuOptions"
          />
        </div>
      </v-card-title>
      <v-card-text>
        <div v-if="isEmptyObj">
          <draggable
            v-model="milestone.tasks"
            :animation="200"
            style="height: 100%;"
            ghost-class="moving-card"
            group="milestones"
            @change="onGroupsChange($event, milestone.id)"
          >
            <v-card
              elevation="2"
              class="pa-3 py-5 mt-5 black--text "
              v-for="(item, index) in milestone.tasks"
              :key="index"
            >
              <div class="d-flex flex-row">
                <div>
                  <v-card-title class="pb-0 pt-0">
                    {{ item.name }}</v-card-title
                  >
                  <v-card-text class="pt-0 pb-0 d-flex ">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <div
                          class="d-flex flex-row align-center"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon>mdi-clock-time-three</v-icon>
                          <div class="d-flex flex-row">
                            <div class="mx-1">
                              {{ item.optimisticHours + "hrs" }}
                            </div>
                            <div class="mx-1">
                              {{ item.pesimisticHours + "hrs" }}
                            </div>
                            <div class="mx-1">
                              {{ item.realisticHours + "hrs" }}
                            </div>
                          </div>
                        </div>
                      </template>
                      <span>Optimistic, pesimistic, realistic hours</span>
                    </v-tooltip>
                    <div class="d-flex flex-row align-center mr-1">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <div v-bind="attrs" v-on="on">
                            <div class="d-flex">
                              <v-icon>mdi-calendar-clock</v-icon>
                              <div class="mx-1">
                                {{ item.estimateHours + "hrs" }}
                              </div>
                            </div>
                          </div>
                        </template>
                        <span>Estimate hours</span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <div v-bind="attrs" v-on="on">
                            <div class="d-flex">
                              <v-icon>mdi-timelapse</v-icon>
                              <div class="mx-1">
                                {{ item.standardDeviation }}
                              </div>
                            </div>
                          </div>
                        </template>
                        <span>Standart deviation</span>
                      </v-tooltip>
                    </div>
                  </v-card-text>
                </div>
                <div class="ml-auto my-auto">
                  <v-btn
                    class="color-red"
                    @click="deleteAlert(milestone.id, item.id)"
                    icon
                  >
                    <v-icon>mdi-delete-empty-outline</v-icon>
                  </v-btn>
                </div>
              </div>
            </v-card>
          </draggable>
        </div>
        <v-card class="black--text mt-5" v-else>
          <div class="red--text">No data found!</div>
        </v-card>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import _ from "lodash";
import draggable from "vuedraggable";
import axiosInstance from "@/axios";

import AddMilestoneTasks from "../Actions/AddMilestoneTasks";
import MilestoneMenu from "../Actions/MilestoneMenu";
import EditMilestoneName from "../Actions/EditMilestoneName";

export default {
  components: {
    AddMilestoneTasks,
    draggable,
    MilestoneMenu,
    EditMilestoneName,
  },
  data: () => ({
    entity: "proposal",
    single: "Milestone",
    expansionHeader: null,
    includesWords: ["optimisticHours", "pesimisticHours", "realisticHours"],
    groupedByNameId: [],
    milestoneMenuOptions: [
      {
        title: "Up",
        methodName: "pushMilestoneUp",
        icon: "fas fa-arrow-up",
      },
      {
        title: "Down",
        methodName: "pushMilestoneDown",
        icon: "fas fa-arrow-down",
      },
      {
        title: "Autofill",
        methodName: "autoFill",
        icon: "mdi-auto-download",
      },
      {
        title: "Delete",
        methodName: "deleteAlert",
        icon: "fas fa-trash-alt",
      },
    ],
  }),
  computed: {
    isEmptyObj: function() {
      if (this.expansionPanelContent) {
        let isEmpty = _.isEmpty(this.expansionPanelContent);
        return !isEmpty;
      }
      return null;
    },
    expansionPanelContent: function() {
      return this.$store.state.milestones;
    },
    getAnchour() {
      return this.$store.state.anchour;
    },
    getUrl() {
      return this.$router.currentRoute.path;
    },
  },
  async created() {
    scroll(0, 0);
    this.refreshData();
    await this.goTo();
  },
  methods: {
    goTo() {
      var vm = this;
      if (
        _(this.getUrl).includes("milestones") &&
        vm.getAnchour &&
        this.expansionPanelContent.length
      ) {
        setTimeout(function() {
          vm.$vuetify.goTo("." + vm.getAnchour, { offset: 0 });
          vm.$store.commit("setAnchour", "");
        }, 500);
      }
    },
    setAnchour(anchour, curId) {
      let id = _(anchour)
        .split("-")
        .pop();
      if (id == curId) {
        return "a-" + curId;
      } else {
        return null;
      }
    },
    deleteAlert(milestoneId, taskId) {
      this.$swal({
        title: "Do you want to delete this milestone?",
        showDenyButton: true,
        confirmButtonText: `Yes`,
        denyButtonText: `No`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteTasks(milestoneId, taskId);
        } else if (result.isDenied) {
          return;
        }
      });
    },
    async deleteTasks(milestoneId, taskId) {
      let proposalId = this.$route.params.id;
      try {
        await axiosInstance.delete(
          `/proposal/${proposalId}/Milestone/tasks/${milestoneId}/${taskId}`
        );
        this.refreshData();
      } catch (err) {
        console.log(err);
      }
    },

    async changeCoordinates(evt, name, milestoneIndex) {
      let obj = {
        milestoneId: milestoneIndex,
        milestoneIndex: evt[name].newIndex,
      };

      let id = evt[name].element.id;
      try {
        await axiosInstance.put(
          `/proposal/${this.$route.params.id}/Milestone/${id}/coordinates`,
          obj
        );
        this.refreshData();
      } catch (e) {
        console.log(e.message);
      }
    },
    async onGroupsChange(evt, milestoneIndex) {
      console.log(evt);
      if ("moved" in evt) {
        this.changeCoordinates(evt, "moved", milestoneIndex);
      }
      if ("added" in evt) {
        this.changeCoordinates(evt, "added", milestoneIndex);
      }
    },
    async deleteTask(milestoneId, taskId) {
      await axiosInstance.delete(
        `/proposal/${this.$route.params.id}/Milestone/tasks/${milestoneId}/${taskId}`
      );
      this.refreshData();
    },
    async refreshData() {
      this.$store.dispatch("refreshMilestones", this.$route.params.id);
      this.$store.dispatch("getMilestoneSummary", this.$route.params.id);
      await this.$store.dispatch("getSummaryData", this.$route.params.id);
    },
  },
};
</script>

<style scoped>
.block-subtitle::first-letter {
  text-transform: uppercase;
}

.moving-card {
  opacity: 0.5;
  background: #f4f5f7;
  border: 3px solid blue !important;
}
</style>
