<template>
  <v-card elevation="2">
    <v-card-title class="card-title-style justify-space-between"
      >{{ expansionHeader }}
      <div class="d-flex">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              class="global-button-vuetify"
              icon
              color="green"
              @click="$router.push(`${proposalId}/architecture`)"
            >
              <v-icon color="green">mdi-pencil-plus</v-icon>
            </v-btn>
          </template>
          Edit architectures
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" @click="autoFill" icon>
              <v-icon color="light-blue">mdi-auto-download</v-icon>
            </v-btn>
          </template>
          Autofill solution architecture
        </v-tooltip>
      </div>
    </v-card-title>
    <div>
      <div
        v-for="({ title, description }, i) in architectures"
        :key="i"
        :class="i % 2 === 0 ? 'px-3' : 'card-text-bg px-3'"
      >
        <div class="pa-4">
          <div class="item-title-font">{{ title }}</div>
          <div class="item-description-font mt-1">{{ description }}</div>
        </div>
      </div>
      <div
        v-if="!architectures.length"
        class="red--text px-7 py-4"
        style="font-size:0.875rem"
      >
        No data found!
      </div>
    </div>
    <v-snackbar
      v-model="errorPopup.status"
      :timeout="errorPopup.timeout"
      bottom
    >
      {{ errorPopup.message }}

      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="closePopup()">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>
<script>
export default {
  data: () => ({
    expansionHeader: "Solution architecture",
    linkText: "Go to proposal's architectures",
  }),
  computed: {
    proposalId() {
      return this.$route.params.id;
    },
    architectures() {
      return this.$store.state.architecturesList.architectures;
    },
    errorPopup() {
      return this.$store.state.architecturesList.errorPopup;
    },
  },
  async created() {
    await this.$store.dispatch("getArchitecturesList", this.$route.params.id);
  },
  methods: {
    async autoFill() {
      await this.$store.dispatch(
        "autoGenerateArchitectures",
        this.$route.params.id
      );
      await this.$store.dispatch("getSummaryData", this.$route.params.id);
    },
    closePopup() {
      this.$store.commit("handleNoDirection", {
        status: false,
        message: "",
        timeout: null,
      });
    },
  },
};
</script>
<style scoped></style>
