<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="global-button-vuetify"
          icon
          @click="refresh"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon color="#92278e">mdi-autorenew</v-icon>
        </v-btn></template
      >Refresh diagrams</v-tooltip
    >
  </div>
</template>
<script>
export default {
  methods: {
    refresh() {
      this.$store.commit("setComponentKey");
    },
  },
};
</script>
