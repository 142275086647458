<template>
  <div>
    <div
      v-for="({
        id,
        title,
        industry,
        completness,
        technologies,
        leadId,
        leadTitle,
        proposalType
      },
      i) in proposals"
      :key="i"
      class="proposal-card"
    >
      <router-link
        :to="`/${entitiesName}/${id}`"
        :style="{ textDecoration: 'none' }"
      >
        <div elevation="2" class="proposal-container">
          <v-tooltip bottom v-if="proposalTypeTitle(proposalType)">
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on" class="d-flex">
                <Label :text="proposalTypeTitle(proposalType)" :customClass="'default'" />
              </div></template
            >Proposal type</v-tooltip
          >
          <div class="d-flex justify-space-between">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on" class="proposal-name">
                  {{ title }}
                </div></template
              >Title</v-tooltip
            >

            <div class="d-flex justify-end">
              <v-tooltip bottom>
                <template
                  v-slot:activator="{
                    on,
                    attrs,
                  }"
                >
                  <v-progress-circular
                    v-bind="attrs"
                    v-on="on"
                    :rotate="-90"
                    :size="45"
                    :width="5"
                    :value="Math.ceil(completness)"
                    color="#92278e"
                  >
                    {{ completness }}
                  </v-progress-circular></template
                >Completness</v-tooltip
              >

              <CardMenu
                :id="id"
                :title="title"
                :proposalTitle="title"
                :leadTitle="leadTitle"
                :leadId="leadId"
                :entitiesName="entitiesName"
                :singleEntityName="singleEntityName"
                :reloadData="reloadData"
                :menuOptions="menuOptions"
                :isCopyProposal="true"
                :isLeadSelectionEnabled="true"
              />
            </div>
          </div>

          <v-tooltip bottom v-if="leadTitle">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                text
                color="primary"
                v-if="leadTitle"
                v-bind="attrs"
                v-on="on"
                class="lead-button"
              >
                <router-link :to="`/leads/${leadId}`" class="lead-title">
                  {{ leadTitle }}</router-link
                >
              </v-btn></template
            >Assigned lead</v-tooltip
          >
          <div class="no-data" v-else>No assigned lead</div>

          <v-tooltip bottom v-if="industry">
            <template v-slot:activator="{ on, attrs }"
              ><div v-bind="attrs" v-on="on" class="industry">
                {{ industry }}
              </div></template
            >Industry</v-tooltip
          >
          <div class="no-data" v-else>No selected industry</div>

          <div class="technologies" v-if="technologies.length">
            <v-chip
              small
              v-for="({ technologyName, description }, j) in technologies"
              :key="j"
              class="current-technology"
              color="rgb(234 237 245)"
              label
              ><v-tooltip v-if="description" bottom max-width="300px">
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    {{ technologyName }}
                  </div></template
                >{{ description }}</v-tooltip
              >
            </v-chip>
          </div>
          <div class="no-data" v-else>No selected technologies</div>
        </div>
      </router-link>
    </div>
    <div class="d-flex justify-center">
      <v-btn color="primary" @click="loadMoreCards" v-if="isLoadMoreActive">
        Load more
      </v-btn>
    </div>
  </div>
</template>
<script>
import CardMenu from "../LeadDraggble/CardMenu";
import Label from "../Common/Label.vue"
import { proposalTypes } from "../../../config";

export default {
  components: { 
    CardMenu,
    Label,
   },
  data: () => ({
    menuOptions: [
      // { title: "Open", methodName: "openItemPage", icon: "fas fa-eye" },
      //{ title: "Edit title", icon: "fas fa-edit", isTitleEditing: true },
      {
        title: "Get link",
        getLink: true,
        icon: "fas fa-link",
      },
      { title: "Make a copy", icon: "fas fa-copy", copy: true },
      { title: "Delete", methodName: "deleteItem", icon: "fas fa-trash-alt" },
    ],
    entitiesName: "proposals",
    singleEntityName: "proposal",
  }),
  created() {
    this.$store.dispatch("getProposalsList");
  },
  methods: {
    async loadMoreCards() {
      this.$store.commit("setPageNumber");
      this.$store.dispatch("getProposalsList");
    },
    async reloadData() {
      this.$store.commit("setPageNumber", 1);
      await this.$store.dispatch("getProposalsList");
    },
    proposalTypeTitle(type) {
      const proposalType = proposalTypes.find(item => item.id === type);
      return proposalType?.value;
    },
  },
  computed: {
    proposals() {
      return this.$store.state.proposalsList.proposals;
    },
    isLoadMoreActive() {
      if (
        this.$store.state.proposalsList.count ===
        this.$store.state.proposalsList.proposals.length
      ) {
        return false;
      }
      return true;
    },
  },
};
</script>
<style scoped>
.v-application a {
  color: inherit;
}
.proposal-card {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  margin: 15px 0;
  border-radius: 4px;
}
.proposal-card:hover {
  background-color: rgba(224, 227, 235, 0.3);
}

.proposal-container {
  padding: 20px;
}
.proposal-name {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 22px;
  width: fit-content;
}
.title-industry-completness {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
}
.industry {
  width: fit-content;
  font-size: 1rem;
  line-height: 1.375rem;
  letter-spacing: 0.0071428571em;
  color: rgba(0, 0, 0, 0.8);
  padding: 10px 0;
}
.lead-and-techs {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
}

.lead-button {
  width: fit-content;
}
.lead-title {
  text-decoration: none;
}
.technologies {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  max-width: 800px;
}
.current-technology {
  margin: 5px 0;
  margin-right: 5px;
  margin-bottom: 0;
}
.block-name {
  font-weight: 500;
  font-size: 18px;
}
.no-data {
  font-size: 0.75rem;
  line-height: 1.375rem;
  letter-spacing: 0.0071428571em;
  color: rgba(0, 0, 0, 0.4);
}
::v-deep .v-btn {
  text-transform: none;
  display: flex;
  justify-content: flex-start;
}
::v-deep .v-btn:not(.v-btn--round).v-size--small {
  padding: 0;
}
</style>
