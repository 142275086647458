<template>
  <div>
    <template>
      <v-dialog persistent v-model="dialog" max-width="600px">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="global-button-vuetify"
            icon
            color="#92278e"
            v-bind="attrs"
            v-on="on"
            @click="templateRefreshData()"
          >
            <v-icon color="#92278e">mdi-book-open-blank-variant</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span>Add task templates</span>
          </v-card-title>
          <v-card-text>
            <v-row no-gutters>
              <v-col>
                <v-select
                  v-model="select"
                  :items="items"
                  label="Select template"
                  :menu-props="{ bottom: true, offsetY: true }"
                  item-text="name"
                  multiple
                  dense
                  return-object
                ></v-select>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="clearAllData()">
              Close
            </v-btn>
            <v-btn color="primary" @click="addTemplate()">
              Add
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </div>
</template>

<script>
import axiosInstance from "@/axios";
export default {
  props: {
    projectId: Number,
    refreshData: Function,
  },
  data: () => ({
    select: [],
    dialog: false,
    items: [],
  }),
  async created() {
    this.templateRefreshData();
  },
  methods: {
    clearAllData() {
      this.$data.items = [];
      this.$data.select = [];
      this.$data.dialog = false;
    },
    async templateRefreshData() {
      try {
        const { data } = await axiosInstance.get(
          `/proposal/${this.$route.params.id}/Task/templates/${this.$props.projectId}`
        );
        this.$data.items = data.data;
      } catch (err) {
        console.log(err);
      }
    },
    async addTemplate() {
      try {
        console.log(this.$data.select);
        let filteredSelect = this.$data.select.map((task) => ({
          name: task.name,
          optimisticHours: task.optimisticHours,
          pesimisticHours: task.pesimisticHours,
          realisticHours: task.realisticHours,
          projectId: task.projectId,
        }));
        await axiosInstance.post(
          `/proposal/${this.$route.params.id}/Task/bulk/${this.$props.projectId}`,
          filteredSelect
        );
        this.$props.refreshData();
        this.clearAllData();
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>
