<template>
  <v-dialog persistent v-model="dialog" max-width="500px">
    <template v-slot:activator="{ on, attrs }">
      <div
        v-bind="attrs"
        v-on="on"
        @click="(dialog = true), closeMenu()"
        class="option"
      >
        <i :class="className" class="mr-2 icon-style" />
        <div>{{ title }}</div>
      </div>
    </template>
    <v-card>
      <v-card-title class="px-4">Get link</v-card-title>
      <v-card-text class="d-flex justify-center pb-1 px-4">
        <div class="link-box">
          <a :href="`${getWindow}/${id}`"
            ><div>{{ getWindow }}/{{ id }}</div></a
          >
        </div>
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <div>
              <v-btn
                class="px-2 ml-2 copy-button"
                v-bind="attrs"
                v-on="on"
                elevation="0"
                @mouseleave="copyText = 'Copy'"
                @click="getLinkToItemPage()"
              >
                <v-icon color="rgb(66, 82, 110)">mdi-content-copy</v-icon>
              </v-btn>
            </div>
          </template>
          <span>{{ copyText }}</span>
        </v-tooltip>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="rgb(66, 82, 110)"
          text
          @click="closeMenu(), (dialog = false)"
          min-width="0px"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    id: Number,
    title: String,
    className: String,
    closeMenu: Function,
  },
  data: () => ({
    dialog: false,
    copyText: "Copy",
  }),
  computed: {
    getWindow() {
      return window.location.href;
    },
  },
  methods: {
    getLinkToItemPage() {
      navigator.clipboard.writeText(
        `${window.location.href}/${this.$props.id}`
      );
      this.$data.copyText = "Copied to clipboard";
    },
  },
};
</script>

<style scoped>
.option {
  display: flex;
  align-items: center;
  padding: 12px;
}

.icon-style {
  width: 20px;
}

.copy-button {
  height: 100% !important;
  min-width: 34px !important;
}

.link-box {
  border-color: rgba(224, 227, 235, 0.7);
  padding: 10px;
  width: 100%;
  margin-left: 0 !important;
  border-radius: 3px;
  border-width: 2px;
  border-style: solid;
  background-color: rgba(224, 227, 235, 0.3);
  height: fit-content;
}
::v-deep .v-card__actions > .v-btn.v-btn {
  padding: 0;
}
</style>
