<template>
  <v-dialog persistent v-model="dialog" max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="global-button-vuetify"
        v-bind="attrs"
        v-on="on"
        fab
        dark
        small
        color="green"
      >
        <v-icon class="px-2">mdi-plus</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">Add project</span>
      </v-card-title>
      <v-card-text class=" pb-0 pr-5">
        <v-row no-gutters>
          <v-col>
            <v-textarea
              label="Enter milestone name "
              auto-grow
              outlined
              rows="1"
              row-height="15"
              v-model="milestoneName"
            >
            </v-textarea>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="(dialog = false), (milestoneName = '')">
          Close
        </v-btn>
        <v-btn color="primary" @click="AddMilestone(), (dialog = false)">
          Add
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axiosInstance from "@/axios";
//import { config } from "../../../../config.js";
export default {
  data: () => ({
    milestoneName: "",
    dialog: false,
  }),

  methods: {
    async AddMilestone() {
      try {
        await axiosInstance.post(
          `/proposal/${this.$route.params.id}/Milestone`,
          {
            name: this.$data.milestoneName,
          }
        );
        this.$data.milestoneName = "";
        this.$store.dispatch("refreshMilestones", this.$route.params.id);
        this.$store.dispatch("getMilestoneSummary", this.$route.params.id);
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>
