import axiosInstance from "@/axios";
import _ from "lodash";
//import { config } from "../../config";

export default {
  state: {
    pageNumber: 1,
    pageSize: 10,
    proposals: [],
    count: null,
  },
  mutations: {
    setPageNumber(state, value) {
      if (value) state.pageNumber = value;
      else state.pageNumber += 1;
    },
    setPageSize(state, value) {
      state.pageSize = value;
    },
    setProposals(state, data) {
      state.proposals = data;
    },
    setCount(state, value) {
      state.count = value;
    },
  },
  actions: {
    async getProposalsList({ commit, state }) {
      try {
        let {
          data: { data },
        } = await axiosInstance.get(
          `/proposal?pageNumber=${state.pageNumber}&pageSize=${state.pageSize}`
        );
        let newItems;
        if (state.pageNumber === 1) {
          newItems = [...data.proposals];
        } else {
          newItems = [...state.proposals, ...data.proposals];
        }
        newItems = _.sortBy(newItems, (i) => i.id).reverse();
        commit("setProposals", newItems);
        commit("setCount", data.count);
      } catch (e) {
        console.log(e.message);
      }
    },
  },
};
