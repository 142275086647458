<template>
  <v-dialog persistent v-model="dialog" max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="global-button-vuetify"
        icon
        color="green"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon color="green">mdi-plus</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">Add proposals</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form ref="form" class="mx-2" lazy-validation>
            <v-row no-gutters>
              <v-col cols="12">
                <v-textarea
                  label="Enter proposal title"
                  auto-grow
                  rows="1"
                  row-height="15"
                  v-model="text"
                  :rules="rules"
                >
                </v-textarea>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  :items="proposalTypes"
                  item-text="value"
                  item-value="id"
                  v-model="selectedProposalType"
                  label="Type"
                  :rules="rules"
                ></v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="closeDialog">
          Cancel
        </v-btn>
        <v-btn color="primary" @click="addLeadProposal">
          Add
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axiosInstance from "@/axios";
import { proposalTypes } from "../../../../config";
export default {
  props: {
    refreshData: Function,
  },
  data: () => ({
    text: "",
    proposalTypes,
    selectedProposalType: null,
    dialog: false,
    questions: null,
    rules: [(v) => !!v || "Field is required"],
  }),
  methods: {
    async addLeadProposal() {
      if (this.$refs.form.validate()) {
        let obj = {
          title: this.$data.text,
          proposalType: this.$data.selectedProposalType,
          leadId: Number(this.$route.params.id),
        };
        try {
          const {
            data: { data },
          } = await axiosInstance.post(`/Proposal`, obj);
          this.closeDialog();
          await this.$store.dispatch("getSummaryData", data.id);
          this.$props.refreshData();
        } catch (e) {
          console.log(e);
        }
      }
    },
    closeDialog() {
      this.$data.dialog = false;
      this.$refs.form.reset();
    },
  },
};
</script>
