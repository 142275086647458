<template>
  <div>
    <v-card elevation="2">
      <v-card-title class="card-title-style justify-space-between"
        >{{ expansionHeader }}
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn @click="autoFill" icon v-bind="attrs" v-on="on">
              <v-icon color="light-blue">mdi-auto-download</v-icon>
            </v-btn>
          </template>
          Autofill deliverables
        </v-tooltip>
      </v-card-title>
      <div>
        <div
          v-for="(item, i) in items"
          :key="i"
          :class="i % 2 === 0 ? 'px-3' : 'px-3 card-text-bg'"
        >
          <v-checkbox
            :label="item.title"
            v-model="item.isSelected"
            @change="changeSelect(item.id, item.isSelected)"
            class="pa-4 item-title-font"
            hide-details
          ></v-checkbox>
        </div>
      </div>
    </v-card>
  </div>
</template>
<script>
import axiosInstance from "@/axios";

export default {
  data: () => ({
    expansionHeader: "Deliverables",
  }),
  methods: {
    async changeSelect(id, status) {
      try {
        await axiosInstance.put(
          `/proposal/${this.$route.params.id}/Deliverable/${id}`,
          status,
          {
            headers: {
              "Content-Type": "application/problem+json; charset=utf-8",
            },
          }
        );
        await this.$store.dispatch("getSummaryData", this.$route.params.id);
        this.$store.dispatch("getCurrentProposalData", this.$route.params.id);
      } catch (e) {
        console.log(e);
      }
    },
    async autoFill() {
      await this.$store.dispatch(
        "getDeliverablesAutoData",
        this.$route.params.id
      );
      await this.$store.dispatch("getSummaryData", this.$route.params.id);
      this.$store.dispatch("getCurrentProposalData", this.$route.params.id);
    },
  },
  computed: {
    items() {
      return this.$store.state.deliverables;
    },
  },
};
</script>
<style scoped>
.block-title {
  display: inline-block;
}
::v-deep .v-input--selection-controls__input input[role="checkbox"],
.v-input--selection-controls__input input[role="radio"],
.v-input--selection-controls__input input[role="switch"] {
  position: relative;
}
</style>
