<template>
  <div>
    <div v-if="!isEdit" class="title-block">
      <div class="proposal-title">{{ title }}</div>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            color="green"
            icon
            @click="enableEditing"
          >
            <v-icon>mdi-pencil-plus</v-icon>
          </v-btn>
        </template>
        Edit proposal title
      </v-tooltip>
    </div>
    <div class="editing-block">
      <v-textarea
        v-if="isEdit"
        label="Enter title"
        auto-grow
        outlined
        rows="1"
        row-height="15"
        v-model="inputData"
        :rules="titleRules"
      />
      <div v-if="isEdit" class="edit-buttons">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              color="green"
              @click="editTitle"
            >
              <v-icon color="green">mdi-check</v-icon>
            </v-btn>
          </template>
          Apply changes
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              color="green"
              @click="cancelEditing"
            >
              <v-icon color="red">mdi-close</v-icon>
            </v-btn>
          </template>
          Cancel changes
        </v-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import axiosInstance from "@/axios";

export default {
  data: () => ({
    inputData: "",
    isEdit: false,
    titleRules: [(v) => !!v || "Title is required"],
  }),
  computed: {
    title() {
      return this.$store.state.proposalTitle;
    },
  },
  methods: {
    enableEditing() {
      this.$data.isEdit = true;
      this.$data.inputData = this.title;
    },
    async editTitle() {
      if (!this.$data.inputData) {
        return;
      }
      const proposalId = this.$route.params.id;
      const newTitle = this.$data.inputData;
      try {
        await axiosInstance.put(`/Proposal/${proposalId}/title/${newTitle}`);
        this.$store.dispatch("getCurrentProposalData", proposalId);
      } catch (e) {
        console.log(e);
      }
      this.$data.isEdit = false;
    },
    cancelEditing() {
      this.$data.isEdit = false;
    },
  },
};
</script>
<style scoped>
.title-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  padding-right: 16px;
}
.proposal-title {
  font-weight: 500;
  font-size: 26px;
}
.editing-block {
  display: flex;
  padding-right: 16px;
}
.edit-buttons {
  padding-top: 8px;
}
</style>
