<template>
  <v-card elevation="2">
    <v-card-title class="card-title-style"
      >{{ expansionHeader }}
      <div class="ml-auto d-flex flex-row">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <SetCostSuggestions :id="proposalId" :refreshData="refreshData"/>
            </div>
          </template>
          <span>Cost suggestions</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <AddEditTeamMember :refreshData="refreshData" :edit="false" />
            </div>
          </template>
          <span>Add team member</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <v-btn color="blue" @click="autoFill" icon>
                <v-icon>mdi-auto-download</v-icon>
              </v-btn>
            </div>
          </template>
          <span>Autofill team members</span>
        </v-tooltip>
      </div>
    </v-card-title>
    <v-card-text class="px-0 pb-0 black--text" v-if="isEmptyObj">
      <div
        v-for="({ 
          id,
          level,
          specialization,
          allocation,
          hourlyPayment,
          monthlyPayment,
          technologies,
          cVs
        },
        index) in expansionPanelContent"
        :key="index"
        :class="index % 2 === 0 ? '' : 'card-text-bg'"
        class="px-3"
      >
        <div class="d-flex flex-row ">
          <div class="pa-4">
            <div
              class="item-title-font"
              v-html="
                specialization
                  ? getSpecialization(specialization)
                  : '<div style=color:red>No specialization, please edit one</div>'
              "
            ></div>
            <div class="d-flex mt-1">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    <div
                      class="mr-2 item-description-font"
                      v-html="
                        level
                          ? getLevel(level)
                          : '<div style=color:red>No level, please edit one</div>'
                      "
                    ></div>
                  </div>
                </template>
                <span>Position</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    <div class="mr-2 item-description-font">
                      {{ allocation + "%" }}
                    </div>
                  </div>
                </template>
                <span>Allocation</span>
              </v-tooltip>
              <v-tooltip v-if="hourlyPayment" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    <div class="mr-2 item-description-font">
                      {{ hourlyPayment + '$' }}
                    </div>
                  </div>
                </template>
                <span>Price per hour</span>
              </v-tooltip>
              <v-tooltip v-if="monthlyPayment" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    <div class="item-description-font">
                      {{ monthlyPayment + '$' }}
                    </div>
                  </div>
                </template>
                <span>Price per month</span>
              </v-tooltip>
            </div>
            <div class="my-auto mt-1" v-if="technologies.length">
              <v-chip
                small
                v-for="({ technologyName }, i) in technologies"
                :key="i"
                class="mr-2"
                label
                color="rgb(234 237 245)"
              >
                <span>{{ technologyName }}</span>
              </v-chip>
            </div>
            <div class="my-auto mt-4" v-if="cVs.length">
              <v-chip
                small
                v-for="({ cvLink }, i) in cVs"
                :key="i"
                class="mr-2 cv-link-font"
                label
                color="rgb(146, 39, 142)"
                @click="openCVInNewWindow(cvLink)"
              >
                CV №{{ i + 1 }}
              </v-chip>
            </div>
          </div>
          <div class="ml-auto d-flex flex-row my-auto">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <AddEditTeamMember
                    :refreshData="refreshData"
                    :edit="true"
                    :items="expansionPanelContent"
                    :id="id"
                  />
                </div>
              </template>
              <span>Edit team member</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <v-btn class="color-red" @click="deleteAlert(id)" icon>
                    <v-icon>mdi-delete-empty-outline</v-icon>
                  </v-btn>
                </div>
              </template>
              <span>Delete team member</span>
            </v-tooltip>
          </div>
        </div>
      </div>
    </v-card-text>
    <v-card-text class="black--text mt-5 pl-7" v-else>
      <div class="red--text">No data found!</div>
      <div class="item-description-font">
        Try to add team member, or use autofill.
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import AddEditTeamMember from "./Actions/AddEditTeamMember";
import SetCostSuggestions from "./Actions/SetCostSuggestions.vue";
import axiosInstance from "@/axios";
import _ from "lodash";
export default {
  components: {
    AddEditTeamMember,
    SetCostSuggestions,
  },
  data: () => ({
    expansionHeader: "Team",
  }),
  computed: {
    isEmptyObj: function() {
      if (this.expansionPanelContent) {
        let isEmpty = _.isEmpty(this.expansionPanelContent);
        return !isEmpty;
      }
      return null;
    },
    expansionPanelContent: function() {
      return this.$store.state.teamMembers;
    },
    lookups: function() {
      return this.$store.state.lookups;
    },
    proposalId() {
      return Number(this.$route.params.id);
    }
  },

  methods: {
    getSpecialization: function(spec) {
      let teamSpecialization = _.find(this.lookups, function(o) {
        return o.lookupName.includes("TeamSpecialization");
      });
      let newScec = spec;
      let curSpec = teamSpecialization.values.find((o) => o.key == newScec);
      if (curSpec) {
        return curSpec.value;
      }
    },
    getLevel: function(lev) {
      let level = _.find(this.lookups, function(o) {
        return o.lookupName.includes("TeamLevel");
      });
      let newLevel = lev;
      let curLevel = level.values.find((o) => o.key == newLevel);
      if (curLevel) {
        return curLevel.value;
      }
    },
    async autoFill() {
      try {
        await axiosInstance.post(
          `/proposal/${this.proposalId}/TeamMember/auto`,
          {},
          {
            headers: {},
          }
        );
        await this.$store.dispatch("getSummaryData", this.proposalId);
        this.$store.dispatch("getCurrentProposalData", this.proposalId);
      } catch (err) {
        console.log(err);
      }
    },
    async refreshData() {
      this.$store.dispatch("getCurrentProposalData", this.proposalId);
    },
    deleteAlert(teamMemberID) {
      this.$swal({
        title: "Do you want to delete this team member?",
        showDenyButton: true,
        confirmButtonText: `Yes`,
        denyButtonText: `No`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteTeamMember(teamMemberID);
        } else if (result.isDenied) {
          return;
        }
      });
    },
    async deleteTeamMember(teamMemberID) {
      try {
        await axiosInstance.delete(
          `/proposal/${this.proposalId}/TeamMember/${teamMemberID}`,
          {
            headers: {},
          }
        );
        await this.$store.dispatch("getSummaryData", this.proposalId);
        this.$store.dispatch("getCurrentProposalData", this.proposalId);
      } catch (err) {
        console.log(err);
      }
    },
    openCVInNewWindow(cvLink) {
      if (cvLink) {
        window.open(cvLink, '_blank');
      }
    },
  },
};
</script>

<style scoped>
.block-title {
  display: inline-block;
}
.cv-link-font {
  color: #FFFFFF;
}
</style>
