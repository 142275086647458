var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"elevation":"2"}},[_c('v-card-title',{staticClass:"card-title-style"},[_vm._v(_vm._s(_vm.expansionHeader)+" "),_c('div',{staticClass:"ml-auto"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('AddProjects')],1)]}}])},[_c('span',[_vm._v("Add project")])])],1)]),(this.$store.state.projects.length)?_c('div',_vm._l((_vm.expansionPanelContent),function(ref,index){
      var id = ref.id;
      var title = ref.title;
      var isWebFrontend = ref.isWebFrontend;
      var isMobileFrontend = ref.isMobileFrontend;
      var isBackend = ref.isBackend;
      var isDatabase = ref.isDatabase;
      var technologies = ref.technologies;
return _c('v-card-text',{key:index,staticClass:"px-3 py-0 black--text ",class:index % 2 === 0 ? '' : 'card-text-bg'},[_c('div',{staticClass:"d-flex flex-row"},[_c('div',[_c('div',{staticClass:"pa-4",attrs:{"elevation":"0"}},[_c('div',{staticClass:"item-title-font"},[_vm._v(_vm._s(title))]),_c('div',{class:technologies.length ? 'my-1' : ''},[_c('div',{staticClass:"d-flex flex-row"},[(isWebFrontend)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-icon',{staticClass:"mr-2",attrs:{"color":isWebFrontend ? 'green' : ''}},[_vm._v("mdi-application")])],1)]}}],null,true)},[_c('span',[_vm._v("WEB")])]):_vm._e(),(isMobileFrontend)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-icon',{staticClass:"mr-2",attrs:{"color":isMobileFrontend ? 'green' : ''}},[_vm._v("mdi-tablet-cellphone")])],1)]}}],null,true)},[_c('span',[_vm._v("Mobile")])]):_vm._e(),(isBackend)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-icon',{staticClass:"mr-2",attrs:{"color":isBackend ? 'green' : ''}},[_vm._v("mdi-application-cog")])],1)]}}],null,true)},[_c('span',[_vm._v("Backend")])]):_vm._e(),(isDatabase)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"green"}},[_vm._v("mdi-database")])],1)]}}],null,true)},[_c('span',[_vm._v("Database")])]):_vm._e()],1)]),(technologies.length)?_c('div',{staticClass:"my-auto"},_vm._l((technologies),function(ref,i){
      var technologyName = ref.technologyName;
      var description = ref.description;
return _c('v-chip',{key:i,staticClass:"mr-2 mb-2",attrs:{"small":"","label":"","color":"rgb(234 237 245)"}},[(description)?_c('v-tooltip',{attrs:{"max-width":"300px","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_vm._v(" "+_vm._s(technologyName)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(description))])]):_vm._e()],1)}),1):_vm._e()])]),_c('div',{staticClass:"d-flex flex-row ml-auto my-auto"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('AddProjects',{attrs:{"id":id,"edit":true}})],1)]}}],null,true)},[_c('span',[_vm._v("Edit project")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-btn',{staticClass:"color-red",attrs:{"icon":""},on:{"click":function($event){return _vm.deleteAlert(id)}}},[_c('v-icon',[_vm._v("mdi-delete-empty-outline")])],1)],1)]}}],null,true)},[_c('span',[_vm._v("Delete project")])])],1)])])}),1):_c('v-card-text',{staticClass:"black--text mt-5 pl-7"},[_c('div',{staticClass:"red--text"},[_vm._v("No data found!")]),_c('div',{staticClass:"item-description-font"},[_vm._v("Try to create project.")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }