<template>
  <v-card elevation="2">
    <v-card-title class="card-title-style"
      >{{ expansionHeader }}
      <div class="ml-auto">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <AddProjects />
            </div>
          </template>
          <span>Add project</span>
        </v-tooltip>
      </div>
    </v-card-title>
    <div v-if="this.$store.state.projects.length">
      <v-card-text
        class="px-3 py-0 black--text "
        v-for="({
          id,
          title,
          isWebFrontend,
          isMobileFrontend,
          isBackend,
          isDatabase,
          technologies,
        },
        index) in expansionPanelContent"
        :key="index"
        :class="index % 2 === 0 ? '' : 'card-text-bg'"
      >
        <div class="d-flex flex-row">
          <div>
            <div elevation="0" class="pa-4">
              <div class="item-title-font">{{ title }}</div>
              <div :class="technologies.length ? 'my-1' : ''">
                <div class="d-flex flex-row">
                  <v-tooltip v-if="isWebFrontend" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on">
                        <v-icon
                          :color="isWebFrontend ? 'green' : ''"
                          class="mr-2"
                          >mdi-application</v-icon
                        >
                      </div>
                    </template>
                    <span>WEB</span>
                  </v-tooltip>
                  <v-tooltip v-if="isMobileFrontend" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on">
                        <v-icon
                          :color="isMobileFrontend ? 'green' : ''"
                          class="mr-2"
                          >mdi-tablet-cellphone</v-icon
                        >
                      </div>
                    </template>
                    <span>Mobile</span>
                  </v-tooltip>
                  <v-tooltip v-if="isBackend" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on">
                        <v-icon :color="isBackend ? 'green' : ''" class="mr-2"
                          >mdi-application-cog</v-icon
                        >
                      </div>
                    </template>
                    <span>Backend</span>
                  </v-tooltip>
                  <v-tooltip v-if="isDatabase" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on">
                        <v-icon color="green" class="mr-2">mdi-database</v-icon>
                      </div>
                    </template>
                    <span>Database</span>
                  </v-tooltip>
                </div>
              </div>

              <div class="my-auto" v-if="technologies.length">
                <v-chip
                  small
                  v-for="({ technologyName, description }, i) in technologies"
                  :key="i"
                  class="mr-2 mb-2"
                  label
                  color="rgb(234 237 245)"
                >
                  <v-tooltip v-if="description" max-width="300px" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on">
                        {{ technologyName }}
                      </div>
                    </template>
                    <span>{{ description }}</span>
                  </v-tooltip>
                </v-chip>
              </div>
            </div>
          </div>
          <div class="d-flex flex-row ml-auto my-auto">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <AddProjects :id="id" :edit="true" />
                </div>
              </template>
              <span>Edit project</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <v-btn class="color-red" @click="deleteAlert(id)" icon>
                    <v-icon>mdi-delete-empty-outline</v-icon>
                  </v-btn>
                </div>
              </template>
              <span>Delete project</span>
            </v-tooltip>
          </div>
        </div>
      </v-card-text>
    </div>
    <v-card-text v-else class="black--text mt-5 pl-7">
      <div class="red--text">No data found!</div>
      <div class="item-description-font">Try to create project.</div>
    </v-card-text>
  </v-card>
</template>

<script>
import AddProjects from "./Actions/AddProjects";
import axiosInstance from "@/axios";
import _ from "lodash";
export default {
  data: () => ({
    expansionHeader: "Projects",
  }),
  computed: {
    expansionPanelContent() {
      return this.$store.state.projects;
    },
  },
  components: {
    AddProjects,
  },
  methods: {
    isEmptyObj: function() {
      if (this.expansionPanelContent) {
        let isEmpty = _.isEmpty(this.expansionPanelContent);
        return !isEmpty;
      }
      return null;
    },

    deleteAlert(projectId) {
      this.$swal({
        title: "Do you want to delete this project?",
        showDenyButton: true,
        confirmButtonText: `Yes`,
        denyButtonText: `No`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteProjects(projectId);
        } else if (result.isDenied) {
          return;
        }
      });
    },
    async deleteProjects(projectId) {
      try {
        await axiosInstance.delete(
          `/proposal/${this.$route.params.id}/Project/${projectId}`
        );
        await this.$store.dispatch("getSummaryData", this.$route.params.id);
        this.$store.dispatch("getCurrentProposalData", this.$route.params.id);
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>

<style scoped>
.block-title {
  display: inline-block;
}
</style>
