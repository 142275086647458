var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('div',[_c('v-card-title',{staticClass:"card-title-style",on:{"click":_vm.refreshData}},[_vm._v(" "+_vm._s(_vm.expansionHeader)+" "),_c('div',{staticClass:"ml-auto"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('AddProposal',{attrs:{"refreshData":_vm.refreshData}})],1)]}}])},[_c('span',[_vm._v("Add proposal")])])],1)]),(_vm.isEmptyObj)?_c('v-card-text',{staticClass:"mt-3"},_vm._l((_vm.expansionPanelContent),function(ref,index){
        var id = ref.id;
        var title = ref.title;
        var createdByUser = ref.createdByUser;
        var completness = ref.completness;
        var technologies = ref.technologies;
        var leadTitle = ref.leadTitle;
        var leadId = ref.leadId;
        var proposalType = ref.proposalType;
return _c('div',{key:index},[_c('div',{staticClass:"d-flex flex-row"},[_c('div',{staticClass:"flex-grow-1"},[_c('v-card',{attrs:{"elevation":"0"}},[_c('v-col',{attrs:{"cols":"3"}},[(index)?_c('v-divider'):_vm._e()],1),(proposalType)?_c('div',{staticClass:"d-flex mx-4 mb-2"},[_c('Label',{attrs:{"text":_vm.proposalTypeTitle(proposalType),"customStyles":_vm.labelStyles,"customClass":'default'}})],1):_vm._e(),_c('div',{staticClass:"d-flex align-center pt-0 mx-4 item-title-font"},[_c('a',{staticClass:"black--text",attrs:{"href":("/proposals/" + id)}},[_vm._v(_vm._s(title))])]),_c('div',{staticClass:"item-description-font px-4 pt-1"},[_vm._v(" "+_vm._s(createdByUser)+" ")]),(technologies.length)?_c('div',{staticClass:"px-4 py-2"},_vm._l((technologies),function(ref,i){
        var technologyName = ref.technologyName;
        var description = ref.description;
return _c('v-chip',{key:i,staticClass:"mr-2",attrs:{"small":"","color":"rgb(234 237 245)"}},[(description)?_c('v-tooltip',{attrs:{"max-width":"300px","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_vm._v(" "+_vm._s(technologyName)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(description))])]):_vm._e()],1)}),1):_vm._e()],1)],1),_c('div',{staticClass:"ml-auto d-flex flex-row  align-center"},[_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-progress-circular',{attrs:{"rotate":360,"size":35,"width":5,"value":completness,"color":"#92278e"}},[_vm._v(" "+_vm._s(completness)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Сompletness")])])],1),_c('CardMenu',{attrs:{"id":id,"proposalTitle":title,"leadTitle":leadTitle,"leadId":leadId,"singleEntityName":_vm.singleEntityName,"menuOptions":_vm.menuOptions,"reloadData":_vm.refreshData,"isLeadSelectionEnabled":false}})],1)])])}),0):_c('v-card-text',{staticClass:"mt-5"},[_c('div',{staticClass:"px-4"},[_c('div',{staticStyle:{"color":"red"}},[_vm._v("No data found!")]),_c('div',{staticClass:"item-description-font"},[_vm._v("You can add proposals.")])])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }