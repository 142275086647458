<template>
  <v-row no-gutters>
    <v-col class="group-column mx-3" v-for="group in groups" :key="group.id">
      <div
        :class="
          windowTop > 136 ? 'group-header group-header-sticky' : 'group-header'
        "
      >
        <h2>{{ group.name }}</h2>
      </div>
      <draggable
        v-model="group.items"
        :animation="200"
        style="height: 100%"
        ghost-class="moving-card"
        group="leads"
        @change="onGroupsChange($event, group.status, group)"
      >
        <div
          class="drag-item"
          v-for="({
            id,
            title,
            organization,
            creationDate,
            assigneeInitials,
            assigneeName,
            dueDate,
            overdue,
          },
          i) in group.items"
          @click="$router.push(`/leads/${id}`)"
          :key="i"
        >
          <div class="card-container">
            <div class="lead-title">{{ title }}</div>
            <div class="lead-organization">{{ organization }}</div>
            <div class="lead-creation-date">{{ creationDate }}</div>

            <div class="icon-initials-container">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    <div v-if="overdue">
                      <i class="fas fa-bell bell-icon" />
                    </div>
                  </div>
                </template>
                <span
                  >Ticket is overdue on {{ filterDate(dueDate) }}.
                  <div>Please execute it immediately</div>
                </span>
              </v-tooltip>

              <v-tooltip right v-if="assigneeInitials">
                <template v-slot:activator="{ on, attrs }">
                  <div
                    class="lead-assignee-initials-container"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <div class="lead-assignee-initials">
                      {{ assigneeInitials }}
                    </div>
                  </div>
                </template>
                <div>
                  <span> {{ assigneeName }} </span>
                </div>
              </v-tooltip>
            </div>
          </div>

          <CardMenu
            :id="id"
            :entitiesName="entitiesName"
            :singleEntityName="singleEntityName"
            :reloadData="reloadData"
            :menuOptions="menuOptions"
          />
        </div>
      </draggable>
    </v-col>
  </v-row>
</template>

<script>
import draggable from "vuedraggable";
//import { config } from "../../../config";
import CardMenu from "./CardMenu";
import axiosInstance from "@/axios";
export default {
  data: () => ({
    windowTop: 0,
    entitiesName: "leads",
    singleEntityName: "lead",
  }),
  props: {
    groups: Array,
    groupOriginal: Array,
    reloadData: Function,
  },
  components: {
    draggable,
    CardMenu,
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  },
  computed: {
    archiveMenuItem() {
      return this.$store.state.filterType === 1
        ? { title: "Archive", methodName: "archiveItem", icon: "fas fa-archive" }
        : { title: "Unarchive", methodName: "unarchiveItem", icon: "fas fa-archive" };
    },
    menuOptions() {
      return [
        // { title: "Open", methodName: "openItemPage", icon: "fas fa-eye" },
        {
          title: "Get link",
          icon: "fas fa-link",
          getLink: true,
        },
        this.archiveMenuItem,
        { title: "Delete", methodName: "deleteItem", icon: "fas fa-trash-alt" },
      ];
    },
  },
  methods: {
    onScroll() {
      this.windowTop = window.top.scrollY;
    },
    filterDate(creationDate) {
      let newDate = new Date(creationDate.split(".").shift() + "Z");

      let date = newDate.toLocaleString().split(",");

      let result = date.shift();
      return result;
    },
    async onGroupsChange(evt, status) {
      if ("moved" in evt) {
        this.changeCoordinates(evt, "moved", status);
      }
      if ("added" in evt) {
        this.changeCoordinates(evt, "added", status);
      }
    },
    calculatingLength() {
      if (this.$store.state.filter) {
        let state = this.$store.state;

        this.$props.groupOriginal.forEach(function(group) {
          group.items = group.items.filter((item) => {
            if (item.assigneeName !== state.filterName || !item.assigneeName) {
              return item;
            }
          });
          return group;
        });
      }
    },
    async changeCoordinates(evt, name, status) {
      this.calculatingLength();
      let column = this.$props.groupOriginal.find((el) => el.status == status);
      let obj = {};
      if (this.$store.state.filter) {
        let filterIndex = column.items.length + evt[name].newIndex;
        obj = {
          status: status,
          index: filterIndex,
        };
      } else {
        obj = {
          status: status,
          index: evt[name].newIndex,
        };
      }
      let id = evt[name].element.id;

      try {
        await axiosInstance.put(`/Lead/${id}/coordinates`, obj);
        if (!this.$store.state.filter) {
          this.$props.reloadData();
        }
      } catch (e) {
        console.log(e.message);
      }
    },
  },
};
</script>

<style scoped>
.group-header {
  display: flex;
  position: sticky;
  top: 0;
  background-color: #f4f5f7;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10px;
  min-height: 80px;
  font-size: 0.6rem;
  color: rgb(94, 108, 132);
  text-transform: uppercase;
  font-weight: 600;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  z-index: 1;
}

.group-header-sticky {
  border-bottom: 3px solid #cecece80;
}

.moving-card {
  opacity: 0.5;
  background: #f4f5f7;
  border: 1px solid blue;
}

.group-column {
  border-radius: 6px;
  background: #f4f5f7;
}

.drag-item:focus {
  outline: none;
}

.drag-item {
  cursor: pointer;
  border-radius: 5px;
  margin: 10px;
  max-height: fit-content;
  background-color: white;
  transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  display: flex;
  justify-content: space-between;
}

.drag-item:hover {
  background-color: #eaebee;
  -webkit-box-shadow: 4px 4px 6px 2px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 4px 4px 6px 2px rgba(34, 60, 80, 0.2);
  box-shadow: 4px 4px 6px 2px rgba(34, 60, 80, 0.2);
}

.card-container {
  display: flex;
  flex-direction: column;
  padding: 7px 0 7px 7px;
  row-gap: 7px;
}

.icon-initials-container {
  margin-top: 5px;
  display: flex;
  align-items: center;
}

.lead-title {
  font-size: 12px;
  font-weight: 500;
}

.lead-organization,
.lead-creation-date {
  font-size: 10px;
}

.lead-assignee-initials {
  font-size: 12px;
  border: 1px solid black;
  border-radius: 50%;
  width: 27px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bell-icon {
  color: orangered;
  margin-right: 10px;
}

.drag-header-more {
  cursor: pointer;
}
</style>
