<template>
  <span :style="customStyles" :class="customClass">{{ text }}</span>
</template>

<script>

export default {
  props: {
    text: String,
    customStyles: Object,
    customClass: String,
  },
};
</script>
<style scoped>
span {
  font-size: 20px;
  text-transform: capitalize;
  font-weight: 500;
  border: 4px solid;
  border-radius: 12px;
  padding: 2px 10px;
}
.default {
  color: rgb(146, 39, 142);
  border-color: rgb(146, 39, 142);
}
.archived {
  color: rgb(201, 12, 12);
  border-color: rgb(201, 12, 12);
}
.deleted {
  color: rgb(84, 84, 84);
  border-color: rgb(84, 84, 84);
}
</style>
