<template>
  <v-card elevation="2">
    <v-card-title class="card-title-style justify-space-between"
      >{{ expansionHeader }}
    </v-card-title>
    <div>
      <div
        v-for="(item, i) in fullData"
        :key="i"
        :class="i % 2 === 0 ? 'px-3' : 'px-3 card-text-bg'"
      >
        <div class="block-style">
          <div class="item-title-font">{{ item.title }}</div>
          <v-select
            v-if="item.inputType === 'select'"
            hide-details
            :items="item.options"
            label="please select"
            dense
            solo
            :value="item.value"
            class="select item-description-font"
            @change="(value) => handleChange(value, item.serverRoute)"
          />
          <v-text-field
            v-else-if="item.inputType === 'input'"
            hide-details
            label="please enter"
            :rules="[rules.discountValue]"
            dense
            solo
            :value="item.value"
            class="select item-description-font"
            @change="(value) => handleInput(value, item.serverRoute)"
          />
        </div>
      </div>
    </div>
  </v-card>
</template>
<script>
import axiosInstance from "@/axios";

export default {
  data: () => ({
    expansionHeader: "Payment terms",
    cooperationModelData: {
      title: "Cooperation model",
      inputType: "select",
      options: [
        { value: 1, text: "Fixed contract/fixed bid" },
        { value: 2, text: "T&M (time and materials)" },
        { value: 3, text: "Dedicated team" },
      ],
      serverRoute: "cooperation/model",
    },
    prepaymentPercentData: {
      title: "Prepayment",
      inputType: "select",
      options: [
        { value: 10, text: "10%" },
        { value: 15, text: "15%" },
        { value: 20, text: "20%" },
        { value: 25, text: "25%" },
        { value: 30, text: "30%" },
        { value: 33, text: "33%" },
        { value: 50, text: "50%" },
        { value: 66, text: "66%" },
        { value: 75, text: "75%" },
        { value: 90, text: "90%" },
        { value: 100, text: "100%" },
      ],
      serverRoute: "prepayment/percent",
    },
    paymentScheduleData: {
      title: "Payments",
      inputType: "select",
      options: [
        { value: 1, text: "Monthly" },
        { value: 2, text: "Biweekly" },
        { value: 3, text: "By milestone" },
        { value: 4, text: "Weekly" },
      ],
      serverRoute: "payment/schedule",
    },
    discountPercentData: {
      title: "Discount %",
      inputType: "input",
      serverRoute: "payment/discount",
    },
    rules: {
      discountValue: (v) => (v >= 0 && v <= 100) || "Discount must be between 0 and 100",
    },
  }),
  methods: {
    async handleInput(value, route) {
      value = value.replace(/,/g, '.');

      value = value < 0 ? 0 : value > 100 ? 100 : value;

      await this.handleChange(value, route);
    },
    async handleChange(value, route) {
      try {
        await axiosInstance.put(
          `/proposal/${this.$route.params.id}/SingleValue/${route}/${value}`,
          {},
          {
            headers: {
              "Content-Type": "application/problem+json; charset=utf-8",
            },
          }
        );
        await this.$store.dispatch("getSummaryData", this.$route.params.id);
        this.$store.dispatch("getCurrentProposalData", this.$route.params.id);
      } catch (e) {
        console.log(e);
      }
    },
  },
  computed: {
    fullData() {
      let tempData = [];
      tempData[0] = this.$data.cooperationModelData;
      tempData[0].value = this.$store.state.singleValue.cooperationModel;
      tempData[1] = this.$data.prepaymentPercentData;
      tempData[1].value = this.$store.state.singleValue.prepaymentPercent;
      tempData[2] = this.$data.paymentScheduleData;
      tempData[2].value = this.$store.state.singleValue.paymentSchedule;
      tempData[3] = this.$data.discountPercentData;
      tempData[3].value = this.$store.state.singleValue.discountPercent;
      return tempData;
    },
  },
};
</script>
<style scoped>
.block-style {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  /* max-width: 800px; */
}
.select {
  max-width: 200px;
}
</style>
