<template>
  <v-card>
    <v-card-text class="pt-4 px-6">
      <div>
        <div>
          <div class="d-flex justify-space-between py-2">
            <v-dialog v-model="dialog" max-width="700px" persistent>
              <template v-slot:activator="{ on, attrs }">
                <div class="d-flex justify-space-between">
                  <div class="d-flex justify-center pb-4">
                    <!-- <v-tooltip bottom>
                <template
                  v-slot:activator="{
                    on: autogenerateOn,
                    attrs: autogenerateAttributes,
                  }"
                >
                  <div v-bind="autogenerateAttributes" v-on="autogenerateOn">
                    <v-btn color="primary" @click="autofill">
                      <v-icon class="px-2">mdi-auto-download</v-icon>
                      Auto
                    </v-btn>
                  </div></template
                >
                Automatically fill all settings from template</v-tooltip
              > -->
                  </div>
                  <v-tooltip bottom>
                    <template
                      v-slot:activator="{
                        on: buildEnable,
                        attrs: buildAttributes,
                      }"
                    >
                      <div v-bind="buildAttributes" v-on="buildEnable">
                        <v-btn
                          depressed
                          v-bind="attrs"
                          v-on="on"
                          class="build-button"
                          @click="buildProposal"
                          width="155px"
                        >
                          <v-icon left>
                            mdi-download
                          </v-icon>
                          Get proposal
                        </v-btn>
                      </div></template
                    >Build proposal document</v-tooltip
                  >
                </div>
              </template>
              <v-card>
                <div v-if="isBuilding">
                  <div class="d-flex align-center justify-center">
                    <v-card-title
                      >Please wait for building proposal</v-card-title
                    >
                    <v-progress-circular
                      :size="30"
                      color="primary"
                      indeterminate
                    ></v-progress-circular>
                  </div>
                  <v-card-subtitle class="d-flex justify-center"
                    >it may take up to 60 seconds</v-card-subtitle
                  >
                </div>

                <div v-if="!isBuilding && !errorOnBuild">
                  <div class="d-flex align-center justify-center">
                    <v-card-title>Proposal built</v-card-title>
                    <v-icon :size="30" color="green">mdi-check</v-icon>
                  </div>
                  <div>
                    <v-card-text class="d-flex justify-center pb-1 pt-1 px-4">
                      <div class="link-box">
                        <a
                          :href="`${googleDriveLink}${documentId}`"
                          target="_blank"
                        >
                          <div>
                            {{ `${googleDriveLink}${documentId}` }}
                          </div>
                        </a>
                      </div>

                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <div>
                            <v-btn
                              color="rgba(224, 227, 235, 0.3)"
                              v-bind="attrs"
                              elevation="0"
                              v-on="on"
                              class="px-2 ml-2 copy-button"
                              @click="copyDocumentLink"
                              @mouseleave="copyText = 'Copy'"
                            >
                              <v-icon color="rgb(66, 82, 110)"
                                >mdi-content-copy</v-icon
                              >
                            </v-btn>
                          </div>
                        </template>
                        <span>{{ copyText }}</span>
                      </v-tooltip>
                    </v-card-text>
                    <v-card-actions class="px-4">
                      <v-spacer></v-spacer>
                      <v-btn
                        color="primary"
                        min-width="0px"
                        class="px-0"
                        text
                        @click="dialog = false"
                      >
                        Close
                      </v-btn>
                    </v-card-actions>
                  </div>
                </div>

                <div v-if="!isBuilding && errorOnBuild">
                  <div class="d-flex align-center justify-center">
                    <v-card-title
                      >Something went wrong during building :(</v-card-title
                    >
                  </div>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDialog">
                      Close
                    </v-btn>
                  </v-card-actions>
                </div>
              </v-card>
            </v-dialog>
            <v-tooltip bottom>
              <template
                v-slot:activator="{
                  on,
                  attrs,
                }"
              >
                <v-progress-circular
                  v-bind="attrs"
                  v-on="on"
                  :rotate="-90"
                  :size="35"
                  :width="5"
                  :value="Math.ceil(completness)"
                  color="#92278e"
                >
                  {{ completness }}
                </v-progress-circular></template
              >Completness</v-tooltip
            >
          </div>
          <div class="d-flex justify-space-between align-center py-3">
            <v-autocomplete
              attach
              :items="leads"
              placeholder="Assign lead"
              dense
              hide-details
              :value="assignedLead.id"
              @change="handleLeadSelecting"
              class="select-lead"
              style="width: 50%"
            ></v-autocomplete>
            <div class="back-to-lead">
              <router-link v-if="assignedLead.id" :to="'/leads/' + assignedLead.id">
                <v-btn class="global-button-vuetify" icon>
                  <v-icon color="#92278e">mdi-arrow-right-bold</v-icon>
                </v-btn>
              </router-link>
              <v-btn v-else class="global-button-vuetify" icon disabled>
                <v-icon color="#92278e">mdi-arrow-right-bold</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
      </div>

      <div class="py-1">
        <div class="py-2">
          <div>Error list</div>
          <div class="errors">
            <div v-if="errors.length">
              <div v-for="(item, index) in errors" :key="index">
                <div class="d-flex">
                  <v-icon small :class="setErrorIconColor(item.level)"
                    >mdi-circle-medium</v-icon
                  >
                  <div :class="setErrorColor(item.level)">{{ item.text }}</div>
                </div>
              </div>
            </div>
            <div v-if="!errors.length" class="no-errors">No errors</div>
          </div>
        </div>
        <div class="py-1">Summary</div>
        <div class="py-1 summary">
          <div
            v-for="({ title, isSummary, hours, price }, index) in durations"
            :key="index"
          >
            <div class="d-flex justify-space-between">
              <div :class="setDurationColor(isSummary)">{{ title }}</div>
              <div class="d-flex">
                <div class="px-2 summary-info">{{ hours }} hr</div>
                <div class="summary-info">${{ price.toFixed(2) }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="py-1">
        <div class="d-flex align-center justify-space-between">
          <div>History</div>
          <v-btn class="global-button-vuetify" icon @click="getHistory">
            <v-icon color="#92278e">mdi-autorenew</v-icon>
          </v-btn>
        </div>
        <div class="history">
          <div v-for="(item, index) in proposalHistory" :key="index">
            <v-list class="pb-0  px-1">
              <template>
                <v-list-item class="pa-0" :key="index">
                  <v-list-item-content class="py-0">
                    <v-list-item-title
                      style="font-size: 14px"
                      v-html="item.message"
                    ></v-list-item-title>
                    <v-list-item-subtitle
                      style="font-size: 12px"
                      v-html="filterDate(item.date) + ' | ' + item.user"
                    ></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list>
          </div>
        </div>
      </div>

      <div @click="deleteProposal()" class="delete-proposal pt-1">
        <v-btn text color="red" small>
          Delete proposal
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import axiosInstance from "@/axios";

export default {
  data: () => ({
    leads: [],
    dialog: false,
    assignDialog: false,
    isBuilding: false,
    errorOnBuild: false,
    googleDriveLink: "https://docs.google.com/document/d/",
    documentId: "",
    copyText: "Copy",
  }),
  async mounted() {
    this.getHistory();
  },

  methods: {
    async getLeads() {
      try {
        const { 
          data: { data },
        } = await axiosInstance.get(`/lead?filter=1`, {});

        const leads = data.map(i => ({ text: i.title, value: i.id }));

        if (this.$store.state.assignedLeadToProposal?.state === 2) {
          leads.unshift({
            text: this.$store.state.assignedLeadToProposal.title,
            value: this.$store.state.assignedLeadToProposal.id,
          });
        }

        this.$data.leads = leads;
      } catch (e) {
        console.log(e.message);
      }
    },
    deleteProposal() {
      this.$swal({
        title: "Are you sure about that?",
        showDenyButton: true,
        confirmButtonText: `Yes`,
        denyButtonText: `No`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteProposalFromServer();
        } else if (result.isDenied) {
          return;
        }
      });
    },
    async deleteProposalFromServer() {
      const proposalId = this.$route.params.id;
      this.$router.go(-1);
      try {
        await axiosInstance.delete(`/proposal/${proposalId}`, {
          headers: {},
        });
      } catch (e) {
        console.log(e);
      }
    },
    async handleLeadSelecting(value) {
      const proposalId = this.$route.params.id;
      try {
        await axiosInstance.put(`/proposal/${proposalId}/lead/${value}`, {});
        await this.$store.dispatch("getSummaryData", this.$route.params.id);
        this.$store.dispatch("getCurrentProposalData", this.$route.params.id);
      } catch (e) {
        console.log(e);
      }
    },
    async getHistory() {
      const proposalId = this.$route.params.id;
      this.$store.dispatch("getHistory", {
        id: proposalId,
        entityName: "proposal",
      });
    },
    async autofill() {
      await this.$store.dispatch("getSQAAutoData", this.$route.params.id);
      await this.$store.dispatch(
        "getDeliverablesAutoData",
        this.$route.params.id
      );
      this.$store.dispatch("getCurrentProposalData", this.$route.params.id);
    },
    async buildProposal() {
      try {
        this.$data.isBuilding = true;
        const { data } = await axiosInstance.post(
          `/proposal/${this.$route.params.id}/document`,
          {}
        );
        this.$data.documentId = data.data;
        this.$data.isBuilding = false;
      } catch (e) {
        this.$data.errorOnBuild = true;
        this.$data.isBuilding = false;
        console.log(e);
      }
    },
    closeDialog() {
      this.$data.dialog = false;
      this.$data.errorOnBuild = false;
    },
    copyDocumentLink() {
      navigator.clipboard.writeText(
        `${this.$data.googleDriveLink}/${this.$data.documentId}`
      );
      this.$data.copyText = "Copied to clipboard";
    },
    setErrorColor(level) {
      if (level === 1) return "error-level-1";
      return "error-level-2";
    },
    setErrorIconColor(level) {
      if (level === 1) return "error-icon-level-1";
      if (level === 2) return "error-icon-level-2";
    },
    setDurationColor(summary) {
      if (summary) {
        return "summary-true";
      }
      return "summary-false";
    },
    filterDate(creationDate) {
      let newDate = new Date(creationDate.split(".").shift() + "Z");

      let date = newDate.toLocaleString().split(",");
      let time = date.pop();

      let result = date.shift() + " | " + time;
      return result;
    },
  },
  computed: {
    completness() {
      return this.$store.state.completness;
    },
    durations() {
      return this.$store.state.durations;
    },
    errors() {
      return this.$store.state.errors;
    },
    assignedLead() {
      const assignedLeadData = this.$store.state.assignedLeadToProposal || { id: null, title: "No assigned lead" };
    
      this.getLeads();
      
      return assignedLeadData;
    },
    proposalHistory() {
      return this.$store.state.history.proposal;
    },
  },
};
</script>

<style scoped>
.assigned-lead {
  color: #1976d2;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;
}
.no-assigned-lead {
  color: #f44336;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
}
.build-button {
  background-color: rgb(76, 175, 80) !important;
  color: #ffffff !important;
}

.delete-proposal {
  color: black;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
}
.link-block {
  padding: 10px 0;
  display: flex;
  justify-content: center;
}

.link-box {
  display: flex;
  justify-content: center;
  border-color: rgba(224, 227, 235, 0.7);
  padding: 10px;
  border-radius: 3px;
  border-width: 2px;
  border-style: solid;
  background-color: rgba(224, 227, 235, 0.3);
  height: fit-content;
}
.copy-link-button {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}
.errors {
  padding: 5px 0;
  height: 100px;
  overflow-y: scroll;
  color: black;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.no-errors {
  font-weight: 500;
  font-size: 12px;
  padding: 0 5px;
}
.error-level-1 {
  font-size: 12px;
  font-weight: 600;
  padding: 0 5px;
}
.error-level-2 {
  font-weight: 300;
  font-size: 12px;
  padding: 0 5px;
}
.error-icon-level-1 {
  height: 20px;
  color: #f44336;
}
.error-icon-level-2 {
  height: 20px;
  color: #ffeb3b;
}
.error-icon-level-3 {
  height: 20px;
  color: rgb(76, 175, 80);
}
.summary {
  height: 100px;
  overflow-y: scroll;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  padding: 0 5px;
}
.summary-info {
  width: 80px;
}
.summary-true {
  font-weight: 600;
}
.summary-false {
  font-weight: 300;
}
.history {
  height: 140px;
  overflow-y: scroll;
  color: black;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.history-message {
  padding: 0 5px;
}
</style>
