var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"elevation":"2"}},[_c('v-card-title',{staticClass:"card-title-style"},[_vm._v(_vm._s(_vm.expansionHeader)+" "),_c('div',{staticClass:"ml-auto"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-btn',{attrs:{"icon":"","color":"#92278e"},on:{"click":function($event){return _vm.refreshLeadData(_vm.$route.params.id)}}},[(!_vm.isDataRefreshed)?_c('v-icon',[_vm._v("mdi-autorenew")]):_vm._e(),(_vm.isDataRefreshed)?_c('v-progress-circular',{attrs:{"width":2,"size":30,"color":"#92278e","indeterminate":""}}):_vm._e()],1)],1)]}}])},[_c('span',[_vm._v("Updating info from Pipedrive")])])],1)]),(_vm.isEmptyObj)?_c('div',{staticClass:"pa-4"},[_c('v-card',{attrs:{"elevation":"0"}},[_c('div',{staticClass:"px-4 pt-4 d-flex align-center item-title-font"},[_vm._v(" "+_vm._s(_vm.expansionPanelContent.title)+" "),_c('div',{staticClass:"ml-auto types d-flex flex-row"},[(_vm.expansionPanelContent.industry)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"sub-card-title mx-3"},'div',attrs,false),on),[_vm._v(" "+_vm._s(_vm.expansionPanelContent.industry)+" ")])]}}],null,false,299630903)},[_c('span',[_vm._v("Industry")])]):_vm._e(),(_vm.expansionPanelContent.companyType)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"sub-card-title mx-3"},'div',attrs,false),on),[_vm._v(" "+_vm._s(_vm.expansionPanelContent.companyType)+" ")])]}}],null,false,3699997394)},[_c('span',[_vm._v("Company type")])]):_vm._e(),(_vm.expansionPanelContent.companySize)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"sub-card-title mx-3"},'div',attrs,false),on),[_vm._v(" "+_vm._s(_vm.expansionPanelContent.companySize)+" ")])]}}],null,false,849499663)},[_c('span',[_vm._v("Company size")])]):_vm._e()],1)]),_c('div',{staticClass:"item-description-font px-4 pb-4"},[(_vm.expansionPanelContent.name)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"d-inline-block"},'div',attrs,false),on),[_vm._v(" "+_vm._s(_vm.expansionPanelContent.name)+" ")])]}}],null,false,4086086869)},[_c('span',[_vm._v("Company name")])]):_vm._e()],1),_c('v-card-text',{staticClass:"black--text"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.withBrTags)}})]),_c('v-card-subtitle',{staticClass:"d-flex flex-row align-center"},[_c('div',[(_vm.expansionPanelContent.pipeDriveUrl)?_c('a',{staticClass:"links mr-1",class:_vm.expansionPanelContent.webSite ? 'links-border-right' : '',attrs:{"target":"_blank","href":("" + (_vm.expansionPanelContent.pipeDriveUrl))}},[_vm._v("Pipedrive")]):_vm._e(),(_vm.expansionPanelContent.webSite)?_c('a',{staticClass:"links mx-1",class:_vm.expansionPanelContent.socialNetwork ? 'links-border-right' : '',attrs:{"target":"_blank","href":("" + (_vm.expansionPanelContent.webSite.includes('\/')
                  ? _vm.expansionPanelContent.webSite.replaceAll('\/', '')
                  : 'https://' + _vm.expansionPanelContent.webSite))}},[_vm._v("Website")]):_vm._e(),(_vm.expansionPanelContent.socialNetwork)?_c('a',{staticClass:"links mx-1",attrs:{"target":"_blank","href":("" + (_vm.expansionPanelContent.socialNetwork.includes('\/')
                  ? _vm.expansionPanelContent.socialNetwork.replaceAll('\/', '')
                  : 'https://' + _vm.expansionPanelContent.socialNetwork))}},[_vm._v("Social network")]):_vm._e()]),_c('div',{staticClass:"ml-auto"},[(
              _vm.expansionPanelContent.address ||
                _vm.expansionPanelContent.country ||
                _vm.expansionPanelContent.city
            )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"d-flex align-center"},'div',attrs,false),on),[_c('div',{staticClass:"mr-1"},[_c('i',{staticClass:"fas fa-map-marker-alt"})]),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"mr-1 item-description-font"},[_vm._v(" "+_vm._s(_vm.expansionPanelContent.address ? _vm.expansionPanelContent.address + ", " : "")+" ")]),_c('div',{staticClass:"mr-1 item-description-font"},[_vm._v(" "+_vm._s(_vm.expansionPanelContent.country ? _vm.expansionPanelContent.country + ", " : "")+" ")]),_c('div',{staticClass:"mr-1 item-description-font"},[_vm._v(" "+_vm._s(_vm.expansionPanelContent.city)+" ")])])])]}}],null,false,1998201879)},[_c('div',[_vm._v(" Address ")])]):_vm._e()],1)])],1)],1):_c('v-card-text',{staticClass:"black--text mt-5"},[_c('div',{staticClass:"red--text"},[_vm._v("No data found!")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }