import axios from "axios";
import ls from "local-storage";

import { config } from "../config";
import router from "./router";
import store from "./store";

const axiosInstance = axios.create({
  baseURL: config.server_URL,
});

axiosInstance.interceptors.response.use(
  (response) => {
    //console.log(response);
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function(error) {
    console.log(error.response);
    if (error.response.status == 401) {
      logOut();
      return Promise.reject(new Error(error.response.statusText));
    }
    if (
      error.response.status == 404 &&
      error.response.config.method === "get"
    ) {
      router.push("/404");
      store.commit("handleError404", error.response.data.Message);
      return Promise.reject(new Error(error.response.statusText));
    }
    if (error.response.status == 400) {
      handleErrorPopup(error.response.data.title, 5000);
      return Promise.reject(new Error(error.response.statusText));
    }
    handleErrorPopup(error.response.data.Message, 5000);
    return Promise.reject(new Error(error.response.statusText));
  }
);
function logOut() {
  store.commit("setUserData", null);
  axiosInstance.defaults.headers.common["Authorization"] = "";
  ls.remove("userData");
  router.push("/login");
}
function handleErrorPopup(errorMessage, timeout) {
  store.commit("handleSnackbar", {
    status: true,
    message: errorMessage,
    timeout: timeout,
  });
  setTimeout(
    () =>
      store.commit("handleSnackbar", {
        status: false,
        message: "",
        timeout: null,
      }),
    timeout
  );
  return Promise.reject(new Error(errorMessage));
}

export default axiosInstance;
