<template>
  <div>
    <template>
      <v-dialog persistent v-model="dialog" max-width="700px">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            @click="editFill(), autoFillFields()"
            class="color-green"
            icon
          >
            <v-icon v-if="!edit" color="green">mdi-plus</v-icon>
            <v-icon v-else>mdi-pencil-outline</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span>Add team member</span>
          </v-card-title>
          <v-card-text class="pb-1">
            <v-row no-gutters>
              <v-col class="col-left">
                <v-form ref="validationRef">
                  <v-select
                    v-model="select"
                    :items="itemsDeveloper"
                    label="Select developer/tester"
                    :rules="[rules.required]"
                    item-text="value"
                    item-value="key"
                    class="mb-5"
                    dense
                    return-object
                  ></v-select>
                  <v-select
                    v-model="level"
                    :items="itemsLevel"
                    label="Seniority level"
                    item-text="value"
                    :rules="[rules.required]"
                    item-value="key"
                    class="mb-5"
                    dense
                    return-object
                  ></v-select>
                  <v-textarea
                    v-model="allocation"
                    label="Allocation %"
                    :rules="[rules.required, rules.allocateValue]"
                    auto-grow
                    class="mb-5"
                    rows="1"
                    row-height="15"
                    dense
                  ></v-textarea>
                  <div class="d-flex prices-gap">
                    <v-textarea
                      dense
                      label="Price/Hour $"
                      :rules="[rules.priceValue]"
                      auto-grow
                      class="mb-5"
                      rows="1"
                      row-height="15"
                      v-model="hourlyPrice"
                    ></v-textarea>
                    <v-textarea
                      dense
                      label="Price/Month $"
                      :rules="[rules.priceValue]"
                      auto-grow
                      class="mb-5"
                      rows="1"
                      row-height="15"
                      v-model="monthlyPrice"
                    ></v-textarea>
                  </div>
                </v-form>
                <v-combobox
                  append-icon=""
                  v-model="technologieSelect"
                  :items="technologies"
                  item-text="technologyName"
                  color="blue"
                  label="Technologies"
                  small-chips
                  hide-selected
                  multiple
                  :return-object="true"
                  :search-input.sync="technologyName"
                >
                  <template v-slot:append>
                    <AddTechnology
                      :key="technologyName"
                      v-bind:text="technologyName"
                      v-bind:addTechnologyLocal="addTechnologyFromDialog"
                    />
                  </template>
                  <template v-slot:no-data>
                    <v-list-item v-if="technologyName">
                      <span class="subheading ">
                        No technologie with name
                        <span class="red--text">"{{ technologyName }}"</span>
                        found!
                      </span>
                    </v-list-item>
                  </template>
                  <template v-slot:selection="{ attrs, item, parent, selected }">
                    <v-chip
                      small
                      v-if="item.technologyName"
                      v-bind="attrs"
                      :input-value="selected"
                      label
                      color="rgb(234 237 245)"
                    >
                      <span class="pr-5">
                        {{ item.technologyName }}
                      </span>
                      <v-icon color="red" small @click="parent.selectItem(item)">
                        mdi-minus
                      </v-icon>
                    </v-chip>
                  </template>
                </v-combobox>
              </v-col>
              <v-col class="col-right pl-5">
                <div class="d-flex align-center justify-end">
                  <span class="col-right_title mr-2 cv-title">CVs</span>
                  <v-btn @click="openCVModal" icon color="green">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                  <div class="empty-container"></div>
                </div>
                <v-list class="pt-0">
                  <v-list-item
                    v-for="(cv, index) in cvSelect"
                    :key="index"
                    class="pr-0 pl-0 col-right_title justify-end"
                  >
                    <span class="mr-2 cv-title">CV №{{ index + 1 }}</span>
                    <v-btn @click="removeCV(index)" icon color="red">
                      <v-icon>mdi-minus</v-icon>
                    </v-btn>
                    <v-btn @click="openCVInNewWindow(cv.cvLink)" icon color="primary" class="mr-1 ml-2">
                      <v-icon>mdi-open-in-new</v-icon>
                    </v-btn>
                  </v-list-item>
                </v-list>
                <v-dialog persistent v-model="cvDialog" max-width="500px" class="cv-modal">
                  <v-card>
                    <v-card-title>Add CV</v-card-title>
                    <v-card-text>
                      <v-text-field v-model="cvLink" label="Link" :rules="[rules.cvValue]"></v-text-field>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn text @click="closeCVModal">Close</v-btn>
                      <v-btn color="primary" @click="addCV">Add CV</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions v-if="!edit">
            <v-spacer></v-spacer>
            <v-btn text @click="clearAllData()">
              Close
            </v-btn>
            <v-btn color="primary" @click="addTeamMember()">
              Add
            </v-btn>
          </v-card-actions>
          <v-card-actions v-else>
            <v-spacer></v-spacer>
            <v-btn @click="clearAllData()" text>
              Close
            </v-btn>
            <v-btn color="primary" @click="editTeamMember()">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </div>
</template>

<script>
import axiosInstance from "@/axios";
import AddTechnology from "./AddTechnology";
import _ from "lodash";
export default {
  props: {
    edit: Boolean,
    items: Array,
    id: Number,
    refreshData: Function,
  },
  components: {
    AddTechnology,
  },
  data: () => ({
    dialog: false,
    itemsDeveloper: [],
    itemsLevel: [],
    rules: {
      required: (v) => !!v || "This field is required",
      allocateValue: (v) =>
        (v >= 1 && v <= 100) ||
        "Allocation can`t be greater than 100 or less than 1",
      priceValue: (v) => v >= 0 || "Price can`t be negative!",
      cvValue: (v) => v.startsWith('https://') || 'CV link must start with "https://".',
    },
    select: "",
    level: "",
    allocation: "",
    monthlyPrice: "",
    hourlyPrice: "",
    technologieSelect: [],
    technologies: [],
    technologyName: "",
    cvDialog: false,
    cvSelect: [],
    cvLink: "",
  }),
  watch: {
    technologyName: async function() {
      try {
        const { data } = await axiosInstance.get(
          `/Dictionary/technologies?search=${this.$data.technologyName}`
        );

        if (data) {
          this.$data.technologies = data.data;
        }
      } catch (err) {
        console.log(err);
      }
    },
  },
  methods: {
    addTechnologyFromDialog(elem) {
      this.technologieSelect.push(elem);
      this.technologyName = "";
    },
    editFill() {
      if (this.$props.edit) {
        this.autoFillFields();
        let currentItem = this.$props.items.find(
          (el) => el.id == this.$props.id
        );

        this.$data.itemsLevel.forEach((element) => {
          if (element.key === currentItem.level) {
            this.$data.level = element;
          }
        });

        this.$data.itemsDeveloper.forEach((element) => {
          if (element.key === currentItem.specialization) {
            this.$data.select = element;
          }
        });

        this.$data.allocation = currentItem.allocation;
        this.$data.monthlyPrice = currentItem.monthlyPayment;
        this.$data.hourlyPrice = currentItem.hourlyPayment;
        this.$data.technologieSelect = currentItem.technologies;
        this.$data.cvSelect = currentItem.cVs;
      }
    },
    autoFillFields() {
      let level = _.find(this.$store.state.lookups, function(o) {
        return o.lookupName.includes("TeamLevel");
      });

      let teamSpecialization = _.find(this.$store.state.lookups, function(o) {
        return o.lookupName.includes("TeamSpecialization");
      });

      this.$data.itemsLevel = level.values;
      this.$data.itemsDeveloper = teamSpecialization.values;

      if (!this.$props.edit) {
        this.$data.allocation = "100";
      }
    },
    clearAllData() {
      this.$data.select = "";
      this.$data.level = "";
      this.$data.allocation = "";
      this.$data.price = "";
      this.$data.dialog = false;
      this.$data.technologieSelect = [];
      this.$data.cvSelect = [];
      this.$refs.validationRef.reset();
    },
    async addTeamMember() {
      try {
        if (this.$refs.validationRef.validate()) {
          let obj = {
            level: this.$data.level.key,
            specialization: this.$data.select.key,
            allocation: Number(this.$data.allocation) || 100,
            hourlyPayment: Number(this.$data.hourlyPrice) | 0,
            monthlyPayment: Number(this.$data.monthlyPrice) | 0,
            technologies: this.$data.technologieSelect,
            cVs: this.$data.cvSelect,
          };

          await axiosInstance.post(
            `/proposal/${this.$route.params.id}/TeamMember`,
            obj,
            {
              headers: {},
            }
          );
          this.$props.refreshData();
          this.clearAllData();
          this.$store.dispatch("getSummaryData", this.$route.params.id);
        }
      } catch (e) {
        console.log(e);
      }
    },
    async editTeamMember() {
      try {
        if (this.$refs.validationRef.validate()) {
          this.dialog = false;
          let obj = {
            level: this.$data.level.key,
            specialization: this.$data.select.key,
            allocation: Number(this.$data.allocation),
            hourlyPayment: Number(this.$data.hourlyPrice),
            monthlyPayment: Number(this.$data.monthlyPrice),
            technologies: this.$data.technologieSelect,
            cVs: this.$data.cvSelect,
          };

          await axiosInstance.put(
            `/proposal/${this.$route.params.id}/TeamMember/${this.$props.id}`,
            obj,
            {
              headers: {},
            }
          );
          this.$props.refreshData();
          this.clearAllData();
          this.$store.dispatch("getSummaryData", this.$route.params.id);
        }
      } catch (e) {
        console.log(e);
      }
    },
    openCVModal() {
      this.cvDialog = true;
    },
    closeCVModal() {
      this.cvDialog = false;
      this.cvLink = "";
    },
    addCV() {
      if (this.cvLink) {
        this.cvSelect.push({ cvLink: this.cvLink });
        this.cvDialog = false;
        this.cvLink = "";
      }
    },
    removeCV(index) {
      this.cvSelect.splice(index, 1);
    },
    openCVInNewWindow(cvLink) {
      if (cvLink) {
        window.open(cvLink, '_blank');
      }
    },
  },
};
</script>

<style>
.col-left {
  flex-basis: 60%;
  max-width: 60%;
}

.col-right {
  flex-basis: 40%;
  max-width: 40%;
}

.col-right_title {
  font-size: 16px;
  line-height: 28px;
}

.cv-title {
  width: 50px !important;
}

.empty-container {
  flex-grow: 0.33 !important;
}

.prices-gap {
  gap: 20px;
}
</style>
