var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"elevation":"2"}},[_c('v-card-title',{staticClass:"card-title-style justify-space-between"},[_vm._v(_vm._s(_vm.expansionHeader)+" "),_c('div',{staticClass:"d-flex ml-auto"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$router.push(("/proposals/" + (_vm.$route.params.id) + "/milestones"))}}},[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-pencil-plus")])],1)],1)]}}])},[_c('span',[_vm._v("Open milestone detail page")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.autoFill()}}},[_c('v-icon',{attrs:{"color":"blue"}},[_vm._v(" mdi-auto-download")])],1)],1)]}}])},[_c('span',[_vm._v("Autofill milestones")])])],1)]),(_vm.isEmptyObj)?_c('v-card-text',{staticClass:" black--text px-0 pb-0"},_vm._l((_vm.expansionPanelContent),function(ref,index){
var id = ref.id;
var name = ref.name;
return _c('div',{key:index,staticClass:"px-3",class:index % 2 === 0 ? '' : 'card-text-bg'},[_c('div',{staticClass:"d-flex flex-row"},[_c('div',{staticClass:"pa-4"},[_c('div',{staticClass:"item-title-font"},[_vm._v(_vm._s(name))]),_c('div',{staticClass:"d-flex align-center mt-1",staticStyle:{"cursor":"pointer"},on:{"mouseover":function($event){_vm.showByIndex = index},"mouseout":function($event){_vm.showByIndex = null},"click":function($event){_vm.setAnchourPoint(("a-" + id)),
                  _vm.$router.push(("/proposals/" + (_vm.$route.params.id) + "/milestones"))}}},[(_vm.showByIndex !== index)?_c('v-icon',[_vm._v("mdi-folder-open")]):_c('v-icon',[_vm._v("mdi-folder-open-outline")]),_c('div',{staticClass:"ml-1 item-description-font"},[_vm._v("Tasks")])],1)]),_c('div',{staticClass:"ml-auto my-auto"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-btn',{staticClass:"color-red",attrs:{"icon":""},on:{"click":function($event){return _vm.deleteAlert(id)}}},[_c('v-icon',[_vm._v("mdi-delete-empty-outline")])],1)],1)]}}],null,true)},[_c('span',[_vm._v("Delete milestone")])])],1)])])}),0):_c('v-card-text',{staticClass:"black--text mt-5 pl-7"},[_c('div',{staticClass:"red--text"},[_vm._v("No data found!")]),_c('div',{staticClass:"item-description-font"},[_vm._v(" Try to create milestone on the "),_c('a',{attrs:{"href":("/proposals/" + (_vm.$route.params.id) + "/milestones")}},[_vm._v("detail page")]),_vm._v(", or use autofill. ")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }