<template>
  <v-dialog persistent v-model="dialog" max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="global-button-vuetify"
        icon
        color="green"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon color="green">mdi-plus</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">Add Comments</span>
      </v-card-title>
      <v-card-text>
        <v-row no-gutters>
          <v-col>
            <v-form ref="form">
              <v-textarea
                :rules="[rules.required]"
                label="Enter comment"
                v-model="text"
              >
              </v-textarea>
            </v-form>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="clearAllData()">
          Close
        </v-btn>
        <v-btn color="primary" @click="addLeadComments($route.params.id)">
          Add
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axiosInstance from "@/axios";
//import { config } from "../../../../config.js";
export default {
  props: {
    refreshData: Function,
  },
  data: () => ({
    text: "",
    dialog: false,
    questions: null,
    rules: {
      required: (v) => !!v || "This field is required",
    },
  }),
  methods: {
    clearAllData() {
      this.$data.dialog = false;
      this.$data.text = "";
      this.$refs.form.reset();
    },
    async addLeadComments(leadId) {
      if (this.$refs.form.validate()) {
        let obj = {
          message: this.$data.text,
        };
        try {
          await axiosInstance.post(`/lead/${leadId}/Comments`, obj, {
            headers: {},
          });
          this.$props.refreshData();
          this.clearAllData();
        } catch (e) {
          console.log(e);
        }
      }
    },
  },
};
</script>
