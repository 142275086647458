<template>
  <div>
    <v-dialog v-model="dialog" max-width="900px">
      <template v-slot:activator="{ on: dialogOn, attrs: dialogAttrs }">
        <v-tooltip style="z-index: 10;" bottom>
          <template v-slot:activator="{ on: tooltipOn, attrs: tooltipAttrs }">
            <div v-on="tooltipOn" v-bind="tooltipAttrs">
              <v-btn
                class="mx-3"
                fab
                dark
                small
                color="#92278e"
                v-on="dialogOn"
                v-bind="dialogAttrs"
                @click="getTemplates()"
              >
                <div>
                  <v-icon dark>mdi-book-open-blank-variant</v-icon>
                </div>
              </v-btn>
            </div></template
          >
          <span>Add architecture from template</span>
        </v-tooltip>
      </template>
      <v-card min-height="90vh">
        <v-card-title class="d-flex justify-space-between">
          <span class="headline">Architecture templates</span>
          <v-btn icon @click="dialog = false">
            <v-icon>fas fa-times</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-text-field
            prepend-inner-icon="mdi-magnify"
            label="Search"
            solo
            hide-details
            clearable
            class="py-6"
            v-model="searchData"
            @input="handleSearch"
          ></v-text-field>
          <div
            v-for="({ id, fileId, title, description }, i) in templates"
            :key="i"
            class="architecture-card"
          >
            <div class="d-flex justify-end px-2 py-2">
              <v-btn
                fab
                dark
                small
                color="green"
                @click="addTemplateToProposal(id)"
                ><v-icon dark>
                  {{ "mdi-plus" }}
                </v-icon></v-btn
              >
            </div>
            <div class="image">
              <iframe
                frameborder="0"
                style="height:240px;"
                :src="
                  `https://viewer.diagrams.net/?highlight=0000ff&nav=1#G${fileId}`
                "
              ></iframe>

              <div class="text">
                <div class="template-title">{{ title }}</div>
                <div class="template-description">{{ description }}</div>
              </div>
            </div>
          </div>
          <div class="load-more-button">
            <v-btn
              color="primary"
              @click="loadMoreCards"
              v-if="isLoadMoreActive"
              >Load more</v-btn
            >
          </div>
        </v-card-text>
      </v-card>
      <v-snackbar v-model="snackbar" color="green" centered timeout="3000">
        {{ text }}

        <template v-slot:action="{ attrs }">
          <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </v-dialog>
  </div>
</template>
<script>
//import { config } from "../../../../../config";
import axiosInstance from "@/axios";

export default {
  data: () => ({
    dialog: false,
    searchData: "",
    itemsPerPage: 3,
    page: 1,
    text: "Architecture was succesfully added to proposal",
    snackbar: false,
  }),
  computed: {
    templates() {
      return this.$store.state.architecturesList.templates;
    },
    params() {
      return {
        search: this.$data.searchData,
        pageNumber: this.$data.page,
        pageSize: this.$data.itemsPerPage,
      };
    },
    isLoadMoreActive() {
      return this.$store.state.architecturesList.isLoadMoreActive;
    },
  },
  methods: {
    async getTemplates() {
      this.$data.searchData = "";
      this.$data.page = 1;
      await this.$store.dispatch("getTemplatesList", this.params);
    },
    async handleSearch() {
      this.$data.page = 1;
      await this.$store.dispatch("getTemplatesList", this.params);
    },
    async loadMoreCards() {
      this.$data.page += 1;
      await this.$store.dispatch("getTemplatesList", this.params);
    },
    async addTemplateToProposal(id) {
      this.$data.snackbar = true;
      try {
        await axiosInstance.post(
          `/proposal/${this.$route.params.id}/Architecture/template/${id}`,
          {}
        );
        await this.$store.dispatch(
          "getArchitecturesList",
          this.$route.params.id
        );
        await this.$store.dispatch("getSummaryData", this.$route.params.id);
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>
<style scoped>
.architecture-card {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  margin: 15px 0;
  padding: 10px;
  border-radius: 4px;
}
.text {
  min-width: 460px;
  padding: 0 15px;
}
.load-more-button {
  display: flex;
  justify-content: center;
}
.image {
  display: flex;
  align-items: center;
  max-width: 300px;
}
.template-title {
  font-weight: 600;
  font-size: 22px;
}
.template-description {
  margin-top: 20px;
}
</style>
