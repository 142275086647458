import axiosInstance from "@/axios";
import _ from "lodash";
//import { config } from "../../config";

export default {
  state: {
    architectures: [],
    templates: [],
    isLoadMoreActive: false,
    componentKey: 0,
    errorPopup: { status: false, message: "asdasd", timeout: null },
  },
  mutations: {
    setArchitectures(state, data) {
      state.architectures = data;
    },
    setTemplates(state, data) {
      if (data.isFirstData) {
        state.templates = data.templates;
      } else {
        state.templates = [...state.templates, ...data.templates];
      }
    },
    setLoadMoreState(state, value) {
      state.isLoadMoreActive = value;
    },
    setComponentKey(state) {
      state.componentKey += 1;
    },
    handleNoDirection(state, data) {
      state.errorPopup = data;
    },
  },
  actions: {
    async getArchitecturesList({ commit }, proposalId) {
      try {
        let {
          data: { data },
        } = await axiosInstance.get(`/proposal/${proposalId}/Architecture`);
        // console.log(data);
        const sortedData = _.sortBy(data, (i) => i.positionIndex);
        commit("setArchitectures", sortedData);
      } catch (e) {
        console.log(e.message);
      }
    },
    async getTemplatesList({ commit }, queryParams) {
      try {
        let {
          data: { data },
        } = await axiosInstance.get(`/dictionary/architecture`, {
          params: queryParams,
        });
        if (queryParams.pageNumber === 1) {
          commit("setTemplates", { templates: data, isFirstData: true });
        } else {
          commit("setTemplates", { templates: data, isFirstData: false });
        }
        if (data.length < queryParams.pageSize) {
          commit("setLoadMoreState", false);
        } else {
          commit("setLoadMoreState", true);
        }
      } catch (e) {
        console.log(e.message);
      }
    },
    async autoGenerateArchitectures(
      { rootState, commit, dispatch },
      proposalId
    ) {
      const projects = rootState.projects;
      const isDirection = projects
        .map(function(x) {
          if (
            x.isBackend ||
            x.isDatabase ||
            x.isMobileFrontend ||
            x.isWebFrontend
          ) {
            return true;
          }
          return false;
        })
        .includes(true);

      if (isDirection) {
        try {
          await axiosInstance.post(
            `/proposal/${proposalId}/Architecture/auto`,
            {}
          );
          dispatch("getArchitecturesList", proposalId);
        } catch (e) {
          console.log(e);
        }
      } else {
        const timeout = 5000;
        commit("handleNoDirection", {
          status: true,
          message: "Choose direction in projects first",
          timeout: timeout,
        });
        setTimeout(
          () =>
            commit("handleNoDirection", {
              status: false,
              message: "",
              timeout: null,
            }),
          timeout
        );
      }
    },
  },
};
