<template>
  <div>
    <div class="d-flex" v-if="this.$store.state.taskDataSummary.length">
      <div class="pr-2">
        <BackToProposal />
      </div>
      <div class="task-edit">
        <div><TasksEdit /></div>
      </div>
      <div class="toolbar px-5">
        <ToolBar class="toolbar-stick" />
      </div>
    </div>
    <div v-else>
      <div class="full-block">
        <div class="went-wrong-message">Nothing was found :(</div>
        <div>
          <div class="mt-1 mb-2  error-tip my-auto">
            Try to create projects.
          </div>
        </div>
        <router-link
          :to="`/proposals/${this.$route.params.id}`"
          class="link-message"
          >return to proposal detailed page
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import BackToProposal from "@/components/ProposalDetailed/Actions/BackToProposal";
import TasksEdit from "../components/ProposalDetailed/Tasks/TasksEdit";
import ToolBar from "../components/ProposalDetailed/Tasks/TaskToolbar";
export default {
  components: {
    TasksEdit,
    ToolBar,
    BackToProposal,
  },
  created() {
    this.$store.dispatch("getSummaryTask", this.$route.params.id);
  },
};
</script>

<style scoped>
.task-edit {
  flex: 7;
}
.toolbar {
  flex: 5;
}

.error-tip {
  font-size: 21px;
}
.toolbar-stick {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  right: 1px;
}
.full-block {
  padding: 15% 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.went-wrong-message {
  font-size: 28px;
}
.link-message {
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
  color: #92278e;
  text-transform: uppercase;
}
.link-message:hover {
  opacity: 0.7;
}
</style>
