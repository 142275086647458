<template>
  <v-dialog v-model="dialog" max-width="600px" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="color-green global-button-vuetify"
        icon
        v-bind="attrs"
        v-on="on"
        @click="valueLocal = value"
      >
        <v-icon>mdi-pencil-outline</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">Edit</span>
      </v-card-title>
      <v-card-text>
        <v-row no-gutters>
          <v-col>
            <v-textarea
              label="Enter constraint answer"
              auto-grow
              rows="2"
              row-height="50"
              v-model="valueLocal"
            >
            </v-textarea>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="(dialog = false), (valueLocal = value)">
          Close
        </v-btn>
        <v-btn
          color="primary"
          @click="(dialog = false), editConstraints(), (valueLocal = value)"
        >
          Add
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axiosInstance from "@/axios";
//import { config } from "../../../../config.js";
export default {
  props: {
    refreshData: Function,
    value: String,
    id: Number,
  },
  data: () => ({
    valid: false,
    dialog: false,
    valueLocal: null,
  }),
  mounted() {
    this.$data.valueLocal = this.$props.value;
  },
  methods: {
    async editConstraints() {
      let obj = {
        text: this.$data.valueLocal,
      };
      try {
        await axiosInstance.put(
          `/lead/${this.$route.params.id}/Constraints/${this.$props.id}`,
          obj
        );
        this.$props.refreshData();
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>
