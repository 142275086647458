<template>
  <v-card elevation="2">
    <v-card-title class="card-title-style"
      >{{ expansionHeader }}
      <div class="ml-auto d-flex flex-row">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <AddRisk :refreshData="refreshData" />
            </div>
          </template>
          <span>Add risk</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <v-btn color="blue" @click="autoFill" icon>
                <v-icon>mdi-auto-download</v-icon>
              </v-btn>
            </div>
          </template>
          <span>Autofill risks</span>
        </v-tooltip>
      </div>
    </v-card-title>
    <div class="px-0 pb-0" v-if="isEmptyObj">
      <div
        v-for="({ id, name }, index) in expansionPanelContent"
        :key="index"
        :class="index % 2 === 0 ? '' : 'card-text-bg'"
        class="px-3"
      >
        <div class="d-flex flex-row">
          <div class="d-flex align-center">
            <div class="pa-4 item-title-font">{{ name }}</div>
          </div>
          <div class="ml-auto my-auto ">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <v-btn class="color-red" @click="deleteAlert(id)" icon>
                    <v-icon>mdi-delete-empty-outline</v-icon>
                  </v-btn>
                </div>
              </template>
              <span>Delete risk</span>
            </v-tooltip>
          </div>
        </div>
      </div>
    </div>
    <v-card-text class="black--text mt-5 pl-7" v-else>
      <div class="red--text">No data found!</div>
      <div class="item-description-font">Try to add risk, or use autofill.</div>
    </v-card-text>
  </v-card>
</template>

<script>
import AddRisk from "./Actions/AddRisk";
import axiosInstance from "@/axios";
//import { config } from "../../../config.js";
import _ from "lodash";
export default {
  components: {
    AddRisk,
  },
  data: () => ({
    expansionHeader: "Risks",
  }),
  computed: {
    isEmptyObj: function() {
      if (this.expansionPanelContent) {
        let isEmpty = _.isEmpty(this.expansionPanelContent);
        return !isEmpty;
      }
      return null;
    },
    expansionPanelContent: function() {
      return this.$store.state.risks;
    },
  },

  methods: {
    async autoFill() {
      try {
        await axiosInstance.post(
          `/proposal/${this.$route.params.id}/Risk/auto`,
          {},
          {
            headers: {},
          }
        );
        await this.$store.dispatch("getSummaryData", this.$route.params.id);
        this.$store.dispatch("getCurrentProposalData", this.$route.params.id);
      } catch (err) {
        console.log(err);
      }
    },
    deleteAlert(riskID) {
      this.$swal({
        title: "Do you want to delete this risk?",
        showDenyButton: true,
        confirmButtonText: `Yes`,
        denyButtonText: `No`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteRisk(riskID);
        } else if (result.isDenied) {
          return;
        }
      });
    },
    async deleteRisk(riskID) {
      try {
        await axiosInstance.delete(
          `/proposal/${this.$route.params.id}/Risk/${riskID}`,
          {
            headers: {},
          }
        );
        await this.$store.dispatch("getSummaryData", this.$route.params.id);
        this.$store.dispatch("getCurrentProposalData", this.$route.params.id);
      } catch (err) {
        console.log(err);
      }
    },
    refreshData() {
      this.$store.dispatch("getCurrentProposalData", this.$route.params.id);
    },
  },
};
</script>

<style scoped>
.block-title {
  display: inline-block;
}
</style>
