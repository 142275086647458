<template>
  <v-menu v-model="menu" bottom left :close-on-click="isMenuCloseOnClick">
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-bind="attrs" v-on="on" @click="preventRouting">
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
    </template>

    <v-list>
      <div
        v-for="({
          title,
          methodName,
          icon,
          copy,
          edit,
          isEdit,
          getLink,
          isTitleEditing,
        },
        i) in menuOptions"
        :key="i"
        class="menu-item px-0"
        @click="methodName ? handleFunctionCall(methodName, id) : null"
      >
        <div style="width:100%">
          <EditTask
            v-if="edit"
            :edit="edit"
            :className="icon"
            :title="title"
            :taskId="taskId"
            :refresh="reloadData"
            :closeMenu="closeMenu"
          />
          <CopyProposal
            v-if="copy || isTitleEditing"
            :className="icon"
            :title="title"
            :proposalTitle="proposalTitle"
            :leadTitle="leadTitle"
            :id="id"
            :refresh="reloadData"
            :leadId="leadId"
            :closeMenu="closeMenu"
            :isLeadSelectionEnabled="isLeadSelectionEnabled"
            :isTitleEditing="isTitleEditing"
          />
          <EditArchitecture
            v-if="isEdit"
            :id="id"
            :className="icon"
            :optionTitle="title"
            :proposalId="proposalId"
            :closeMenu="closeMenu"
          />
          <GetLink
            v-if="getLink"
            :id="id"
            :title="title"
            :closeMenu="closeMenu"
            :className="icon"
          />
          <div v-if="methodName" class="option">
            <i :class="icon" class="icon-style mr-2" />
            <div>{{ title }}</div>
          </div>
        </div>
      </div>
    </v-list>
  </v-menu>
</template>

<script>
import axiosInstance from "@/axios";
import CopyProposal from "../LeadDetailed/Actions/CopyProposal";
import EditTask from "../ProposalDetailed/Actions/AddTask.vue";
import EditArchitecture from "../../components/ProposalDetailed/SolutionArchitecture/NavigationBar/AddArchitecture";
import GetLink from "./GetLink.vue";

export default {
  components: {
    CopyProposal,
    EditArchitecture,
    EditTask,
    GetLink,
  },
  data: () => ({
    menu: false,
  }),
  props: {
    id: Number,
    alternativeDelete: Boolean,
    proposalTitle: String,
    leadTitle: String,
    leadId: Number,
    taskId: Number,
    entitiesName: String,
    singleEntityName: String,
    reloadData: Function,
    menuOptions: Array,
    architectureId: String,
    isLeadSelectionEnabled: Boolean,
    isTitleEditing: Boolean,
  },

  computed: {
    proposalId() {
      return this.$route.params.id;
    },
    isMenuCloseOnClick() {
      return this.$store.state.cardMenuClockCloseStatus;
    },
  },
  methods: {
    closeMenu() {
      this.$data.menu = false;
    },
    preventRouting(e) {
      e.preventDefault();
    },
    handleFunctionCall(methodName, id) {
      this[methodName](id);
    },
    openItemPage(id) {
      this.$router.push(`/${this.$props.entitiesName}/${id}`);
    },
    async deleteItem(id) {
      this.$swal({
        title: `Do you want to delete this ${this.$props.singleEntityName}?`,
        showDenyButton: true,
        confirmButtonText: `Yes`,
        denyButtonText: `No`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.delete(id);
        } else if (result.isDenied) {
          return;
        }
      });
    },

    async delete(id) {
      try {
        if (this.$props.alternativeDelete) {
          await axiosInstance.delete(
            `/proposal/${this.$route.params.id}/${this.$props.singleEntityName}/${id}`
          );
        } else {
          await axiosInstance.delete(`/${this.$props.singleEntityName}/${id}`);
        }
        await this.$props.reloadData();
      } catch (e) {
        console.log(e.message);
      }
    },
    async copy(id) {
      try {
        if (this.$props.singleEntityName === "architecture") {
          await axiosInstance.post(
            `/proposal/${this.proposalId}/${this.$props.singleEntityName}/copy/${id}`,
            {}
          );
        } else {
          await axiosInstance.post(
            `/proposal/${this.proposalId}/${this.$props.singleEntityName}/${id}/copy`,
            {}
          );
        }

        await this.$props.reloadData();
      } catch (e) {
        console.log(e.message);
      }
    },
    openArchitectureDrawIo() {
      window.open(`https://app.diagrams.net/#G${this.$props.architectureId}`);
    },
    openArchitectureGdrive() {
      window.open(
        `https://drive.google.com/file/d/${this.$props.architectureId}`
      );
    },
    async saveAsTemplate(id) {
      try {
        await axiosInstance.post(
          `/dictionary/${this.$props.singleEntityName}/${id}`,
          {}
        );
        await this.$props.reloadData();
      } catch (e) {
        console.log(e);
      }
    },
    async moveArchitectureUp(id) {
      try {
        await axiosInstance.post(
          `/proposal/${this.proposalId}/${this.$props.singleEntityName}/position/up/${id}`,
          {}
        );
        await this.$props.reloadData();
      } catch (e) {
        console.log(e);
      }
    },
    async moveArchitectureDown(id) {
      try {
        await axiosInstance.post(
          `/proposal/${this.proposalId}/${this.$props.singleEntityName}/position/down/${id}`,
          {}
        );
        await this.$props.reloadData();
      } catch (e) {
        console.log(e);
      }
    },

    async archiveItem(id) {
      this.$swal({
        title: `Do you want to arhive this ${this.$props.singleEntityName}?`,
        showDenyButton: true,
        confirmButtonText: `Yes`,
        denyButtonText: `No`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.archive(id);
        } else if (result.isDenied) {
          return;
        }
      });
    },
    async unarchiveItem(id) {
      this.$swal({
        title: `Do you want to unarchive this ${this.$props.singleEntityName}?`,
        showDenyButton: true,
        confirmButtonText: `Yes`,
        denyButtonText: `No`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.unarchive(id);
        } else if (result.isDenied) {
          return;
        }
      });
    },
    async archive(id) {
      try {
        await axiosInstance.put(
          `/Lead/${id}/archive`
        );
        
        await this.$props.reloadData();
      } catch (e) {
        console.log(e.message);
      }
    },
    async unarchive(id) {
      try {
        await axiosInstance.put(
          `/Lead/${id}/unarchive`
        );
        await this.$props.reloadData();
      } catch (e) {
        console.log(e.message);
      }
    },
  },
};
</script>

<style scoped>
.menu-item {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.menu-item:hover {
  background-color: rgba(224, 227, 235, 0.3);
}
.option {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 12px;
}
.icon-style {
  width: 20px;
}
::v-deep .v-btn__content {
  justify-content: center;
}
</style>
