<template>
  <v-card elevation="2">
    <v-card-title class="card-title-style justify-space-between"
      >{{ expansionHeader }}
    </v-card-title>
    <div>
      <div
        v-for="(item, i) in fullData"
        :key="i"
        :class="i % 2 === 0 ? 'px-3' : 'px-3 card-text-bg'"
      >
        <div class="block-style">
          <div class="item-title-font">{{ item.title }}</div>
          <v-select
            hide-details
            :items="item.options"
            label="please select"
            dense
            solo
            :value="item.value"
            class="select item-description-font"
            @change="(value) => handleChange(value, item.serverRoute)"
          />
        </div>
      </div>
    </div>
  </v-card>
</template>
<script>
import axiosInstance from "@/axios";

export default {
  data: () => ({
    expansionHeader: "Acceptance & Warranty",
    uatDurationData: {
      title: "User acceptance testing time",
      options: [
        { value: 1, text: "1 Day" },
        { value: 2, text: "2 Days" },
        { value: 3, text: "3 Days" },
        { value: 4, text: "4 Days" },
        { value: 5, text: "5 Days" },
        { value: 6, text: "6 Days" },
        { value: 7, text: "7 Days" },
        { value: 8, text: "8 Days" },
        { value: 9, text: "9 Days" },
        { value: 10, text: "10 Days" },
      ],
      serverRoute: "uat/duration",
    },
    defectVerificationTimeData: {
      title: "System acceptance defect verification time  ",
      options: [
        { value: 1, text: "1 Day" },
        { value: 2, text: "2 Days" },
        { value: 3, text: "3 Days" },
        { value: 4, text: "4 Days" },
        { value: 5, text: "5 Days" },
        { value: 6, text: "6 Days" },
        { value: 7, text: "7 Days" },
        { value: 8, text: "8 Days" },
        { value: 9, text: "9 Days" },
        { value: 10, text: "10 Days" },
        { value: 11, text: "11 Days" },
        { value: 12, text: "12 Days" },
        { value: 13, text: "13 Days" },
        { value: 14, text: "14 Days" },
      ],
      serverRoute: "defect/verification",
    },
    warrantyPeriodData: {
      title: "Warranty period for critical, major and moderate defects",
      options: [
        { value: 5, text: "5 Days" },
        { value: 10, text: "10 Days" },
        { value: 15, text: "15 Days" },
        { value: 30, text: "30 Days" },
        { value: 60, text: "60 Days" },
        { value: 90, text: "90 Days" },
        { value: 180, text: "180 Days" },
        { value: 365, text: "365 Days" },
      ],
      serverRoute: "warranty",
    },
    majorBugsPercentData: {
      title: "Required percent of major bugs to be fixed",
      options: [
        { value: 0, text: "0%" },
        { value: 10, text: "10%" },
        { value: 20, text: "20%" },
        { value: 30, text: "30%" },
        { value: 40, text: "40%" },
        { value: 50, text: "50%" },
        { value: 60, text: "60%" },
        { value: 70, text: "70%" },
        { value: 80, text: "80%" },
        { value: 90, text: "90%" },
        { value: 100, text: "100%" },
      ],
      serverRoute: "defect/major",
    },
    minorBugsPercentData: {
      title: "Required percent of minor bugs to be fixed",
      options: [
        { value: 0, text: "0%" },
        { value: 10, text: "10%" },
        { value: 20, text: "20%" },
        { value: 30, text: "30%" },
        { value: 40, text: "40%" },
        { value: 50, text: "50%" },
        { value: 60, text: "60%" },
        { value: 70, text: "70%" },
        { value: 80, text: "80%" },
        { value: 90, text: "90%" },
        { value: 100, text: "100%" },
      ],
      serverRoute: "defect/minor",
    },
  }),
  methods: {
    async handleChange(value, route) {
      try {
        await axiosInstance.put(
          `/proposal/${this.$route.params.id}/SingleValue/${route}/${value}`,
          {},
          {
            headers: {
              "Content-Type": "application/problem+json; charset=utf-8",
            },
          }
        );
        await this.$store.dispatch("getSummaryData", this.$route.params.id);
        this.$store.dispatch("getCurrentProposalData", this.$route.params.id);
      } catch (e) {
        console.log(e);
      }
    },
  },
  computed: {
    fullData() {
      let tempData = [];
      tempData[0] = this.$data.uatDurationData;
      tempData[0].value = this.$store.state.singleValue.uatDuration;
      tempData[1] = this.$data.defectVerificationTimeData;
      tempData[1].value = this.$store.state.singleValue.defectVerificationTime;
      tempData[2] = this.$data.warrantyPeriodData;
      tempData[2].value = this.$store.state.singleValue.warrantyPeriod;
      tempData[3] = this.$data.majorBugsPercentData;
      tempData[3].value = this.$store.state.singleValue.minPercentOfMajorBugsFixed;
      tempData[4] = this.$data.minorBugsPercentData;
      tempData[4].value = this.$store.state.singleValue.minPercentOfMinorBugsFixed;
      return tempData;
    },
  },
};
</script>
<style scoped>
.block-style {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  /* max-width: 800px; */
}
.select {
  max-width: 200px;
}
</style>
