<template>
  <div>
    <div class="login-block">
      <h2>Welcome to Presale application</h2>
      <div class="sign-in">Sign in to your account</div>
      <v-btn @click="authenticate" class="button" :disabled="isButtonDisabled">Google login</v-btn>
      <v-snackbar v-model="alert" absolute top>
        {{ alertText
        }}<template v-slot:action="{ attrs }">
          <v-btn color="pink" text v-bind="attrs" @click="alert = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </div>
  </div>
</template>
<script>
import axiosInstance from "@/axios";
import ls from "local-storage";
import { config } from "../../config";

export default {
  data: () => ({
    alert: false,
    alertText: "",
    isButtonDisabled: true,
  }),
  methods: {
    async authenticate() {
      try {
        const gAuth = await this.waitForGAuthInit();
        const googleUser = await gAuth.signIn();
        const userData = googleUser.getAuthResponse();
        const apiUserData = await this.getAPIToken(userData.id_token);
        this.$store.commit("setUserData", apiUserData.data.data);
        axiosInstance.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${apiUserData.data.data.token}`;
        ls.set("userData", apiUserData.data.data);
        const redirectTo = this.$store.state.redirectTo || "/leads";
        this.$router.push(redirectTo);
        this.$store.commit("setRedirectTo", null);
      } catch (e) {
        this.handleAuthenticationError(e);
      }
    },
    handleAuthenticationError(error) {
      if (error.error !== "popup_closed_by_user") {
        this.alertText = "Google initialization failed...";
        this.alert = true;
      }
      console.log(error);
    },
    async waitForGAuthInit() {
      const maxWaitTime = 10000;
      const interval = 100;
      let waitedTime = 0;

      return new Promise((resolve, reject) => {
        const checkGAuthLoad = setInterval(() => {
          if (this.$gAuth.isInit) {
            clearInterval(checkGAuthLoad);
            resolve(this.$gAuth);
          }
          waitedTime += interval;

          if (waitedTime >= maxWaitTime) {
            clearInterval(checkGAuthLoad);
            this.alertText = "Google Auth initialization timed out...";
            this.alert = true;
            reject(new Error(this.alertText));
          }
        }, interval);
      });
    },
    async getAPIToken(googleToken) {
      try {
        const parseGoogleToken = JSON.stringify(googleToken);
        const apiUserData = await axiosInstance.post(
          `/google`,
          parseGoogleToken,
          {
            baseURL: config.server_STS_URL,
            headers: {
              "Content-Type": "application/problem+json; charset=utf-8",
            },
          }
        );
        return apiUserData;
      } catch (e) {
        console.log(e);
      }
    },
    checkLogin() {
      const userData = ls.get("userData");
      this.$store.commit("setUserData", userData);
      if (userData) {
        const redirectTo = this.$store.state.redirectTo || "/leads";
        this.$router.push(redirectTo);
      }
    },
  },
  async created() {
    await this.waitForGAuthInit();
    this.isButtonDisabled = false;
    this.checkLogin();
  },
};
</script>
<style scoped>
.login-block {
  text-align: center;
  padding: 20% 0;
}
.sign-in {
  padding: 15px 0;
}
.button {
  background-color: #92278e !important;
  color: #ffffff !important;
}
</style>
