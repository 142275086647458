<template>
  <v-card>
    <div>
      <v-card-title class="card-title-style" @click="refreshData">
        {{ expansionHeader }}
      </v-card-title>
      <v-card-text class="mt-5" v-if="isEmptyObj">
        <div
          v-for="({ id, constraint, value }, index) in expansionPanelContent"
          :key="index"
          class="d-flex flex-row"
        >
          <div class="d-flex flex-row px-2">
            <v-list class="py-0" two-line>
              <div class="d-flex flex-row ">
                <v-icon color="black" class="px-1 pr-2">{{
                  icons[index]
                }}</v-icon>
                <template>
                  <div>
                    <v-list-item class="px-0" :key="index">
                      <v-list-item-content>
                        <div class="item-title-font" v-html="constraint"></div>
                        <v-list-item-subtitle
                          class="wrap-text"
                          v-html="value"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </div>
                </template>
              </div>
            </v-list>
          </div>
          <div class="ml-auto my-auto">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <EditConstraints
                    :refreshData="refreshData"
                    :value="value"
                    :id="id"
                  />
                </div>
              </template>
              <span>Saves answer for the given constraint</span>
            </v-tooltip>
          </div>
        </div>
      </v-card-text>
      <v-card-text class="mt-5" v-else>
        <div class="red--text">No data found!</div>
      </v-card-text>
    </div>
  </v-card>
</template>

<script>
import axiosInstance from "@/axios";
//import { config } from "../../../config.js";
import _ from "lodash";
import EditConstraints from "../LeadDetailed/Actions/EditConstraints";
export default {
  data: () => ({
    expansionHeader: "Constraints",
    expansionPanelContent: null,
    icons: [
      "mdi-exclamation-thick",
      "mdi-timer-sand",
      "mdi-webpack",
      "mdi-currency-usd",
      "mdi-code-json",
    ],
  }),
  components: {
    EditConstraints,
  },
  async mounted() {
    this.refreshData();
  },
  computed: {
    isEmptyObj: function() {
      if (this.$data.expansionPanelContent) {
        let isEmpty = _.isEmpty(this.$data.expansionPanelContent);
        return !isEmpty;
      }
      return null;
    },
  },
  methods: {
    async refreshData() {
      try {
        const { data } = await axiosInstance.get(
          `/lead/${this.$route.params.id}/Constraints`
        );

        if (data) {
          this.$data.expansionPanelContent = _.sortBy(data.data, (i) => i.id);
        }
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>

<style>
.wrap-text {
  white-space: normal;
  width: 100%;
  word-break: break-all;
  -webkit-line-clamp: unset !important;
}
</style>
