<template>
  <v-card>
    <v-card-title class="card-title-style"
      >{{ expansionHeader }}
      <div class="ml-auto">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <v-btn
                @click="refreshLeadData($route.params.id)"
                icon
                color="#92278e"
              >
                <v-icon v-if="!isDataRefreshed">mdi-autorenew</v-icon>
                <v-progress-circular
                  :width="2"
                  :size="30"
                  v-if="isDataRefreshed"
                  color="#92278e"
                  indeterminate
                />
              </v-btn>
            </div>
          </template>
          <span>Updating info from Pipedrive</span>
        </v-tooltip>
      </div></v-card-title
    >
    <v-card-text class="mt-5 black--text" v-if="isEmptyObj">
      <v-list max-width="1050px" two-line>
        <template
          v-for="({ note, user, creationDate }, index) in expansionPanelContent"
        >
          <v-divider
            class="split-line my-3"
            v-if="index"
            :key="note"
          ></v-divider>
          <div class="px-4" :key="index">
            <div class="py-3">
              <div class="item-title-font" v-html="note"></div>
              <div class="item-subtitle-font" v-html="user"></div>
              <div
                class="item-subtitle-font"
                style="font-size: 10px"
                v-html="filterDate(creationDate)"
              ></div>
            </div>
          </div>
        </template>
      </v-list>
    </v-card-text>
    <v-card-text class="black--text mt-5" v-else>
      <div class="px-4">
        <div class="red--text">No data found!</div>
        <div class="item-description-font">
          You can refresh the info, if you want.
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import axiosInstance from "@/axios";
//import { config } from "../../../config.js";
import _ from "lodash";
export default {
  computed: {
    isEmptyObj: function() {
      if (this.$data.expansionPanelContent) {
        let isEmpty = _.isEmpty(this.$data.expansionPanelContent);
        return !isEmpty;
      }
      return null;
    },
  },
  data: () => ({
    expansionHeader: "Notes from Pipedrive",
    expansionPanelContent: null,
    isDataRefreshed: false,
  }),
  async created() {
    try {
      const { data } = await axiosInstance.get(
        `/lead/${this.$route.params.id}/notes`
      );

      if (data) {
        this.$data.expansionPanelContent = data.data;
        // console.log(this.$data.expansionPanelContent);
      }
    } catch (err) {
      console.log(err);
    }
  },
  methods: {
    filterDate(creationDate) {
      let newDate = new Date(creationDate.split(".").shift() + "Z");

      let date = newDate.toLocaleString().split(",");
      let time = date.pop();

      let result = date.shift() + " | " + time;
      return result;
    },
    async refreshInfo() {
      try {
        const { data } = await axiosInstance.get(
          `/lead/${this.$route.params.id}/notes`
        );

        if (data) {
          this.$data.expansionPanelContent = data.data;
        }
      } catch (err) {
        console.log(err);
      }
    },
    async refreshLeadData(leadId) {
      this.$data.isDataRefreshed = true;
      try {
        await axiosInstance.post(`/Lead/${leadId}/pipedrive/refresh`, {});
        this.refreshInfo();
        this.$data.isDataRefreshed = false;
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style scoped>
.split-line {
  margin: 0 auto;
  width: 97%;
}
</style>
