<template>
  <div>
    <v-card elevation="2">
      <v-card-title class="card-title-style justify-space-between"
        >{{ expansionHeader }}

        <div class="d-flex ml-auto">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-btn
                  @click="
                    $router.push(`/proposals/${$route.params.id}/milestones`)
                  "
                  icon
                >
                  <v-icon color="green">mdi-pencil-plus</v-icon>
                </v-btn>
              </div>
            </template>
            <span>Open milestone detail page</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-btn @click="autoFill()" icon>
                  <v-icon color="blue"> mdi-auto-download</v-icon>
                </v-btn>
              </div>
            </template>
            <span>Autofill milestones</span>
          </v-tooltip>
        </div>
      </v-card-title>
      <v-card-text class=" black--text px-0 pb-0" v-if="isEmptyObj">
        <div
          v-for="({ id, name }, index) in expansionPanelContent"
          :key="index"
          :class="index % 2 === 0 ? '' : 'card-text-bg'"
          class="px-3"
        >
          <div class="d-flex flex-row">
            <div class="pa-4">
              <div class="item-title-font">{{ name }}</div>
              <div
                @mouseover="showByIndex = index"
                @mouseout="showByIndex = null"
                @click="
                  setAnchourPoint(`a-${id}`),
                    $router.push(`/proposals/${$route.params.id}/milestones`)
                "
                style="cursor: pointer"
                class="d-flex align-center mt-1"
              >
                <v-icon v-if="showByIndex !== index">mdi-folder-open</v-icon>
                <v-icon v-else>mdi-folder-open-outline</v-icon>
                <div class="ml-1 item-description-font">Tasks</div>
              </div>
            </div>
            <div class="ml-auto my-auto">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    <v-btn class="color-red" @click="deleteAlert(id)" icon>
                      <v-icon>mdi-delete-empty-outline</v-icon>
                    </v-btn>
                  </div>
                </template>
                <span>Delete milestone</span>
              </v-tooltip>
            </div>
          </div>
        </div>
      </v-card-text>
      <v-card-text class="black--text mt-5 pl-7" v-else>
        <div class="red--text">No data found!</div>
        <div class="item-description-font">
          Try to create milestone on the
          <a :href="`/proposals/${$route.params.id}/milestones`">detail page</a
          >, or use autofill.
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import _ from "lodash";
import axiosInstance from "@/axios";
export default {
  data: () => ({
    expansionHeader: "Milestones",
    showByIndex: null,
  }),
  computed: {
    isEmptyObj: function() {
      if (this.expansionPanelContent) {
        let isEmpty = _.isEmpty(this.expansionPanelContent);
        return !isEmpty;
      }
      return null;
    },
    expansionPanelContent: function() {
      return this.$store.state.milestones;
    },
  },
  methods: {
    async autoFill() {
      try {
        await axiosInstance.post(
          `/proposal/${this.$route.params.id}/Milestone/auto`,
          {}
        );
        await this.$store.dispatch("getSummaryData", this.$route.params.id);
        this.$store.dispatch("getCurrentProposalData", this.$route.params.id);
      } catch (err) {
        console.log(err);
      }
    },
    setAnchourPoint(anchour) {
      this.$store.commit("setAnchour", anchour);
    },
    deleteAlert(milestoneId) {
      this.$swal({
        title: "Do you want to delete this milestone?",
        showDenyButton: true,
        confirmButtonText: `Yes`,
        denyButtonText: `No`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteMilestone(milestoneId);
        } else if (result.isDenied) {
          return;
        }
      });
    },
    async deleteMilestone(milestoneId) {
      let proposalId = this.$route.params.id;
      try {
        await axiosInstance.delete(
          `/proposal/${proposalId}/Milestone/${milestoneId}`
        );
        await this.$store.dispatch("getSummaryData", this.$route.params.id);
        this.$store.dispatch("getCurrentProposalData", this.$route.params.id);
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>

<style scoped></style>
