var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('div',[_c('v-card-title',{staticClass:"card-title-style",on:{"click":_vm.refreshData}},[_vm._v(" "+_vm._s(_vm.expansionHeader)+" "),_c('div',{staticClass:"d-flex flex-row ml-auto"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('AddQuestion',{attrs:{"type":_vm.type,"refreshQuestions":_vm.refreshData}})],1)]}}])},[_c('span',[_vm._v("Add business question")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.autoFillQuestions}},[_c('v-icon',{attrs:{"color":"light-blue"}},[_vm._v("mdi-auto-download")])],1)],1)]}}])},[_c('span',[_vm._v("Autofill questions")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-dialog',{attrs:{"persistent":"","max-width":"830px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"global-button-vuetify",attrs:{"color":"#92278e","icon":""},on:{"click":_vm.generateGoogleDoc}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-cloud-download ")])],1)]}}],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[(_vm.isBuilding)?_c('div',[_c('div',{staticClass:"d-flex align-center justify-center"},[_c('v-card-title',[_vm._v("Please wait for building business questions")]),_c('v-progress-circular',{attrs:{"size":30,"color":"primary","indeterminate":""}})],1),_c('v-card-text',{staticClass:"d-flex justify-center"},[_vm._v("it may take up to 60 seconds")])],1):_vm._e(),(!_vm.isBuilding && !_vm.errorOnBuild)?_c('div',[_c('div',{staticClass:"d-flex align-center justify-center"},[_c('v-card-title',[_vm._v("Business questions built")]),_c('v-icon',{attrs:{"size":30,"color":"green"}},[_vm._v("mdi-check")])],1),_c('v-card-text',{staticClass:"d-flex justify-center pb-1 pt-1 px-4"},[_c('div',{staticClass:"link-box"},[_c('a',{attrs:{"href":("" + _vm.googleDriveLink + _vm.documentId),"target":"_blank"}},[_c('div',[_vm._v(" "+_vm._s(("" + _vm.googleDriveLink + _vm.documentId))+" ")])])]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',[_c('v-btn',_vm._g(_vm._b({staticClass:"px-2 ml-2 copy-button",attrs:{"color":"rgba(224, 227, 235, 0.3)","elevation":"0"},on:{"click":_vm.copyDocumentLink,"mouseleave":function($event){_vm.copyText = 'Copy'}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"rgb(66, 82, 110)"}},[_vm._v("mdi-content-copy")])],1)],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.copyText))])])],1),_c('v-card-actions',{staticClass:"px-4"},[_c('v-spacer'),_c('v-btn',{staticClass:"px-0",attrs:{"color":"primary","text":"","min-width":"0px"},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Close ")])],1)],1):_vm._e(),(!_vm.isBuilding && _vm.errorOnBuild)?_c('div',[_c('div',{staticClass:"d-flex align-center justify-center"},[_c('v-card-title',[_vm._v("Something went wrong during building :(")])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){(_vm.dialog = false), (_vm.errorOnBuild = false)}}},[_vm._v(" Close ")])],1)],1):_vm._e()])],1)],1)]}}])},[_c('span',[_vm._v("Generate google doc")])])],1)]),(_vm.isEmptyObj)?_c('v-card-text',{staticClass:"mt-5"},_vm._l((_vm.expansionPanelContent),function(ref,index){
var id = ref.id;
var text = ref.text;
var answer = ref.answer;
return _c('div',{key:index},[_c('div',{staticClass:"d-flex flex-row"},[_c('v-list',{attrs:{"max-width":"1050px","two-line":""}},[[(index)?_c('v-divider',{key:id}):_vm._e(),_c('v-list-item',{key:index},[_c('v-list-item-content',{staticClass:"d-flex flex-row"},[_c('h3',{staticClass:"item-title=font mb-3",domProps:{"innerHTML":_vm._s('Q: ' + text)}}),_c('div',{staticClass:"item-description-font",domProps:{"innerHTML":_vm._s(answer ? 'A: ' + answer : 'A: ')}})])],1)]],2),_c('div',{staticClass:"d-flex flex-row ml-auto my-auto"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('AddEditQuestionSections',{attrs:{"refreshData":_vm.refreshData,"question":text,"questionId":id,"answer":answer,"type":_vm.type}})],1)]}}],null,true)},[_c('span',[_vm._v("Edit question and answer")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-btn',{staticClass:"color-red",attrs:{"icon":""},on:{"click":function($event){return _vm.deleteAlert(id)}}},[_c('v-icon',[_vm._v("mdi-delete-empty-outline")])],1)],1)]}}],null,true)},[_c('span',[_vm._v("Delete question")])])],1)],1)])}),0):_c('v-card-text',{staticClass:"black--text mt-5"},[_c('div',{staticClass:"px-4"},[_c('div',{staticClass:"red--text"},[_vm._v("No data found!")]),_c('div',{staticClass:"item-description-font"},[_vm._v("You can add or set auto fill.")])])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }