<template>
  <div>
    <v-dialog max-width="600px" persistent v-model="dialog">
      <template v-slot:activator="{ on: dialogOn, attrs: dialogAttrs }">
        <div class="button-container px-3 mb-2" v-if="!id">
          <v-tooltip style="z-index: 10;" bottom>
            <template v-slot:activator="{ on: tooltipOn, attrs: tooltipAttrs }">
              <div v-on="tooltipOn" v-bind="tooltipAttrs">
                <v-btn
                  fab
                  dark
                  small
                  color="green"
                  v-on="dialogOn"
                  v-bind="dialogAttrs"
                >
                  <div @click="clearData()">
                    <v-icon dark>
                      {{ "mdi-plus" }}
                    </v-icon>
                  </div>
                </v-btn>
              </div>
            </template>
            <span>Add empty architecture</span>
          </v-tooltip>
        </div>
        <div
          v-if="id"
          v-on="dialogOn"
          v-bind="dialogAttrs"
          @click="setEditData()"
          class="option"
        >
          <i :class="className" class="mr-2 icon-style" />
          <div>{{ optionTitle }}</div>
        </div>
      </template>
      <v-card>
        <v-card-title class="d-flex justify-space-between">
          <span v-if="!id" class="headline">New architecture</span>
          <span v-if="id" class="headline">Edit architecture</span>
          <v-progress-circular
            :size="30"
            color="primary"
            indeterminate
            v-if="isPosting"
          ></v-progress-circular>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="form" class="mx-2" lazy-validation>
              <v-row>
                <v-col cols="8">
                  <v-text-field
                    label="Title"
                    v-model="architectureTitle"
                    :rules="titleRules"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    auto-grow
                    row-height="18"
                    label="Description"
                    v-model="architectureDescription"
                    :rules="descriptionRules"
                  >
                  </v-textarea>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="closeDialog"
            :disabled="isPosting"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            @click="addArchitecture"
            :disabled="isPosting"
            v-if="!id"
          >
            Add
          </v-btn>
          <v-btn
            color="primary"
            @click="editArchitecture"
            :disabled="isPosting"
            v-if="id"
          >
            Edit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axiosInstance from "@/axios";

export default {
  props: ["id", "className", "optionTitle", "proposalId", "closeMenu"],
  data: () => ({
    architectureTitle: "",
    architectureDescription: "",
    titleRules: [(v) => !!v || "Name is required"],
    descriptionRules: [(v) => !!v || "Description is required"],
    isPosting: false,
    dialog: false,
  }),
  methods: {
    closeDialog() {
      this.$refs.form.reset();
      this.$store.commit("setCardMenuCloseStatus", true);
      this.$data.dialog = false;
      if (this.$props.closeMenu) {
        this.$props.closeMenu();
      }
    },
    async addArchitecture() {
      if (this.$refs.form.validate()) {
        const params = {
          title: this.$data.architectureTitle,
          description: this.$data.architectureDescription,
          fileId: '"',
        };

        try {
          this.$data.isPosting = true;
          await axiosInstance.post(
            `/proposal/${this.$route.params.id}/Architecture`,
            params
          );
          this.$data.isPosting = false;
        } catch (e) {
          console.log(e.message);
        }
        this.closeDialog();
        await this.$store.dispatch(
          "getArchitecturesList",
          this.$route.params.id
        );
        await this.$store.dispatch("getSummaryData", this.$route.params.id);
        this.$store.commit("setCardMenuCloseStatus", true);
      }
    },
    async editArchitecture() {
      const params = {
        title: this.$data.architectureTitle,
        description: this.$data.architectureDescription,
      };
      try {
        this.$data.isPosting = true;
        await axiosInstance.put(
          `/proposal/${this.$route.params.id}/Architecture/${this.$props.id}`,
          params
        );
        this.$data.isPosting = false;
        this.$props.closeMenu();
      } catch (e) {
        console.log(e.message);
      }
      this.closeDialog();
      await this.$store.dispatch("getArchitecturesList", this.$route.params.id);
      await this.$store.dispatch("getSummaryData", this.$route.params.id);
    },
    async setEditData() {
      this.$store.commit("setCardMenuCloseStatus", false);
      // this.$props.closeMenu();
      try {
        let {
          data: { data },
        } = await axiosInstance.get(
          `/proposal/${this.$props.proposalId}/Architecture`
        );
        const [currentArchitecture] = data.filter(
          (x) => x.id === this.$props.id
        );
        this.$data.architectureTitle = currentArchitecture.title;
        this.$data.architectureDescription = currentArchitecture.description;
      } catch (e) {
        console.log(e.message);
      }
    },
    clearData() {
      this.$data.architectureTitle = "";
      this.$data.architectureDescription = "";
    },
  },
};
</script>
<style scoped>
.icon-style {
  width: 20px;
}
.option {
  display: flex;
  align-items: center;
  padding: 12px;
}
</style>
