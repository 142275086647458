<template>
  <div>
    <v-card elevation="2">
      <v-card-title class="card-title-style justify-space-between"
        >{{ expansionHeader }}
      </v-card-title>

      <div class="px-7 py-4 d-flex justify-space-between">
        <div class="d-flex flex-column">
          <div>
            <div class="d-flex">
              <div class="item-description-font">Estimate(PERT)</div>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div
                    v-bind="attrs"
                    v-on="on"
                    v-on:mouseenter="handleEasterEgg(true)"
                    v-on:mouseleave="handleEasterEgg(false)"
                  >
                    <a :href="pertLink" target="_blank" class="pert-link"
                      ><i class="fas fa-question-circle"></i
                    ></a>
                  </div>
                </template>
                <div v-if="!isEasterEggEnabled">
                  More info about three-point estimation
                </div>
                <div v-if="isEasterEggEnabled">
                  <div class="easter-egg-text">Don't know what it means?</div>
                  <v-img :src="easterEgg" class="easter-egg-image" />
                  <div class="easter-egg-text">You can check it on wikipedia</div>
                </div>
              </v-tooltip>
            </div>
            <v-radio-group
              v-model="pertEstimationType.selected"
              @change="
                handleChange(pertEstimationType.selected, pertEstimationType.link)
              "
            >
              <v-radio
                v-for="(item, i) in pertEstimationType.options"
                :key="i"
                :label="item.title"
                :value="item.value"
                class="item-description-font"
              ></v-radio>
            </v-radio-group>
          </div>

          <div>
            <div class="item-description-font">Outstaff payment calculations</div>
            <v-radio-group
              v-model="outstaffPaymentMethodsCalculations.selected"
              @change="
                handleChange(outstaffPaymentMethodsCalculations.selected, outstaffPaymentMethodsCalculations.link)
              "
            >
              <v-radio
                v-for="(item, i) in outstaffPaymentMethodsCalculations.options"
                :key="i"
                :label="item.title"
                :value="item.value"
                class="item-description-font"
              ></v-radio>
            </v-radio-group>
          </div>
        </div>

        <div>
          <div class="item-description-font">Standard deviation</div>
          <v-radio-group
            v-model="standartDeviation.selected"
            @change="
              handleChange(standartDeviation.selected, standartDeviation.link)
            "
          >
            <v-radio
              v-for="(item, i) in standartDeviation.options.slice().reverse()"
              :key="i"
              :label="item.title"
              :value="item.value"
              class="item-description-font"
            ></v-radio>
          </v-radio-group>
        </div>

        <div class="other-settings">
          <div v-for="(item, i) in otherSettings" :key="i">
            <v-checkbox
              hide-details
              :label="item.title"
              v-model="item.isSelected"
              @change="handleChange(item.isSelected, item.link)"
              class="mb-2 item-description-font"
            ></v-checkbox>
          </div>
        </div>
      </div>
    </v-card>
  </div>
</template>
<script>
import axiosInstance from "@/axios";
import easterEgg from "@/assets/easterEgg.jpg";

export default {
  data: () => ({
    expansionHeader: "Settings",
    pertEstimationTypeData: {
      options: [
        { title: "Weighted average", value: 1 },
        { title: "Triangular distribution", value: 2 },
      ],
      selected: null,
      link: "estimationType",
    },
    outstaffPaymentMethodsCalculationsData: {
      options: [
        { title: "DT & TM", value: 1 },
        { title: "DT only", value: 2 },
        { title: "TM only", value: 3 },
      ],
      selected: null,
      link: "outstaff/method",
    },
    standartDeviationData: {
      options: [
        { title: "0%", value: 1 },
        { title: "68%", value: 2 },
        { title: "90%", value: 3 },
        { title: "95%", value: 4 },
        { title: "99.7%", value: 5 },
      ],
      selected: null,
      link: "standardError",
    },
    otherSettingsData: [
      {
        title: "Show unused services in proposal",
        isSelected: null,
        link: "unused/services",
      },
      {
        title: "Include services to milestones",
        isSelected: null,
        link: "services",
      },
    ],
    pertLink: "https://en.wikipedia.org/wiki/Three-point_estimation",
    easterEgg,
    isEasterEggEnabled: false,
    easterEggVariable: null,
  }),
  methods: {
    async handleChange(value, link) {
      try {
        await axiosInstance.put(
          `/proposal/${this.$route.params.id}/Settings/${link}/${value}`,
          {},
          {
            headers: {
              "Content-Type": "application/problem+json; charset=utf-8",
            },
          }
        );
        await this.$store.dispatch("getSummaryData", this.$route.params.id);
        this.$store.dispatch("getCurrentProposalData", this.$route.params.id);
      } catch (e) {
        console.log(e.response);
      }
    },
    handleEasterEgg(status) {
      if (status) {
        this.$data.easterEggVariable = setTimeout(
          () => (this.$data.isEasterEggEnabled = true),
          8000
        );
      } else {
        clearTimeout(this.$data.easterEggVariable);
        setTimeout(() => (this.$data.isEasterEggEnabled = false), 200);
      }
    },
  },
  computed: {
    pertEstimationType() {
      let tempData = this.$data.pertEstimationTypeData;
      tempData.selected = this.$store.state.settings.pertEstimationType;
      return tempData;
    },
    outstaffPaymentMethodsCalculations() {
      let tempData = this.$data.outstaffPaymentMethodsCalculationsData;
      tempData.selected = this.$store.state.settings.outstaffPaymentMethodsCalculations;
      return tempData;
    },
    standartDeviation() {
      let tempData = this.$data.standartDeviationData;
      tempData.selected = this.$store.state.settings.pertStandardError;
      return tempData;
    },
    otherSettings() {
      let tempData = this.$data.otherSettingsData;
      tempData[0].isSelected = this.$store.state.settings.includeUnusedServiceToProposal;
      tempData[1].isSelected = this.$store.state.settings.includeServicesToMilestone;
      return tempData;
    },
  },
};
</script>
<style scoped>
.pert-link {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 500;
  padding: 0 5px;
}
.pert-link:hover {
  color: rgb(146, 39, 142);
}
.other-settings {
  padding: 28px 0;
}
.easter-egg-image {
  height: 120px;
  width: 120px;
  margin: 0 auto;
}
.easter-egg-text {
  padding: 4px 0;
  text-align: center;
}
::v-deep .v-label {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.7);
  font-weight: 500;
}
</style>
