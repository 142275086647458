<template>
  <div>
    <template>
      <v-dialog persistent v-model="dialog" max-width="600px">
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on">
            <v-btn @click="milestoneName = name" icon>
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </div>
        </template>
        <v-card>
          <v-card-title>
            <span>Edit milestone name</span>
          </v-card-title>
          <v-card-text class="pb-0">
            <v-row no-gutters>
              <v-col>
                <v-textarea
                  auto-grow
                  rows="1"
                  row-height="15"
                  v-model="milestoneName"
                  class="pa-0 ma-0"
                >
                </v-textarea
              ></v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="dialog = false">
              Close
            </v-btn>
            <v-btn color="primary" @click="editMilestone(), (dialog = false)">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </div>
</template>

<script>
import axiosInstance from "@/axios";
export default {
  data: () => ({
    dialog: false,
    milestoneName: "",
  }),
  props: {
    id: Number,
    name: String,
    refreshData: Function,
  },
  methods: {
    async editMilestone() {
      console.log(this.$data.milestoneName);
      try {
        await axiosInstance.put(
          `/proposal/${this.$route.params.id}/Milestone/${this.$props.id}`,
          { name: this.$data.milestoneName }
        );
        this.$props.refreshData();
      } catch (e) {
        console.log(e.message);
      }
    },
  },
};
</script>
