import axiosInstance from "@/axios";
//import { config } from "../../config";

export default {
  state: {
    lead: null,
    proposal: null,
  },
  mutations: {
    setHistory(state, data) {
      const { entityName, history } = data;
      state[entityName] = history;
    },
  },
  actions: {
    async getHistory({ commit }, payload) {
      const { id, entityName } = payload;
      try {
        const { data: history } = await axiosInstance.get(
          `/History/${entityName}/${id}`
        );
        commit("setHistory", { entityName, history: history.data });
      } catch (e) {
        console.log(e);
      }
    },
  },
};
