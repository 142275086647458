<template>
  <v-dialog persistent v-model="dialog" max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="global-button-vuetify"
        icon
        color="green"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon color="green">mdi-plus</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">Add Question</span>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-combobox
              append-icon=""
              :items="questions"
              color="blue"
              label="Questions"
              :search-input.sync="text"
            ></v-combobox>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-checkbox v-model="checkbox" :label="`Add as template`"></v-checkbox>
        <v-spacer></v-spacer>
        <v-btn text @click="(dialog = false), (text = ''), (checkbox = false)">
          Close
        </v-btn>
        <v-btn
          color="primary"
          @click="
            addLeadQuestion($route.params.id), (text = ''), (checkbox = false)
          "
        >
          Add
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axiosInstance from "@/axios";
//import { config } from "../../../../config.js";
export default {
  props: {
    type: Number,
    refreshQuestions: Function,
  },
  data: () => ({
    text: "",
    dialog: false,
    checkbox: false,
    questions: [],
  }),
  watch: {
    text: async function() {
      try {
        const { data } = await axiosInstance.get(
          `/Dictionary/questions?type=${this.$props.type}&search=${this.$data.text}`
        );

        if (data) {
          this.$data.questions = data.data;
        }
      } catch (err) {
        console.log(err);
      }
    },
  },
  methods: {
    async addLeadQuestion(leadId) {
      let isTemplate = this.$data.questions.find(
        (el) => el.text === this.$data.text
      );
      if (isTemplate && this.$data.checkbox) {
        alert("You are trying to add template that is exist");
        this.$data.text = "";
        !this.$data.checkbox;
      } else {
        this.$data.dialog = false;
        let obj = {
          text: this.$data.text,
          type: this.$props.type,
          saveAsTemplate: this.$data.checkbox,
        };

        try {
          await axiosInstance.post(`/lead/${leadId}/Question`, obj);
          this.$props.refreshQuestions();
        } catch (e) {
          console.log(e);
        }
      }
    },
  },
};
</script>

<style>
.v-autocomplete__content {
  max-width: 535px;
}
</style>
