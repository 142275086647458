var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"bottom":"","left":"","close-on-click":_vm.isMenuCloseOnClick},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.preventRouting}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-list',_vm._l((_vm.menuOptions),function(ref,i){
      var title = ref.title;
      var methodName = ref.methodName;
      var icon = ref.icon;
      var copy = ref.copy;
      var edit = ref.edit;
      var isEdit = ref.isEdit;
      var getLink = ref.getLink;
      var isTitleEditing = ref.isTitleEditing;
return _c('div',{key:i,staticClass:"menu-item px-0",on:{"click":function($event){methodName ? _vm.handleFunctionCall(methodName, _vm.id) : null}}},[_c('div',{staticStyle:{"width":"100%"}},[(edit)?_c('EditTask',{attrs:{"edit":edit,"className":icon,"title":title,"taskId":_vm.taskId,"refresh":_vm.reloadData,"closeMenu":_vm.closeMenu}}):_vm._e(),(copy || isTitleEditing)?_c('CopyProposal',{attrs:{"className":icon,"title":title,"proposalTitle":_vm.proposalTitle,"leadTitle":_vm.leadTitle,"id":_vm.id,"refresh":_vm.reloadData,"leadId":_vm.leadId,"closeMenu":_vm.closeMenu,"isLeadSelectionEnabled":_vm.isLeadSelectionEnabled,"isTitleEditing":isTitleEditing}}):_vm._e(),(isEdit)?_c('EditArchitecture',{attrs:{"id":_vm.id,"className":icon,"optionTitle":title,"proposalId":_vm.proposalId,"closeMenu":_vm.closeMenu}}):_vm._e(),(getLink)?_c('GetLink',{attrs:{"id":_vm.id,"title":title,"closeMenu":_vm.closeMenu,"className":icon}}):_vm._e(),(methodName)?_c('div',{staticClass:"option"},[_c('i',{staticClass:"icon-style mr-2",class:icon}),_c('div',[_vm._v(_vm._s(title))])]):_vm._e()],1)])}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }