<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on" class="account-icon">
        <div v-if="getAvatarUrl" class="img-container">
          <img :src="getAvatarUrl" alt="avatar" class="avatar" />
        </div>
        <div v-else>
          <v-icon class="no-avatar" style="" medium>mdi-account</v-icon>
        </div>
      </div>
    </template>
    <v-list class="mt-4">
      <v-list-item
        class="dropdown-items pa-0"
        v-for="(item, index) in accountData"
        :key="index"
        link
      >
        <div @click="logOut" class="option">
          {{ item.title }}
          <div class="logout-icon">
            <i class="fas fa-sign-out-alt"></i>
          </div>
        </div>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import ls from "local-storage";
import axiosInstance from "@/axios";

export default {
  data: () => ({
    accountData: [
      {
        title: "Logout",
      },
    ],
  }),

  computed: {
    //#r computed prop cant be a verb
    getAvatarUrl() {
      return this.$store.state.userData.avatarUrl;
    },
  },
  methods: {
    logOut() {
      this.$store.state.userData = null;
      this.$store.state.redirectTo = null;
      axiosInstance.defaults.headers.common["Authorization"] = "";
      ls.remove("userData");
      this.$router.push("/login");
    },
  },
};
</script>
<style scoped>
.account-icon {
  font-size: 27px;
  margin-left: 15px;
}

.dropdown-items {
  display: flex;
  flex-direction: row;
}

.option {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 20px 12px;
}

.no-avatar {
  font-size: 30px;
  color: black;
}

.logout-icon {
  padding-left: 5px;
}

.img-container {
  width: 30px;
  display: flex;
  align-items: center;
}

.avatar {
  border-radius: 50%;
  max-width: 100%;
  max-height: 100%;
}
::v-deep .v-list {
  padding: 0;
}
</style>
