<template>
  <v-card>
    <v-card-title class="card-title-style">Summary</v-card-title>
    <v-card-text class="my-5 px-6">
      <v-data-table
        :headers="headers"
        :items="elements"
        hide-default-footer
        disable-sort
      ></v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import _ from "lodash";
export default {
  data: () => ({
    headers: [
      {
        text: "Projects",
        align: "start",
        value: "projectName",
        width: "1%",
      },
      {
        text: "Confidence estimate",
        value: "confidenceEstimate",
        width: "1%",
      },
      { text: "Estimate", value: "estimate", width: "1%" },
      { text: "Diviation", value: "diviation", width: "1%" },
    ],
  }),
  computed: {
    elements() {
      return _(this.$store.state.taskDataSummary).forEachRight(
        (value) =>
          (value = _(value).forInRight((element, key) =>
            typeof element == "number"
              ? (value[key] = _(element).floor(2))
              : element
          ))
      );
    },
  },
};
</script>
