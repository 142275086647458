var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._l((_vm.expansionPanelContent),function(value,name,index){return _c('v-card',{key:index,staticClass:"mb-10",attrs:{"elevation":"2"}},[_c('v-card-title',{staticClass:"card-title-style my-auto"},[_vm._v(" "+_vm._s(name)+" "),_c('div',{staticClass:"ml-auto d-flex align-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.autoFill(value[0].key)}}},[_c('v-icon',{attrs:{"color":"blue"}},[_vm._v(" mdi-auto-download")])],1)],1)]}}],null,true)},[_c('span',[_vm._v("Autofill "+_vm._s(name)+" section")])])],1)]),_c('v-card-text',[_c('div',{staticClass:" pt-5"},_vm._l((value),function(item,index){return _c('div',{key:index},[_c('div',{staticClass:"d-flex flex-row align-center black--text my-1"},[_c('AddService',{attrs:{"id":item.id,"type":item.estimateType,"refreshData":_vm.refreshData}}),_c('div',{staticClass:"d-flex flex-column"},[_c('div',[_vm._v(_vm._s(item.title))]),_c('div',[(item.estimateType === 2)?_c('v-card-subtitle',{staticClass:"d-flex px-0 py-0"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_vm._v(" "+_vm._s(item.relativeEstimate + "%")+" ")])]}}],null,true)},[_c('span',[_vm._v("Relative estimate")])])],1):_c('v-card-subtitle',{staticClass:"d-flex px-0 py-0"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('div',{staticClass:"mr-2"},[_vm._v(" "+_vm._s(item.optimisticEstimate + "hrs")+" ")])])]}}],null,true)},[_c('span',[_vm._v("Optimistic estimate")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('div',{staticClass:"mr-2"},[_vm._v(" "+_vm._s(item.pesimisticEstimate + "hrs")+" ")])])]}}],null,true)},[_c('span',[_vm._v("Pesimistic estimate")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('div',{staticClass:"mr-2"},[_vm._v(" "+_vm._s(item.realisticEstimate + "hrs")+" ")])])]}}],null,true)},[_c('span',[_vm._v("Realistic estimate")])])],1)],1)]),_c('div',{staticClass:"ml-auto"},[_c('v-switch',{attrs:{"label":item.isFree ? 'free' : 'paid'},on:{"change":function($event){return _vm.newState(item.id, item.isFree)}},model:{value:(item.isFree),callback:function ($$v) {_vm.$set(item, "isFree", $$v)},expression:"item.isFree"}})],1)],1)])}),0)])],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }