<template>
  <v-dialog v-model="dialog" max-width="600px" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        @click="(answerLocal = answer), (questionLocal = question)"
        class="color-green global-button-vuetify"
        icon
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>mdi-pencil-outline</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">Edit</span>
      </v-card-title>
      <v-card-text>
        <v-row no-gutters>
          <v-col>
            <v-textarea
              label="Enter question"
              auto-grow
              rows="2"
              row-height="50"
              v-model="questionLocal"
            >
            </v-textarea>
            <v-textarea
              label="Enter answer"
              auto-grow
              rows="2"
              row-height="50"
              v-model="answerLocal"
            >
            </v-textarea>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="dialog = false">
          Close
        </v-btn>
        <v-btn color="primary" @click="(dialog = false), AddAnswerQuestion()">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axiosInstance from "@/axios";
export default {
  data: () => ({
    dialog: false,
    answerLocal: "",
    questionLocal: "",
  }),
  props: {
    refreshData: Function,
    questionId: Number,
    question: String,
    answer: String,
    type: Number,
  },
  methods: {
    async AddAnswerQuestion() {
      try {
        await axiosInstance.put(
          `/lead/${this.$route.params.id}/Question/${this.$props.questionId}/answer`,
          {
            answer: this.$data.answerLocal
              ? this.$data.answerLocal
              : this.$props.answer,
          }
        );
        await axiosInstance.put(
          `/lead/${this.$route.params.id}/Question/${this.$props.questionId}`,
          {
            text: this.$data.questionLocal
              ? this.$data.questionLocal
              : this.$props.question,
          }
        );
        this.$props.refreshData();
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>
