<template>
  <div>
    <div class="full-block">
      <div class="d-flex">
        <NavigationBar />
      </div>
      <ArchitecturesList />
    </div>
  </div>
</template>
<script>
import ArchitecturesList from "../components/ProposalDetailed/SolutionArchitecture/ArchitecturesList";
import NavigationBar from "../components/ProposalDetailed/SolutionArchitecture/NavigationBar";

export default {
  components: { ArchitecturesList, NavigationBar },
  created() {
    window.scrollTo(0, 0);
  },
};
</script>
<style scoped>
.full-block {
  max-width: 850px;
  margin: 0 auto;
}
</style>
