<template>
  <div>
    <div class=" d-flex mb-4">
      <BackToProposal />
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on" class="ml-1">
            <AddMilestone />
          </div>
        </template>
        <span>Add milestone</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on">
            <v-btn
              fab
              dark
              small
              class="global-button-vuetify ml-4"
              color="primary "
              @click="autofill"
            >
              <v-icon class="px-2">mdi-auto-download</v-icon>
            </v-btn>
          </div>
        </template>
        <span>Autofill milestones</span>
      </v-tooltip>
    </div>
    <div class="d-flex">
      <div v-if="isDataEmpty" class="milestone-edit">
        <MilestonesEdit />
      </div>
      <div class="milestone-edit" v-else>
        <div class="full-block">
          <div class="went-wrong-message">Nothing was found :(</div>
          <div class="tip error-tip">
            Try to add milestone.
          </div>
        </div>
      </div>
      <div class="toolbar px-5">
        <ToolBar class="toolbar-stick" />
      </div>
    </div>
  </div>
</template>

<script>
import BackToProposal from "@/components/ProposalDetailed/Actions/BackToProposal";
import MilestonesEdit from "../components/ProposalDetailed/Milestones/MilestonesEdit";
import ToolBar from "../components/ProposalDetailed/Milestones/MilestonesToolbar";
import AddMilestone from "../components/ProposalDetailed/Actions/AddMilestone.vue";
import axiosInstance from "@/axios";
export default {
  components: {
    BackToProposal,
    MilestonesEdit,
    AddMilestone,
    ToolBar,
  },
  created() {
    this.$store.dispatch("refreshMilestones", this.$route.params.id);
  },
  computed: {
    isDataEmpty() {
      return this.$store.state.milestones.length;
    },
  },
  methods: {
    async autofill() {
      try {
        await axiosInstance.post(
          `/proposal/${this.$route.params.id}/Milestone/auto`,
          {}
        );
        this.$store.dispatch("refreshMilestones", this.$route.params.id);
        this.$store.dispatch("getMilestoneSummary", this.$route.params.id);
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>

<style scoped>
.milestone-edit {
  flex: 7;
}
.toolbar {
  flex: 5;
}
.toolbar-stick {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  right: 1px;
}

.error-tip {
  font-size: 21px;
}
.toolbar-stick {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  right: 1px;
}
.full-block {
  padding: 15% 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.went-wrong-message {
  font-size: 28px;
}
.link-message {
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
  color: #92278e;
  text-transform: uppercase;
}
.link-message:hover {
  opacity: 0.7;
}
</style>
