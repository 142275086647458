<template>
  <div :key="componentKey">
    <div v-if="!architectures.length" class="no-data">
      There is no architectures yet. Please add new
    </div>
    <div
      v-for="({ id, fileId, title, description }, i) in architectures"
      :key="i"
      class="architecture-card"
    >
      <div class="menu">
        <CardMenu
          :id="id"
          :alternativeDelete="true"
          :menuOptions="menuOptions"
          :entitiesName="entitiesName"
          :singleEntityName="singleEntityName"
          :architectureId="fileId"
          :reloadData="reloadData"
        />
      </div>
      <div class="content-container">
        <div class="architecture-title">{{ title }}</div>
        <div class="image"></div>
        <iframe
          frameborder="0"
          style="width:100%;height:423px;"
          :src="
            `https://viewer.diagrams.net/?highlight=0000ff&edit=https%3A%2F%2Fapp.diagrams.net%2F%23G${fileId}&nav=1#G${fileId}`
          "
        ></iframe>
      </div>
      <div class="architecture-description">{{ description }}</div>
    </div>
  </div>
</template>
<script>
import CardMenu from "../../LeadDraggble/CardMenu";
export default {
  components: { CardMenu },
  data: () => ({
    entitiesName: "architectures",
    menuOptions: [
      {
        title: "Edit diagram(Draw.io)",
        methodName: "openArchitectureDrawIo",
        icon: "fas fa-paint-brush",
      },
      {
        title: "Edit diagram(Google drive)",
        methodName: "openArchitectureGdrive",
        icon: "fab fa-google-drive",
      },
      {
        title: "Edit text",
        isEdit: true,
        icon: "fas fa-edit",
      },
      {
        title: "Up",
        methodName: "moveArchitectureUp",
        icon: "fas fa-arrow-up",
      },
      {
        title: "Down",
        methodName: "moveArchitectureDown",
        icon: "fas fa-arrow-down",
      },

      {
        title: "Make a copy",
        methodName: "copy",
        icon: "fas fa-copy",
        copy: false,
      },
      {
        title: "Save as template",
        methodName: "saveAsTemplate",
        icon: "fas fa-save",
      },
      { title: "Delete", methodName: "deleteItem", icon: "fas fa-trash-alt" },
    ],
    singleEntityName: "architecture",
  }),
  created() {
    this.reloadData();
  },
  computed: {
    architectures() {
      return this.$store.state.architecturesList.architectures;
    },
    proposalId() {
      return parseInt(this.$route.params.id);
    },
    componentKey() {
      return this.$store.state.architecturesList.componentKey;
    },
  },
  methods: {
    async reloadData() {
      await this.$store.dispatch("getArchitecturesList", this.$route.params.id);
    },
  },
};
</script>
<style scoped>
.v-application a {
  color: inherit;
}
.architecture-card {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  margin: 15px 0;
  border-radius: 4px;
}

.content-container {
  padding: 0 100px;
}
.menu {
  display: flex;
  justify-content: flex-end;
  padding: 0 15px;
  padding-top: 30px;
}
.image {
  display: flex;
  justify-content: center;
  width: fit-content;
}

.architecture-title {
  font-weight: 500;
  font-size: 22px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
}
.architecture-description {
  padding-bottom: 30px;
  font-size: 1rem;
  line-height: 1.375rem;
  letter-spacing: 0.0071428571em;
  color: rgba(0, 0, 0, 0.6);
  max-width: 750px;
  margin: 0 auto;
  text-align: center;
}
.no-data {
  font-weight: 500;
  margin: 20px 0;
}
</style>
