<template>
  <v-dialog persistent v-model="dialog" max-width="700px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        @click="getCostSuggestions()"
        color="#2f7a28"
        icon
      >
        <v-icon>mdi-account-cash</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="d-flex justify-space-between sticky-line header-top">
        <span>Cost suggestions</span>
        <v-btn @click="clearAllData()" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pb-1">
        <div v-if="isLoading" class="d-flex justify-center">
          <v-progress-circular
            indeterminate
            color="primary"
            :size="60"
          ></v-progress-circular>
        </div>
        <div v-else v-for="(suggestion, index) in suggestions" :key="suggestion.id">
          <v-row no-gutters class="pt-2">
            <v-col cols="3" class="d-flex flex-column black--text">
              <span class="option-title item-title-font">
                {{ suggestion.title }}
              </span>
              <span class="option-allocation item-description-font mt-1">
                {{ suggestion.allocation }}%
              </span>
            </v-col>
            <v-col class="d-flex flex-column">
              <v-radio-group v-model="selectedSuggestions[index]">
                <v-radio 
                  v-for="(item, i) in suggestion.priceSuggestions" 
                  :key="i" 
                  :value="item"
                >
                  <template v-slot:label>
                    <span>{{ item.description }}</span>
                    <span class="mx-1">-</span>
                    <span> {{ item.hourlyPayment }}$/hr</span>
                    <span class="mx-1">|</span>
                    <span> {{ item.monthlyPayment }}$/month</span>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-divider />
        </div>
      </v-card-text>
      <v-card-actions class="sticky-line footer-bottom">
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="setCostSuggestions()">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axiosInstance from "@/axios";
export default {
  props: {
    id: Number,
    refreshData: Function,
  },
  data: () => ({
    dialog: false,
    suggestions: [],
    selectedSuggestions: [],
    isLoading: false,
  }),
  methods: {
    async getCostSuggestions() {
      try {
        this.$data.isLoading = true;
        const { data } = await axiosInstance.get(
          `/proposal/${this.$props.id}/TeamMember/auto/rates`
        );

        if (data) {
          this.$data.suggestions = data.data.reduce((newSuggestions, item, index) => {
            if(item.suggestions.length !== 0) {
              const priceSuggestions = item.suggestions.map(suggestion => ({
                id: item.id,
                description: suggestion.description,
                hourlyPayment: suggestion.hourlyPrice,
                monthlyPayment: suggestion.monthlyPrice,
              }));

              if (priceSuggestions.length === 1) {
                this.$data.selectedSuggestions[index] = priceSuggestions[0];
              }
              
              newSuggestions.push({
                title: item.suggestions[0].title,
                allocation: item.allocation,
                priceSuggestions,
              });
            }
            
            return newSuggestions;
          }, []);
        }
        this.$data.isLoading = false;
      } catch (err) {
        console.log(err);
        this.clearAllData();
      }
    },
    async setCostSuggestions() {
      try {
        this.$data.isLoading = true;
        for (const suggestion of this.$data.selectedSuggestions) {
          if (suggestion) {
            const currentTeamMember = this.teamMembers.find(teamMember => teamMember.id == suggestion.id);
            const editedTeamMember = {
              ...currentTeamMember,
              monthlyPayment: suggestion.monthlyPayment,
              hourlyPayment: suggestion.hourlyPayment,
            };
            await axiosInstance.put(
              `/proposal/${this.$props.id}/TeamMember/${suggestion.id}`,
              editedTeamMember,
              {
                headers: {},
              }
            );
          }
        }
        
        this.$store.dispatch("getSummaryData", this.$route.params.id);
        this.$props.refreshData();
        this.dialog = false;
      } catch (e) {
        console.log(e);
      } finally {
        this.clearAllData();
      }
    },
    clearAllData() {
      this.$data.dialog = false;
      this.$data.isLoading = false;
      this.$data.selectedSuggestions = [];
      this.$data.suggestions = [];
    }
  },
  computed: {
    teamMembers() {
      return this.$store.state.teamMembers;
    }
  },
};
</script>

<style>
  .sticky-line {
    position: sticky;
    background-color: white;
    z-index: 1;
  }
  .header-top {
    top: 0;
  }
  .footer-bottom {
    bottom: 0;
  }
</style>
