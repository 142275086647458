<template>
  <v-card elevation="2">
    <v-card-title class="card-title-style"
      >{{ expansionHeader }}
      <div class="ml-auto">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <v-btn
                @click="refreshLeadData($route.params.id)"
                icon
                color="#92278e"
              >
                <v-icon v-if="!isDataRefreshed">mdi-autorenew</v-icon>
                <v-progress-circular
                  :width="2"
                  :size="30"
                  v-if="isDataRefreshed"
                  color="#92278e"
                  indeterminate
                />
              </v-btn>
            </div>
          </template>
          <span>Updating info from Pipedrive</span>
        </v-tooltip>
      </div></v-card-title
    >
    <div v-if="isEmptyObj" class="pa-4">
      <v-card elevation="0">
        <div class="px-4 pt-4 d-flex align-center item-title-font">
          {{ expansionPanelContent.title }}
          <div class="ml-auto types d-flex flex-row">
            <v-tooltip v-if="expansionPanelContent.industry" bottom>
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on" class="sub-card-title mx-3">
                  {{ expansionPanelContent.industry }}
                </div>
              </template>
              <span>Industry</span>
            </v-tooltip>
            <v-tooltip v-if="expansionPanelContent.companyType" bottom>
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on" class="sub-card-title mx-3">
                  {{ expansionPanelContent.companyType }}
                </div>
              </template>
              <span>Company type</span>
            </v-tooltip>
            <v-tooltip v-if="expansionPanelContent.companySize" bottom>
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on" class="sub-card-title mx-3">
                  {{ expansionPanelContent.companySize }}
                </div>
              </template>
              <span>Company size</span>
            </v-tooltip>
          </div>
        </div>
        <div class="item-description-font px-4 pb-4">
          <v-tooltip v-if="expansionPanelContent.name" bottom>
            <template v-slot:activator="{ on, attrs }">
              <div class="d-inline-block" v-bind="attrs" v-on="on">
                {{ expansionPanelContent.name }}
              </div>
            </template>
            <span>Company name</span>
          </v-tooltip>
        </div>
        <v-card-text class="black--text">
          <div v-html="withBrTags"></div>
        </v-card-text>

        <v-card-subtitle class="d-flex flex-row align-center">
          <div>
            <a
              v-if="expansionPanelContent.pipeDriveUrl"
              :class="expansionPanelContent.webSite ? 'links-border-right' : ''"
              class="links mr-1"
              target="_blank"
              :href="`${expansionPanelContent.pipeDriveUrl}`"
              >Pipedrive</a
            >
            <a
              v-if="expansionPanelContent.webSite"
              :class="
                expansionPanelContent.socialNetwork ? 'links-border-right' : ''
              "
              class="links mx-1"
              target="_blank"
              :href="
                `${
                  expansionPanelContent.webSite.includes('\/')
                    ? expansionPanelContent.webSite.replaceAll('\/', '')
                    : 'https://' + expansionPanelContent.webSite
                }`
              "
              >Website</a
            >
            <a
              v-if="expansionPanelContent.socialNetwork"
              class="links mx-1"
              target="_blank"
              :href="
                `${
                  expansionPanelContent.socialNetwork.includes('\/')
                    ? expansionPanelContent.socialNetwork.replaceAll('\/', '')
                    : 'https://' + expansionPanelContent.socialNetwork
                }`
              "
              >Social network</a
            >
          </div>

          <div class="ml-auto">
            <v-tooltip
              v-if="
                expansionPanelContent.address ||
                  expansionPanelContent.country ||
                  expansionPanelContent.city
              "
              bottom
            >
              <template v-slot:activator="{ on, attrs }">
                <div class="d-flex align-center" v-bind="attrs" v-on="on">
                  <div class="mr-1">
                    <i class="fas fa-map-marker-alt"></i>
                  </div>
                  <div class="d-flex">
                    <div class="mr-1 item-description-font">
                      {{
                        expansionPanelContent.address
                          ? expansionPanelContent.address + ", "
                          : ""
                      }}
                    </div>
                    <div class="mr-1 item-description-font">
                      {{
                        expansionPanelContent.country
                          ? expansionPanelContent.country + ", "
                          : ""
                      }}
                    </div>
                    <div class="mr-1 item-description-font">
                      {{ expansionPanelContent.city }}
                    </div>
                  </div>
                </div>
              </template>
              <div>
                Address
              </div>
            </v-tooltip>
          </div>
        </v-card-subtitle>
      </v-card>
    </div>
    <v-card-text class="black--text mt-5" v-else>
      <div class="red--text">No data found!</div>
    </v-card-text>
  </v-card>
</template>

<script>
import axiosInstance from "@/axios";
//import { config } from "../../../config.js";
import _ from "lodash";
export default {
  props: ["getHistory"],
  computed: {
    isEmptyObj: function() {
      if (this.$data.expansionPanelContent) {
        let isEmpty = _.isEmpty(this.$data.expansionPanelContent);
        return !isEmpty;
      }
      return null;
    },
    withBrTags: function() {
      if (this.$data.description) {
        const doc = this.$data.description;
        return doc.replace(/(\\r)*\\n/g, "<br>");
      } else {
        return this.$data.description;
      }
    },
  },
  data: () => ({
    expansionHeader: "General information about lead from Pipedrive",
    expansionPanelContent: null,
    isDataRefreshed: false,
    description: "",
  }),
  async created() {
    this.refreshInfo();
  },
  methods: {
    async refreshInfo() {
      try {
        const { data } = await axiosInstance.get(
          `/lead/${this.$route.params.id}/info`
        );

        this.$data.description = data.data.description;

        if (data) {
          this.$data.expansionPanelContent = data.data;
        }
        this.$props.getHistory();
      } catch (err) {
        console.log(err);
      }
    },
    async refreshLeadData(leadId) {
      this.$data.isDataRefreshed = true;
      try {
        await axiosInstance.post(`/Lead/${leadId}/pipedrive/refresh`, {});
        this.refreshInfo();
        this.$data.isDataRefreshed = false;
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style scoped>
.links {
  text-decoration: none;
}

.links-border-right {
  border-right: 0.5px solid rgba(0, 0, 0, 0.4);
  padding-right: 4px;
}

.links:hover {
  opacity: 0.7;
}

.types {
  font-size: 15px;
  color: rgba(0, 0, 0, 0.8);
}

.sub-card-title {
  display: list-item;
  list-style-type: square;
  list-style-position: inside;
}
/* .company-name {
  padding: 16px;
  padding-top: 0;
  color: rgba(0, 0, 0, 0.6);
} */
</style>
