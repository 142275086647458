export const config = {
  server_URL: "https://apipresale.cloverdynamics.com",
  server_STS_URL: "https://api-sts.cloverdynamics.com/api/sts",
  gauthOptions: {
    clientId:
      "211434246108-9tb45r42m9rl3lan28cf95okkkhqn49d.apps.googleusercontent.com",
    scope: "profile email",
    prompt: "select_account",
  },
};

export const proposalTypes = Object.freeze([
  { id: 1, value: 'Outsource' },
  { id: 2, value: 'Outstaff' },
  { id: 3, value: 'Discovery' },
]);

export const stateProperty = Object.freeze([
  { id: 1, value: 'Active' },
  { id: 2, value: 'Archived' },
  { id: 3, value: 'Deleted' },
]);
