<template>
  <v-card elevation="2">
    <v-card-title class="card-title-style justify-space-between"
      >{{ expansionHeader }}
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn @click="autoFill" icon v-bind="attrs" v-on="on">
              <v-icon color="light-blue">mdi-auto-download</v-icon>
            </v-btn>
          </template>
          Autofill support/maintenance
        </v-tooltip>
    </v-card-title>
    <div class="px-7 pt-6">
      <div class="d-flex">
        <div class="text-lining-up  item-description-font">{{ text[0] }}</div>
        <div class="hours-input">
          <v-text-field
            :value="checkValue(supportHoursPerWeek)"
            solo
            dense
            type="number"
            :min="0"
            v-on:input="(value) => handleChange(value, hoursRoute)"
            :rules="rules.val"
            class="item-description-font"
          ></v-text-field>
        </div>
        <div class="text-lining-up  item-description-font">{{ text[1] }}</div>
        <div class="hours-input">
          <v-text-field
            :value="checkValue(supportWeeks)"
            solo
            dense
            type="number"
            :min="0"
            v-on:input="(value) => handleChange(value, weeksRoute)"
            :rules="rules.val"
            class="item-description-font"
          ></v-text-field>
        </div>
        <div class="text-lining-up  item-description-font">{{ text[2] }}</div>
      </div>
    </div>
  </v-card>
</template>
<script>
import axiosInstance from "@/axios";

export default {
  data: () => ({
    expansionHeader: "Support/Maintenance",
    text: ["We propose", "hours of support per week, for next", "weeks"],
    hoursRoute: "support/week/hours",
    weeksRoute: "support/duration",
    rules: {
      val: [
        (v) => v >= 0 || "lower 0",
        (v) => Number.isInteger(Number(v)) || "incorrect value",
      ],
    },
  }),
  methods: {
    async handleChange(value, route) {
      if (!value || value < 0 || !Number.isInteger(Number(value))) return;
      try {
        await axiosInstance.put(
          `/proposal/${this.$route.params.id}/SingleValue/${route}/${value}`,
          {},
          {
            headers: {
              "Content-Type": "application/problem+json; charset=utf-8",
            },
          }
        );
        await this.$store.dispatch("getSummaryData", this.$route.params.id);
        this.$store.dispatch("getCurrentProposalData", this.$route.params.id);
      } catch (e) {
        console.log(e);
      }
    },
    checkValue(value) {
      if (value < 0) {
        return 0;
      }
      return value;
    },
    async autoFill() {
      await this.$store.dispatch("getSupportMaintenanceAutoData", this.$route.params.id);
      await this.$store.dispatch("getSummaryData", this.$route.params.id);
      this.$store.dispatch("getCurrentProposalData", this.$route.params.id);
    },
  },
  computed: {
    supportHoursPerWeek() {
      return this.$store.state.singleValue.supportHoursPerWeek;
    },
    supportWeeks() {
      return this.$store.state.singleValue.supportWeeks;
    },
  },
};
</script>
<style scoped>
.hours-input {
  max-width: 100px;
  padding: 0 10px;
}
.text-lining-up {
  margin-top: 10px;
}
/* ::v-deep .v-card__subtitle,
.v-card__text,
.v-card__title {
  padding: 0 16px;
} */
</style>
