<template>
  <div>
    <v-card elevation="2">
      <v-card-title class="card-title-style justify-space-between"
        >{{ expansionHeader }}
        <div class="d-flex">
          <v-tooltip bottom v-if="!isEdit">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                class="global-button-vuetify"
                icon
                color="green"
                @click="enableEditing"
              >
                <v-icon color="green">mdi-pencil-plus</v-icon>
              </v-btn>
            </template>
            Edit general summary
          </v-tooltip>
          <div v-if="isEdit">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  color="green"
                  @click="editSummary"
                >
                  <v-icon color="green">mdi-check</v-icon>
                </v-btn>
              </template>
              Apply changes
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  color="green"
                  @click="cancelEditing"
                >
                  <v-icon color="red">mdi-close</v-icon>
                </v-btn>
              </template>
              Cancel changes
            </v-tooltip>
          </div>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" @click="autoFill" icon>
                <v-icon color="light-blue">mdi-auto-download</v-icon>
              </v-btn>
            </template>
            Autofill general summary
          </v-tooltip>
        </div>
      </v-card-title>
      <div class="px-3">
        <div
          class="pa-4 text-to-show item-description-font"
          v-if="!isEdit && data"
        >
          {{ data }}
        </div>
        <div
          v-if="!isEdit && !data"
          class="red--text pa-4"
          style="font-size:0.875rem"
        >
          No data found!
        </div>
        <v-textarea
          multi-line
          v-if="isEdit"
          label="Enter summary"
          auto-grow
          outlined
          rows="1"
          row-height="15"
          v-model="inputData"
          class="pt-4"
        >
        </v-textarea>
      </div>
    </v-card>
  </div>
</template>
<script>
import axiosInstance from "@/axios";

export default {
  data: () => ({
    expansionHeader: "General summary",
    isEdit: false,
    inputData: "",
  }),
  methods: {
    enableEditing() {
      this.$data.isEdit = true;
      this.$data.inputData = this.data;
    },
    async editSummary() {
      let newSummary = JSON.stringify(this.$data.inputData);
      try {
        await axiosInstance.put(
          `/proposal/${this.$route.params.id}/SingleValue/summary`,
          newSummary,
          {
            headers: {
              "Content-Type": "application/problem+json; charset=utf-8",
            },
          }
        );
        await this.$store.dispatch("getSummaryData", this.$route.params.id);
        this.$store.dispatch("getCurrentProposalData", this.$route.params.id);
      } catch (e) {
        console.log(e.message);
      }
      this.$data.isEdit = false;
    },
    cancelEditing() {
      this.$data.isEdit = false;
    },
    async autoFill() {
      await this.$store.dispatch("getGeneralSummaryAutoData", this.$route.params.id);
      await this.$store.dispatch("getSummaryData", this.$route.params.id);
      this.$store.dispatch("getCurrentProposalData", this.$route.params.id);
    },
  },
  computed: {
    data() {
      return this.$store.state.singleValue.generalSummary;
    },
  },
};
</script>
<style scoped>
.block-title {
  display: inline-block;
}
.text-to-show {
  white-space: pre-line;
}
</style>
