var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"elevation":"2"}},[_c('v-card-title',{staticClass:"card-title-style"},[_vm._v(_vm._s(_vm.expansionHeader)+" "),_c('div',{staticClass:"ml-auto d-flex flex-row"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('SetCostSuggestions',{attrs:{"id":_vm.proposalId,"refreshData":_vm.refreshData}})],1)]}}])},[_c('span',[_vm._v("Cost suggestions")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('AddEditTeamMember',{attrs:{"refreshData":_vm.refreshData,"edit":false}})],1)]}}])},[_c('span',[_vm._v("Add team member")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-btn',{attrs:{"color":"blue","icon":""},on:{"click":_vm.autoFill}},[_c('v-icon',[_vm._v("mdi-auto-download")])],1)],1)]}}])},[_c('span',[_vm._v("Autofill team members")])])],1)]),(_vm.isEmptyObj)?_c('v-card-text',{staticClass:"px-0 pb-0 black--text"},_vm._l((_vm.expansionPanelContent),function(ref,index){
      var id = ref.id;
      var level = ref.level;
      var specialization = ref.specialization;
      var allocation = ref.allocation;
      var hourlyPayment = ref.hourlyPayment;
      var monthlyPayment = ref.monthlyPayment;
      var technologies = ref.technologies;
      var cVs = ref.cVs;
return _c('div',{key:index,staticClass:"px-3",class:index % 2 === 0 ? '' : 'card-text-bg'},[_c('div',{staticClass:"d-flex flex-row "},[_c('div',{staticClass:"pa-4"},[_c('div',{staticClass:"item-title-font",domProps:{"innerHTML":_vm._s(
              specialization
                ? _vm.getSpecialization(specialization)
                : '<div style=color:red>No specialization, please edit one</div>'
            )}}),_c('div',{staticClass:"d-flex mt-1"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('div',{staticClass:"mr-2 item-description-font",domProps:{"innerHTML":_vm._s(
                      level
                        ? _vm.getLevel(level)
                        : '<div style=color:red>No level, please edit one</div>'
                    )}})])]}}],null,true)},[_c('span',[_vm._v("Position")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('div',{staticClass:"mr-2 item-description-font"},[_vm._v(" "+_vm._s(allocation + "%")+" ")])])]}}],null,true)},[_c('span',[_vm._v("Allocation")])]),(hourlyPayment)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('div',{staticClass:"mr-2 item-description-font"},[_vm._v(" "+_vm._s(hourlyPayment + '$')+" ")])])]}}],null,true)},[_c('span',[_vm._v("Price per hour")])]):_vm._e(),(monthlyPayment)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('div',{staticClass:"item-description-font"},[_vm._v(" "+_vm._s(monthlyPayment + '$')+" ")])])]}}],null,true)},[_c('span',[_vm._v("Price per month")])]):_vm._e()],1),(technologies.length)?_c('div',{staticClass:"my-auto mt-1"},_vm._l((technologies),function(ref,i){
                    var technologyName = ref.technologyName;
return _c('v-chip',{key:i,staticClass:"mr-2",attrs:{"small":"","label":"","color":"rgb(234 237 245)"}},[_c('span',[_vm._v(_vm._s(technologyName))])])}),1):_vm._e(),(cVs.length)?_c('div',{staticClass:"my-auto mt-4"},_vm._l((cVs),function(ref,i){
                    var cvLink = ref.cvLink;
return _c('v-chip',{key:i,staticClass:"mr-2 cv-link-font",attrs:{"small":"","label":"","color":"rgb(146, 39, 142)"},on:{"click":function($event){return _vm.openCVInNewWindow(cvLink)}}},[_vm._v(" CV №"+_vm._s(i + 1)+" ")])}),1):_vm._e()]),_c('div',{staticClass:"ml-auto d-flex flex-row my-auto"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('AddEditTeamMember',{attrs:{"refreshData":_vm.refreshData,"edit":true,"items":_vm.expansionPanelContent,"id":id}})],1)]}}],null,true)},[_c('span',[_vm._v("Edit team member")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-btn',{staticClass:"color-red",attrs:{"icon":""},on:{"click":function($event){return _vm.deleteAlert(id)}}},[_c('v-icon',[_vm._v("mdi-delete-empty-outline")])],1)],1)]}}],null,true)},[_c('span',[_vm._v("Delete team member")])])],1)])])}),0):_c('v-card-text',{staticClass:"black--text mt-5 pl-7"},[_c('div',{staticClass:"red--text"},[_vm._v("No data found!")]),_c('div',{staticClass:"item-description-font"},[_vm._v(" Try to add team member, or use autofill. ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }