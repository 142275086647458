<template>
  <div>
    <v-card
      elevation="2"
      v-for="(value, name, index) in expansionPanelContent"
      :key="index"
      class="mb-10"
    >
      <v-card-title class="card-title-style my-auto">
        {{ name }}
        <div class="ml-auto d-flex align-center">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-btn @click="autoFill(value[0].key)" icon>
                  <v-icon color="blue"> mdi-auto-download</v-icon>
                </v-btn>
              </div>
            </template>
            <span>Autofill {{ name }} section</span>
          </v-tooltip>
        </div>
      </v-card-title>
      <v-card-text>
        <div class=" pt-5">
          <div v-for="(item, index) in value" :key="index">
            <div class="d-flex flex-row align-center black--text my-1">
              <AddService
                :id="item.id"
                :type="item.estimateType"
                :refreshData="refreshData"
              />
              <div class="d-flex flex-column">
                <div>{{ item.title }}</div>
                <div>
                  <v-card-subtitle
                    class="d-flex px-0 py-0"
                    v-if="item.estimateType === 2"
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on">
                          {{ item.relativeEstimate + "%" }}
                        </div>
                      </template>
                      <span>Relative estimate</span>
                    </v-tooltip>
                  </v-card-subtitle>
                  <v-card-subtitle class="d-flex px-0 py-0" v-else>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on">
                          <div class="mr-2">
                            {{ item.optimisticEstimate + "hrs" }}
                          </div>
                        </div>
                      </template>
                      <span>Optimistic estimate</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on">
                          <div class="mr-2">
                            {{ item.pesimisticEstimate + "hrs" }}
                          </div>
                        </div>
                      </template>
                      <span>Pesimistic estimate</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on">
                          <div class="mr-2">
                            {{ item.realisticEstimate + "hrs" }}
                          </div>
                        </div>
                      </template>
                      <span>Realistic estimate</span>
                    </v-tooltip>
                  </v-card-subtitle>
                </div>
              </div>
              <div class="ml-auto">
                <v-switch
                  v-model="item.isFree"
                  @change="newState(item.id, item.isFree)"
                  :label="item.isFree ? 'free' : 'paid'"
                ></v-switch>
              </div>
            </div>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import _ from "lodash";
import axiosInstance from "@/axios";
import AddService from "../Actions/AddService";

export default {
  components: {
    AddService,
  },
  data: () => ({
    expansionHeader: null,
  }),
  computed: {
    isEmptyObj: function() {
      if (this.$data.expansionPanelContent) {
        let isEmpty = _.isEmpty(this.$data.expansionPanelContent);
        return !isEmpty;
      }
      return null;
    },
    lookups: function() {
      this.$store.dispatch("getLookups");
      return this.$store.state.lookups;
    },
    expansionPanelContent: function() {
      return this.$store.state.services;
    },
  },

  async created() {
    this.refreshData();
  },

  methods: {
    async newState(id, state) {
      try {
        await axiosInstance.put(
          `/proposal/${this.$route.params.id}/Service/${id}/free`,
          state,
          {
            headers: {
              "Content-Type": "application/problem+json; charset=utf-8",
            },
          }
        );
        this.refreshData();
      } catch (err) {
        console.log(err);
      }
    },
    async refreshData() {
      await this.$store.dispatch("getLookups");
      await this.$store.dispatch("refreshServices", this.$route.params.id);
      await this.$store.dispatch("getSimpleServices", this.$route.params.id);
      await this.$store.dispatch("getSummaryServices", this.$route.params.id);
      await this.$store.dispatch("getSummaryData", this.$route.params.id);
    },
    async autoFill(serviceType) {
      try {
        await axiosInstance.post(
          `/proposal/${this.$route.params.id}/Service/${serviceType}/auto`,
          {}
        );
        this.refreshData();
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>
