<template>
  <div class="page-container">
    <div v-if="['Archived', 'Deleted'].includes(leadState)" class="mb-2 d-flex col-12 pa-0">
      <Label :text="leadState" :customClass="labelClass" />
    </div>
    <div class="general-info">
      <GeneralInformation
        class="margins-between-blocks"
        :getHistory="getHistory"
      />
      <PipedriveNotes class="margins-between-blocks" />
      <BusinessQuestions class="margins-between-blocks" />
      <RequirementsQuestions class="margins-between-blocks" />
      <Comments class="margins-between-blocks" />
      <Proposals class="margins-between-blocks" />
      <Constraints class="margins-between-blocks" />
    </div>
    <div class="toolbar px-8">
      <ToolBar class="toolbar-stick" />
    </div>
  </div>
</template>

<script>
import GeneralInformation from "../components/LeadDetailed/GeneralInformation";
import PipedriveNotes from "../components/LeadDetailed/PipedriveNotes";
import BusinessQuestions from "../components/LeadDetailed/BusinessQuestions";
import RequirementsQuestions from "../components/LeadDetailed/RequirementsQuestions";
import Comments from "../components/LeadDetailed/Comments";
import Proposals from "../components/LeadDetailed/Proposals";
import Constraints from "../components/LeadDetailed/Constraints";
import ToolBar from "../components/LeadDetailed/ToolBar";
import Label from "../components/Common/Label";
import { stateProperty } from "../../config";
import axiosInstance from "@/axios";

export default {
  components: {
    GeneralInformation,
    PipedriveNotes,
    BusinessQuestions,
    RequirementsQuestions,
    Comments,
    Proposals,
    Constraints,
    ToolBar,
    Label,
  },
  data: () => ({
    stateProperty,
    leadId: null,
    leadState: null,
  }),
  created: async function() {
    this.leadId  = this.$route.params.id;
    const { data: { data: { state } } } = await axiosInstance.get(`/lead/${this.leadId}`);
    this.leadState = stateProperty.find(item => item.id === state).value;
  },
  methods: {
    getHistory() {
      this.$store.dispatch("getHistory", {
        id: this.leadId ,
        entityName: "lead",
      });
    },
  },
  computed: {
    labelClass() {
      return this.leadState.toLowerCase();
    },
  }
};
</script>

<style scoped>
.general-info {
  flex: 9;
}
.toolbar {
  flex: 3;
}

.toolbar-stick {
  position: -webkit-sticky;
  position: sticky;
  max-width: 274px;
  top: 0;
  right: 1px;
}
</style>
