<template>
  <v-dialog
    persistent
    v-model="dialog"
    max-width="600px"
    @keydown.esc="clearAllData()"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="global-button-vuetify"
        v-bind="attrs"
        v-on="on"
        color="green"
        @click="getRisks()"
        icon
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">Add risk</span>
      </v-card-title>
      <v-card-text class="pb-0">
        <v-row no-gutters>
          <v-col>
            <v-combobox
              v-model="name"
              append-icon=""
              clearable
              color="blue"
              label="Enter or choose risk"
              item-text="name"
              hide-selected
              :items="risks"
              :search-input.sync="name"
            ></v-combobox>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-checkbox v-model="checkbox" :label="`Add as template`"></v-checkbox>
        <v-spacer></v-spacer>
        <v-btn text @click="clearAllData()">
          Close
        </v-btn>
        <v-btn color="primary" @click="AddRisk(), clearAllData()">
          Add
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axiosInstance from "@/axios";
export default {
  props: {
    refreshData: Function,
  },

  data: () => ({
    name: "",
    risks: [],
    dialog: false,
    checkbox: false,
  }),

  watch: {
    name: async function() {
      if (!this.name) {
        this.getRisks();
      } else {
        try {
          const { data } = await axiosInstance.get(
            `/Dictionary/risks?search=${this.name}`
          );
          let risksNames = data.data.map((el) => el.name);

          this.$data.risks = risksNames;
        } catch (e) {
          console.log(e);
        }
      }
    },
  },

  methods: {
    clearAllData() {
      this.$data.dialog = false;
      this.$data.checkbox = false;
      this.$data.risks = [];
      this.$data.name = null;
      this.getRisks();
    },
    async getRisks() {
      try {
        const { data } = await axiosInstance.get(`/Dictionary/risks`);
        this.$data.risks = data.data.map((el) => el.name);
      } catch (e) {
        console.log(e);
      }
    },
    async AddRisk() {
      try {
        let obj = {};
        obj = {
          name: this.$data.name,
          saveAsTemplate: this.$data.checkbox,
        };
        await axiosInstance.post(
          `/proposal/${this.$route.params.id}/Risk`,
          obj
        );
        this.$props.refreshData();
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style>
.v-menu__content {
  max-width: 551px !important;
}
</style>
