<template>
  <v-app>
    <v-main>
      <Header v-if="userData && !error404Message" />
      <div id="page-wrap">
        <v-container>
          <router-view />
        </v-container>
      </div>
      <v-snackbar v-model="errorPopup.status" :timeout="errorPopup.timeout" top>
        {{ errorPopup.message }}

        <template v-slot:action="{ attrs }">
          <v-btn color="red" text v-bind="attrs" @click="closeSnackbar()">
            Close
          </v-btn>
        </template>
      </v-snackbar>
      <UpButton />
    </v-main>
  </v-app>
</template>

<script>
import Header from "./components/Header/Header";
import UpButton from "./components/UpButton.vue";
import store from "./store/index";

export default {
  store,
  components: {
    Header,
    UpButton,
  },

  computed: {
    userData() {
      return this.$store.state.userData;
    },
    errorPopup() {
      return this.$store.state.errorPopup;
    },
    error404Message() {
      return this.$store.state.error404Message;
    },
  },

  created() {
    if (this.$route.path !== "/login") {
      if (!this.userData) {
        this.$store.commit("setRedirectTo", this.$route.fullPath);
        this.$router.push("/login");
      }
      
      this.$store.dispatch("getLookups");
    }
  },

  methods: {
    closeSnackbar() {
      this.$store.commit("handleSnackbar", {
        status: false,
        message: "",
        timeout: null,
      });
    },
  },
};
</script>

// Global css
<style>
.page-container {
  display: flex;
  max-width: 1200px;
  margin: 0 auto;
  flex-wrap: wrap;
}
.margins-between-blocks {
  margin-bottom: 20px;
}

@font-face {
  font-family: "TisaSansPro";
  src: local("TisaSansPro"),
    url("./fonts/TisaSansPro-Regular.ttf") format("truetype");
}

body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) {
  overflow: unset !important;
}

body {
  padding-right: 0 !important;
}

.v-list-item__title {
  white-space: normal;
}

.card-title-style {
  background-color: rgba(224, 227, 235, 0.7) !important;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.global-button-vuetify:focus::before {
  opacity: 0 !important;
}

.block-title {
  font-weight: 800;
  font-size: 17px;
  letter-spacing: 1px;
  padding-right: 5px;
}

.block-title::first-letter {
  text-transform: uppercase;
}

.block-title-subtitle {
  font-weight: bold;
  font-size: 20px;
  letter-spacing: 1px;
  padding-right: 5px;
  text-decoration: underline;
}

.block-title-subtitle::first-letter {
  text-transform: uppercase;
}
.swal2-title {
  font-size: 1.5rem !important;
  font-weight: 400 !important;
  line-height: 2rem !important;
  letter-spacing: normal !important;
  font-family: "Roboto", sans-serif !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.swal2-styled.swal2-confirm {
  background-color: #1976d2 !important;
  height: 36px !important;
  min-width: 64px !important;
  padding: 0 16px !important;
  box-shadow: none !important;
}
.swal2-styled.swal2-deny {
  background-color: #f44336 !important;
  height: 36px !important;
  min-width: 64px !important;
  padding: 0 16px !important;
}
#page-wrap {
  padding: 20px;
}
.v-chip.v-size--default {
  font-size: 12px;
}
.v-icon.v-icon {
  font-size: 20px;
}
.v-label {
  color: rgba(0, 0, 0, 0.87) !important;
}

.v-input--selection-controls {
  margin-top: 0;
}

.card-text-bg {
  background-color: rgba(224, 227, 235, 0.25);
}
.item-title-font {
  font-weight: 500;
  font-size: 16px;
}
.item-description-font {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.7);
}

.tip {
  font-weight: 500;
}
.color-green:hover {
  color: rgb(76, 175, 80) !important;
}
.color-red:hover {
  color: #f44336 !important;
}
</style>
