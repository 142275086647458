var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-text',{staticClass:"pt-4 px-6"},[_c('div',[_c('div',[_c('div',{staticClass:"d-flex justify-space-between py-2"},[_c('v-dialog',{attrs:{"max-width":"700px","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',{staticClass:"d-flex justify-center pb-4"}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var buildEnable = ref.on;
                    var buildAttributes = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',buildAttributes,false),buildEnable),[_c('v-btn',_vm._g(_vm._b({staticClass:"build-button",attrs:{"depressed":"","width":"155px"},on:{"click":_vm.buildProposal}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-download ")]),_vm._v(" Get proposal ")],1)],1)]}}],null,true)},[_vm._v("Build proposal document")])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[(_vm.isBuilding)?_c('div',[_c('div',{staticClass:"d-flex align-center justify-center"},[_c('v-card-title',[_vm._v("Please wait for building proposal")]),_c('v-progress-circular',{attrs:{"size":30,"color":"primary","indeterminate":""}})],1),_c('v-card-subtitle',{staticClass:"d-flex justify-center"},[_vm._v("it may take up to 60 seconds")])],1):_vm._e(),(!_vm.isBuilding && !_vm.errorOnBuild)?_c('div',[_c('div',{staticClass:"d-flex align-center justify-center"},[_c('v-card-title',[_vm._v("Proposal built")]),_c('v-icon',{attrs:{"size":30,"color":"green"}},[_vm._v("mdi-check")])],1),_c('div',[_c('v-card-text',{staticClass:"d-flex justify-center pb-1 pt-1 px-4"},[_c('div',{staticClass:"link-box"},[_c('a',{attrs:{"href":("" + _vm.googleDriveLink + _vm.documentId),"target":"_blank"}},[_c('div',[_vm._v(" "+_vm._s(("" + _vm.googleDriveLink + _vm.documentId))+" ")])])]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('div',[_c('v-btn',_vm._g(_vm._b({staticClass:"px-2 ml-2 copy-button",attrs:{"color":"rgba(224, 227, 235, 0.3)","elevation":"0"},on:{"click":_vm.copyDocumentLink,"mouseleave":function($event){_vm.copyText = 'Copy'}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"rgb(66, 82, 110)"}},[_vm._v("mdi-content-copy")])],1)],1)]}}],null,false,3749684647)},[_c('span',[_vm._v(_vm._s(_vm.copyText))])])],1),_c('v-card-actions',{staticClass:"px-4"},[_c('v-spacer'),_c('v-btn',{staticClass:"px-0",attrs:{"color":"primary","min-width":"0px","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Close ")])],1)],1)]):_vm._e(),(!_vm.isBuilding && _vm.errorOnBuild)?_c('div',[_c('div',{staticClass:"d-flex align-center justify-center"},[_c('v-card-title',[_vm._v("Something went wrong during building :(")])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDialog}},[_vm._v(" Close ")])],1)],1):_vm._e()])],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-progress-circular',_vm._g(_vm._b({attrs:{"rotate":-90,"size":35,"width":5,"value":Math.ceil(_vm.completness),"color":"#92278e"}},'v-progress-circular',attrs,false),on),[_vm._v(" "+_vm._s(_vm.completness)+" ")])]}}])},[_vm._v("Completness")])],1),_c('div',{staticClass:"d-flex justify-space-between align-center py-3"},[_c('v-autocomplete',{staticClass:"select-lead",staticStyle:{"width":"50%"},attrs:{"attach":"","items":_vm.leads,"placeholder":"Assign lead","dense":"","hide-details":"","value":_vm.assignedLead.id},on:{"change":_vm.handleLeadSelecting}}),_c('div',{staticClass:"back-to-lead"},[(_vm.assignedLead.id)?_c('router-link',{attrs:{"to":'/leads/' + _vm.assignedLead.id}},[_c('v-btn',{staticClass:"global-button-vuetify",attrs:{"icon":""}},[_c('v-icon',{attrs:{"color":"#92278e"}},[_vm._v("mdi-arrow-right-bold")])],1)],1):_c('v-btn',{staticClass:"global-button-vuetify",attrs:{"icon":"","disabled":""}},[_c('v-icon',{attrs:{"color":"#92278e"}},[_vm._v("mdi-arrow-right-bold")])],1)],1)],1)])]),_c('div',{staticClass:"py-1"},[_c('div',{staticClass:"py-2"},[_c('div',[_vm._v("Error list")]),_c('div',{staticClass:"errors"},[(_vm.errors.length)?_c('div',_vm._l((_vm.errors),function(item,index){return _c('div',{key:index},[_c('div',{staticClass:"d-flex"},[_c('v-icon',{class:_vm.setErrorIconColor(item.level),attrs:{"small":""}},[_vm._v("mdi-circle-medium")]),_c('div',{class:_vm.setErrorColor(item.level)},[_vm._v(_vm._s(item.text))])],1)])}),0):_vm._e(),(!_vm.errors.length)?_c('div',{staticClass:"no-errors"},[_vm._v("No errors")]):_vm._e()])]),_c('div',{staticClass:"py-1"},[_vm._v("Summary")]),_c('div',{staticClass:"py-1 summary"},_vm._l((_vm.durations),function(ref,index){
              var title = ref.title;
              var isSummary = ref.isSummary;
              var hours = ref.hours;
              var price = ref.price;
return _c('div',{key:index},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',{class:_vm.setDurationColor(isSummary)},[_vm._v(_vm._s(title))]),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"px-2 summary-info"},[_vm._v(_vm._s(hours)+" hr")]),_c('div',{staticClass:"summary-info"},[_vm._v("$"+_vm._s(price.toFixed(2)))])])])])}),0)]),_c('div',{staticClass:"py-1"},[_c('div',{staticClass:"d-flex align-center justify-space-between"},[_c('div',[_vm._v("History")]),_c('v-btn',{staticClass:"global-button-vuetify",attrs:{"icon":""},on:{"click":_vm.getHistory}},[_c('v-icon',{attrs:{"color":"#92278e"}},[_vm._v("mdi-autorenew")])],1)],1),_c('div',{staticClass:"history"},_vm._l((_vm.proposalHistory),function(item,index){return _c('div',{key:index},[_c('v-list',{staticClass:"pb-0  px-1"},[[_c('v-list-item',{key:index,staticClass:"pa-0"},[_c('v-list-item-content',{staticClass:"py-0"},[_c('v-list-item-title',{staticStyle:{"font-size":"14px"},domProps:{"innerHTML":_vm._s(item.message)}}),_c('v-list-item-subtitle',{staticStyle:{"font-size":"12px"},domProps:{"innerHTML":_vm._s(_vm.filterDate(item.date) + ' | ' + item.user)}})],1)],1)]],2)],1)}),0)]),_c('div',{staticClass:"delete-proposal pt-1",on:{"click":function($event){return _vm.deleteProposal()}}},[_c('v-btn',{attrs:{"text":"","color":"red","small":""}},[_vm._v(" Delete proposal ")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }