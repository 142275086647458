<template>
  <div class="relative-container">
    <div class="d-flex mb-2">
      <CreatingANewLead :reloadData="loadData" />
      <LeadsTypeFilter class="ml-3" :reloadData="loadData" />
      <LeadsFilter class="ml-auto pr-3" :reloadData="loadData" />
    </div>
    <LeadBoard 
      :groups="groups" 
      :groupOriginal="groupOriginal" 
      :reloadData="loadData" 
      />
  </div>
</template>

<script>
import CreatingANewLead from "../components/LeadDraggble/CreatingANewLead";
import LeadBoard from "../components/LeadDraggble/LeadBoard";
import LeadsTypeFilter from "../components/LeadDraggble/LeadsTypeFilter";
import LeadsFilter from "../components/LeadDraggble/LeadsFilter";
import moment from "moment";
import _ from "lodash";

export default {
  components: { LeadBoard, CreatingANewLead, LeadsTypeFilter, LeadsFilter },
  data() {
    return {
      groups: [
        { status: 1, name: "New", items: [] },
        { status: 2, name: "Business clarification", items: [] },
        { status: 3, name: "Requirements clarification", items: [] },
        { status: 4, name: "Preparing proposal", items: [] },
        { status: 5, name: "Done", items: [] },
      ],
      groupOriginal: [],
    };
  },
  methods: {
    async loadData() {
      await this.$store.dispatch("getLeads");

      //#r change to computed
      let store = this.$store.state;

      this.$data.groups.forEach((group) => {
        group.items = [];
      });

      this.$data.groups.forEach((group) => {
        store.leads.forEach((lead) => {
          if (lead.status === group.status) {
            const arrayOfFullName = 
              lead.assigneeName && lead.assigneeName.split(" ");
            let assigneeInitials = "";

            if (arrayOfFullName) {
              arrayOfFullName.forEach((element) => {
                assigneeInitials += element.charAt(0).toUpperCase();
              });
            }

            group.items.push({
              ...lead,
              creationDate: moment(lead.creationDate).format("lll"),
              assigneeInitials,
            });
          }
        });
        group.items = _.sortBy(group.items, (o) => o.index);
      });

      if (!store.filter) {
        this.$data.groupOriginal = _.cloneDeep(this.$data.groups);
      }
    },
  },

  async created() {
    await this.loadData();
  },
};
</script>

<style>
.relative-container {
  position: relative;
}
</style>
