var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',{staticClass:"card-title-style"},[_vm._v(_vm._s(_vm.expansionHeader)+" "),_c('div',{staticClass:"ml-auto"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-btn',{attrs:{"icon":"","color":"#92278e"},on:{"click":function($event){return _vm.refreshLeadData(_vm.$route.params.id)}}},[(!_vm.isDataRefreshed)?_c('v-icon',[_vm._v("mdi-autorenew")]):_vm._e(),(_vm.isDataRefreshed)?_c('v-progress-circular',{attrs:{"width":2,"size":30,"color":"#92278e","indeterminate":""}}):_vm._e()],1)],1)]}}])},[_c('span',[_vm._v("Updating info from Pipedrive")])])],1)]),(_vm.isEmptyObj)?_c('v-card-text',{staticClass:"mt-5 black--text"},[_c('v-list',{attrs:{"max-width":"1050px","two-line":""}},[_vm._l((_vm.expansionPanelContent),function(ref,index){
var note = ref.note;
var user = ref.user;
var creationDate = ref.creationDate;
return [(index)?_c('v-divider',{key:note,staticClass:"split-line my-3"}):_vm._e(),_c('div',{key:index,staticClass:"px-4"},[_c('div',{staticClass:"py-3"},[_c('div',{staticClass:"item-title-font",domProps:{"innerHTML":_vm._s(note)}}),_c('div',{staticClass:"item-subtitle-font",domProps:{"innerHTML":_vm._s(user)}}),_c('div',{staticClass:"item-subtitle-font",staticStyle:{"font-size":"10px"},domProps:{"innerHTML":_vm._s(_vm.filterDate(creationDate))}})])])]})],2)],1):_c('v-card-text',{staticClass:"black--text mt-5"},[_c('div',{staticClass:"px-4"},[_c('div',{staticClass:"red--text"},[_vm._v("No data found!")]),_c('div',{staticClass:"item-description-font"},[_vm._v(" You can refresh the info, if you want. ")])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }